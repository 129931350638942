.jtwt_tweet_text.icon-twitter a:hover {
	color: #3c3950;
}
.widget.widget_search form {
	border-radius: 3px;
	overflow: hidden;
}
.widget_diagram .skill-amount {
	letter-spacing: 0.05em;
}
#colophon #mc4wp_email {
	border-radius: 3px;
}
#mc4wp_submit{
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
#mc4wp_email {
	border-radius: 3px;
}
.digram-line-box  .skill-title {
	font-size: 14px;
}
.widget_recent_comments li {
	font-size: 14px;
}
#wp-calendar caption {
	letter-spacing: 0.05em;
	text-transform: uppercase;
	height: 39px;
	padding: 7px 0;
	line-height: 25px;
}
#wp-calendar #prev a,
#wp-calendar #next a{
	color: transparent;
}

.widget_recent_comments .comment-author-link a{
	font-size: 14px;
}
.widget_recent_comments a {
	font-size: 16px;
}

.project_info-item-style-1 .project_info-item .title {
	letter-spacing: 0.05em;
}

.widget .gem-teams-name {
	clear: both;
}

.gem-teams-phone {
	letter-spacing: 0.05em;
}

.widget_nav_menu li a:after,
.widget_submenu li a:after {
	position: absolute;
	right: 30px;
}

.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a {
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.widget_submenu  ul ul ul,
.widget_nav_menu  ul ul ul,
.widget_pages ul ul ul,
.widget_categories ul ul ul{
	font-size: 14px;
}

.widget_submenu  ul ul ul ul,
.widget_nav_menu  ul ul ul ul,
.widget_pages ul ul ul ul
.widget_categories ul ul ul ul{
	background-color: #dde6e8;

}

.widget_submenu  ul ul ul ul ul,
.widget_nav_menu  ul ul ul ul ul,
.widget_pages ul ul ul ul ul,
.widget_categories ul ul ul ul ul{
	background-color: #d4dfe3;
}

.jtwt_date{
	font-size: 14px;
}


/*footer*/
#colophon .widget_tweets .twitter-box .jtwt_tweet,
#colophon .Custom_Recent_Posts .gem-pp-posts,
#colophon .widget_meta li,
#colophon .widget_rss li,
#colophon .widget_recent_entries li,
#colophon .Custom_Recent_Posts ul li,
#colophon .Custom_Popular_Posts ul li,
#colophon .project_info-item-style-1 .project_info-item.iconed,
#colophon .gem-latest-news {
	border-bottom: 1px solid #393d50;
}
#colophon .widget_tweets .twitter-box .jtwt_tweet:last-child,
#colophon .Custom_Recent_Posts .gem-pp-posts:last-child,
#colophon .widget_meta li:last-child,
#colophon .widget_rss li:last-child,
#colophon .widget_recent_entries li:last-child,
#colophon .Custom_Recent_Posts ul li:last-child,
#colophon .Custom_Popular_Posts ul li:last-child,
#colophon .project_info-item-style-1 .project_info-item.iconed:last-child,
#colophon .gem-latest-news:last-child {
	border-bottom: none;
}

#colophon .widget_recent_entries ul {
	background: transparent;
}
#colophon .project_info-item-style-2 .project_info-item,
#colophon .project_info-item-style-1,
#colophon .digram-line-box,
#colophon .widget form.searchform,
#colophon.gem-teams-item-wrap,
#colophon.gem-picturebox-text,
#colophon .widget_recent_comments li,
#colophon .gem-teams-item-wrap,
#colophon #wp-calendar #prev a,
#colophon #wp-calendar #next a,
#colophon #wp-calendar caption:after,
#colophon #calendar_wrap,
#colophon  .widget_mc4wp_widget form {
	background-color: #2c2e3d ;
}
#colophon #wp-calendar #prev a,
#colophon #wp-calendar #next a {
	-moz-transition: all 0.2  linear;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s  linear;
	transition: all 0.2s  linear;
}

#colophon #wp-calendar #prev a:hover:after,
#colophon #wp-calendar #next a:hover:after {
	-moz-transition: all 0.2  linear;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s  linear;
	transition: all 0.2s  linear;
}
#colophon .widget.widget_search input,
#colophon #wp-calendar caption {
	background-color: #181828;
}


#colophon .jtwt_tweet:first-child,
#colophon .project_info-item-style-1 .project_info-item.iconed:first-child,
#colophon .gem-pp-posts:first-child,
#colophon .widget_tweets .twitter-box .jtwt_tweet:first-child{
	background: none;
}
#colophon .skill-line {
	background-color: #171728;
}

#colophon .widget.widget_search input,
#colophon #mc4wp_email,
#colophon .wpcf7-form-control-wrap input,
#colophon .wpcf7-form-control-wrap textarea{
	background-color: #181828;
	border: 1px solid #394050;
}
#colophon .wpcf7-form-control-wrap textarea {
	border-radius: 3px;
}
#colophon .widget.widget_search .gem-button,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit{
	background-color: #394050;
}
#colophon #mc4wp_submit{
	background-color: #394050;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
#page #colophon .woocommerce .select2-container--default .select2-selection--single .select2-selection__rendered {
	background-color: #181828 !important;
}
#page #colophon .woocommerce .select2-container .select2-selection--single {
	border-color: #394050;
}
#page #colophon  .select2-container {
	border-radius: 10px;
}
#colophon .combobox-wrapper {
	background-color: #181828 !important;
	border: 1px solid #394050 !important;
}

#colophon .widget_categories > ul,
#colophon .widget_categories > ul li,
#colophon .widget.widget_pages > ul,
#colophon .widget.widget_pages li {
	border-color: #394050;
}
#colophon .widget_archive li,
#colophon .widget_archive li:nth-child(odd) {
	background-color: transparent;
}
#colophon .widget_submenu li,
#colophon .widget_nav_menu > div > ul li,
#colophon .widget_archive  ul li {
	border-top: 1px solid #394050;
}
body #colophon .widget_nav_menu ul.menu > li > ul
{
	border-left: 1px solid #394050;
	border-right: 1px solid #394050;
	border-top: 1px solid #394050;
}

#colophon .widget_submenu ul,
#colophon .widget_nav_menu > div > ul,
#colophon .widget_archive  ul {
	border-left: 1px solid #394050;
	border-right: 1px solid #394050;
	border-bottom: 1px solid #394050;
}
#colophon .tagcloud a {
	background-color: transparent;
}
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_categories ul ul,
#colophon .widget_pages li li {
	background-color: #2c2e3d;
}

#colophon .wpcf7-validation-errors,
#colophon .wpcf7-not-valid-tip {
	display: none !important;
}

/*footer*/
.widget .searchform input {
	border-radius: 3px;
}
body .widget_tag_cloud .tagcloud a:hover{
	border: 1px solid transparent;
}

.widget :after,
.widget :before,
.widget a,
.widget a:hover,
.widget.widget_search .gem-button:hover,
.widget.widget_search .gem-button,
body .widget_tag_cloud .tagcloud a,
body .widget_tag_cloud .tagcloud a:hover,
#colophon .widget.widget_search .gem-button:hover,
#colophon #mc4wp_submit:hover,
#colophon .widget.widget_search .gem-button:hover,
body #colophon .tagcloud a:hover,
#colophon .widget.widget_search .gem-button {
	-o-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.gem-gmaps.gem-wrapbox-style-11 .gem-wrapbox-inner {
	border-color: #e0e5e8;
}

body .portfolio-items-masonry.background-style-dark  .portfolio-item .wrap > .caption {
	border-bottom: none;
}
.portfolio-items-masonry .portfolio-item .wrap > .caption {
	border-bottom: 1px solid #e0e5e8;
}
/*hardcode*/
.portfolio-filters-resp button:after {
	background: #99a9b5;
	box-shadow: 0 10px 0 0 #99a9b5, 0 20px 0 0 #99a9b5;
}
/*hardcode*/
#colophon .gem-teams-name {
	color: #99A9B6;
}
#colophon .gem-picturebox-text {
	background-color: #2c2e3d !important;
}
#colophon  .widget_product_categories > ul,
#colophon  .widget_product_categories > ul li,
#colophon .gem-products-content,
#colophon  .widget_shopping_cart_content ul.product_list_widget li {
	border-color: #394050;
}
#colophon  .widget_product_search input.search-field {
	background-color: #181828;
	border: 1px solid #394050;
}
#colophon .widget_product_search button {
	top: 41px;
}
#colophon  .widget_product_categories > ul li {
	padding-left: 10px;
}
#colophon  .widget_shopping_cart .total span.amount{
	color: #99a9b5;
}
#colophon .widget  .gem-products-content {
	display: table-cell;
	width: 100%;
	vertical-align: middle;
}
#colophon .widget .gem-products-image {
	display: table-cell;
}
#colophon .team-person-email a:before
{
	margin-right: 0px;
}


.digital-agency-mailchimp.yikes-easy-mc-form label{
	width: 68%;
	padding-right: 5%;
}
.digital-agency-mailchimp.yikes-easy-mc-form label > input[type="email"]{
	background: #f0f3f2;
	height: 45px;
	border-radius: 3px;
	border: none;
	padding-left: 20px;
}
.digital-agency-mailchimp.yikes-easy-mc-form label > input[type="email"]:focus{
	outline: none !important;
}
.yikes-mailchimp-container .digital-agency-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
	width: 17%;
	min-width: 235px;
	display: inline-block;
	background: #212331;
	color: #fff;
	height: 45px;
	border: none;
	border-radius: 3px;
	font-family: 'Montserrat';
	font-weight: 700;
	text-transform: uppercase;
	padding-left: 50px;
	position: relative;
}
.yikes-mailchimp-container .digital-agency-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button:before{
	content: "\e626";
	font-family: "thegem-icons";
	font-style: normal;
	font-size: 24px;
	font-weight: normal;
	color: #fff;
	position: absolute;
	left: 50%;
	margin-left: -69px;
	text-align: center;
}
@media(max-width: 1200px){
	body .digital-agency-mailchimp.yikes-easy-mc-form label{
		width: 100%;
		padding-right: 0;
	}
	body .yikes-mailchimp-container .digital-agency-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
		width: 100%;
	}
}


.business-light-mailchimp.yikes-easy-mc-form label {
	float: left;
	width: 100%;
	padding-right: 0;
	margin-bottom: 0;
}
.business-light-mailchimp.yikes-easy-mc-form input[type="email"]{
	background-color: rgba(255,255,255,0.2);
	border: 1px solid #dfe5e8;
	text-align:center;
	color:#ffffff!important;
	border-radius:5px;

}
.business-light-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button{
	width: 100%;
	min-width: 90px;
	float: left;
	padding:0;
	margin:0;
}
.business-light-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button{
	background-color:#2fc5b0;
	border:0;
	border-radius:5px;
	color:#fff;
	text-transform:uppercase;
	font-size:19px;
	min-height: 45px;
	padding-top:3px;
}
.business-light-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button.gem-button:hover{
	background-color:#3c3950;
}



.business-sulutions-mailchimp input[type=email]{
	width:100%;
	height:40px!important;
	border:0!important;
	background-color:#f0f3f2!important;
	color:#5f727f;
	font-size:16px;
	padding-left:15px!important;
	padding-right:50px!important;
}
.business-mail-submit{
	position:absolute;
	right:20px;
	top:0;
	width:40px!important;
	height:40px!important;
	margin:0!important;
	padding:0!important;
	border:0!important;
	background-color:#dfe5e8!important;
}
.business-mail-submit:before{
	font-family:'thegem-icons';
	content: '\e601';
	color:#3c3950;
	font-size:24px;
	line-height:33px;
}


.online-shop-mailchimp.yikes-easy-mc-form label{
	width: 350px;
	padding-right: 20px;
}
.online-shop-mailchimp.yikes-easy-mc-form label > input{
	background: #f0f3f2;
	height: 45px;
	border: none;
	padding-left: 20px;
}
.yikes-mailchimp-container .online-shop-mailchimp.yikes-easy-mc-form label > input:focus{
	outline: none !important;
}
.yikes-mailchimp-container .online-shop-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
	width: 155px;
	min-width: 155px;
	height: 45px;
	display: inline-block;
	background: #d7b398;
	color: #fff;
	border: none;
	font-family: 'Montserrat';
	font-weight: 700;
	text-transform: uppercase;
	padding-right: 30px;
	padding-left: 30px;
	position: relative;
}
.yikes-mailchimp-container .online-shop-mailchimp.yikes-easy-mc-form button {
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
.yikes-mailchimp-container .online-shop-mailchimp.yikes-easy-mc-form button:hover {
	background: #212121 !important;
	color: #fff !important;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
@media(max-width: 1200px){
	body .online-shop-mailchimp.yikes-easy-mc-form label{
		width: 100%;
		padding-right: 0;
	}
	body .yikes-mailchimp-container .online-shop-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
		width: 100%;
	}
}


.trendy-shop-mailchimp.yikes-easy-mc-form label{
	width: 350px;
	padding-right: 20px;
}
.trendy-shop-mailchimp.yikes-easy-mc-form label > input{
	color: #fff;
	background: transparent;
	height: 45px;
	border: none;
	padding-left: 0;
	border-bottom: 1px solid #575c6c;
}
.yikes-mailchimp-container .trendy-shop-mailchimp.yikes-easy-mc-form label > input:focus{
	outline: none !important;
}
.yikes-mailchimp-container .trendy-shop-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
	width: 155px;
	min-width: 155px;
	height: 45px;
	display: inline-block;
	background: #f44236;
	color: #fff;
	border: none;
	font-family: 'Montserrat';
	font-weight: 700;
	text-transform: uppercase;
	padding-right: 30px;
	padding-left: 30px;
	position: relative;
}
.yikes-mailchimp-container .trendy-shop-mailchimp.yikes-easy-mc-form button{
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
.yikes-mailchimp-container .trendy-shop-mailchimp.yikes-easy-mc-form button:hover{
	background: #212121;
	color: #fff;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
@media(max-width: 1200px){
	body .trendy-shop-mailchimp.yikes-easy-mc-form label{
		width: 100%;
		padding-right: 0;
	}
	body .yikes-mailchimp-container .trendy-shop-mailchimp .yikes-easy-mc-submit-button{
		width: 100%;
	}
}




.lookbook-landing-mailchimp.yikes-easy-mc-form label{
	width: 350px;
	padding-right: 20px;
}
.lookbook-landing-mailchimp.yikes-easy-mc-form label > input{
	color: #333;
	background: transparent;
	height: 40px;
	border: none;
	padding-left: 0;
	border-bottom: 1px solid #dbdbdb;
}
.yikes-mailchimp-container .lookbook-landing-mailchimp.yikes-easy-mc-form label > input:focus{
	outline: none !important;
}
.yikes-mailchimp-container .lookbook-landing-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
	width: 140px;
	min-width: 140px;
	height: 40px;
	display: inline-block;
	background: transparent;
	color: #333;
	font-family: 'Montserrat';
	font-weight: 700;
	text-transform: uppercase;
	padding-right: 22px;
	padding-left: 22px;
	padding-top: 6px;
	padding-bottom: 6px;
	position: relative;
	border: 2px solid #333;
}
.yikes-mailchimp-container .lookbook-landing-mailchimp.yikes-easy-mc-form button {
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
.yikes-mailchimp-container .lookbook-landing-mailchimp.yikes-easy-mc-form button:hover {
	background: #212121;
	color: #fff;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
@media(max-width: 1200px){
	body .lookbook-landing-mailchimp.yikes-easy-mc-form label{
		width: 100%;
		padding-right: 0;
	}
	body .yikes-mailchimp-container .lookbook-landing-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
		width: 100%;
	}
}


.beauty-spa-mailchimp.yikes-easy-mc-form{
	display: block;
	margin: 0 auto;
	width: 320px;
}
.beauty-spa-mailchimp.yikes-easy-mc-form label{
	width: 320px;
	padding-right: 0;
}
.beauty-spa-mailchimp.yikes-easy-mc-form label > input{
	color: #928d8e;
	background: transparent;
	height: 45px;
	border: none;
	padding-left: 0;
	border-bottom: 1px solid #928d8e;
}
.yikes-mailchimp-container .beauty-spa-mailchimp.yikes-easy-mc-form label > input:focus{
	outline: none !important;
}
.yikes-mailchimp-container .beauty-spa-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
	width: 320px;
	min-width: 320px;
	height: 50px;
	background: #e77785;
	font-size: 19px;
	color: #fff;
	border-radius: 25px;
	border: none;
	font-family: 'Montserrat UltraLight';
	font-weight: 100;
	text-transform: uppercase;
	padding-right: 30px;
	padding-left: 30px;
	position: relative;
	margin-top:40px;
}
.yikes-mailchimp-container .beauty-spa-mailchimp.yikes-easy-mc-form button {
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
.yikes-mailchimp-container .beauty-spa-mailchimp.yikes-easy-mc-form button:hover {
	background: #212121;
	color: #fff;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}



.yikes-mailchimp-container .classic-landing-mailchimp.yikes-easy-mc-form label > input[type=email]{
	background: #fff !important;
	padding-left: 20px !important;
	border: 1px solid #dfe5e8 !important;
}
.yikes-mailchimp-container .classic-landing-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button{
	border: none;
	color: #fff;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	background: #e43f65;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}
.yikes-mailchimp-container .classic-landing-mailchimp.yikes-easy-mc-form .yikes-easy-mc-submit-button:hover{
	color: #e43f65;
	background: #212331;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}




.silk-diamond-mailchimp.yikes-easy-mc-form input[type=email]{
	background: #fff !important;
	padding-left: 20px !important;
	border: 1px solid #dfe5e8 !important;
}
.silk-diamond-mailchimp .yikes-easy-mc-submit-button{
	border: none;
	color: #fff;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
.silk-diamond-mailchimp .yikes-easy-mc-submit-button{
	background: #9ccc65;
}

.yellow-breath-mailchimp.yikes-easy-mc-form input[type=email]{
	background: #fff !important;
	color: #99a9b5;
	padding-left: 20px !important;
	border: none !important;
}
.yellow-breath-mailchimp .yikes-easy-mc-submit-button{
	background: #2c2e3d;
	border: none;
	color: #fff;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}
