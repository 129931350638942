@import "src/client_customizations/scss/client_master.scss";

.home-charger-advisor-landing {
    .landing-block, .sample-incentives {
        padding: 1rem;
    }
    .sectionBody{
        line-height: 2rem;
        font-size: 1.65rem;
        font-weight: 600;
        padding-bottom: 1rem;
    }
    .zappy-zip-input-group {
        .button-click-disabled {
            cursor: default;            
        }
        .zappy-zip-input {
            display: inline-block;
            width: 15rem;
            border-color: black;
            margin-right: 0.5rem;
            label, .text-danger {
                display: none;
            }
        }   
    }
    .home-charger-advisor-welcome {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        p {
            max-width: 98%;
            color: #222222;
        }

        h1 {
            color: #222222;
            text-transform: uppercase;
            font-size: 3rem;
            font-family: "IntroBold";
            font-weight: 700;
            margin: 0px;
            padding: 0px;
        }
    }
    .home-charger-advisor-icons {
        margin: 0;
        background-color: #e5e5e5;
        padding: 2rem 0 2rem 0;
        text-transform: uppercase;
        font-weight: 600;
        img {
            height: 72px;
        }
        .icon-filler {
            margin-top: 0;
        }
        .advisor-icons-container {
            margin-top: 0;
        }
        .first-landing-advisor-icon {
            border-left-style: none;
        }
        .icons-container {
            margin: 0;
            .landing-advisor-icon {
                padding: 0;
                margin-top: 0;
                text-align: center;
                border-style: none solid none none;
                border-width: 1px;
                border-color: $gray;

                &.border-left {
                     border-left:1px solid !important;
                }

                p {
                    font-size: 1rem;
                    margin: 2rem 0;
                }
            }
            .first-landing-advisor-icon {
                border-left-style: none;
            }
        }

    }
    .welcome-zip-search {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 6rem 3rem 3rem 3rem;
        background-color: white;
        color: black    ;
    }
    .btn {
        height: 37px;
    }
    .button-get-started {
        font-size: 16px;
        padding: 1.75em 3em;
        line-height: 0px;
        border-radius: 4px;
        min-height: 2.3rem;
        min-width: 220px;
    }

    .home-charger-advisor-incentives {
        margin: 0;
        .intro{
            font-size: 2rem;
            text-transform: uppercase;
            color: #626262;
            font-family: 'IntroBold';
        }
        .hp-incentives-headers {
            max-width: 1000px;
            margin: auto;
        }
        .discover-incentives-text-block, .zappy-zip-block, .hp-incentives-container, .mobile-hp-incentives-container {
            margin: 0;
            width: 100%;
        }
        .circle-container {
            margin: 1rem 5rem;
            display: flex;
            justify-content: space-around;
            img {
                margin: 0;
            }
        }
        .carousel-button {
            border: none;
            color: white;
        }

    }
    .zappy-zip-input-group {
        text-align: left;
    }

    .button-click-disabled {
        cursor: default
    }

    @media only screen and (max-device-width: 480px){
        .home-charger-advisor-icons {
            background-color: white;
            .icons-container {
                flex-wrap: nowrap;
                .landing-advisor-icon {
                    border: none;
                    p {
                        font-size: .9rem;
                    }
                }
            }

        }
        .home-charger-advisor-incentives {
            margin: 0;
            .intro{
                font-size: 1.5rem;
            }
            .hp-incentives-headers {
                p {
                    font-size: 1rem;
                    padding: 0;
                }
            }
            .mobile-hp-incentives-container {
                flex-wrap: nowrap;
            }

        }

    }

    @media only screen and (max-width: 577px){
        .home-charger-advisor-incentives {
            .render-cards-container {
                .IncentiveCard {
                    flex-basis: 90%;
                    height: 412px;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .home-charger-advisor-incentives {
            .IncentiveCard {
                flex-basis: 23.5%;
                height: 412px;
            }
        }
    }

}

