@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	position: relative;
	flex-basis: 49%;
	margin-right: 1%;
	margin-bottom: 1%;
	border-radius: $IncentiveCard-card-border-radius;
	text-align: center;
	flex-direction: column;
	justify-content: space-between;
}
.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	.IncentiveCardContent{
		color:#5F727F;
		font-family: $H1-font-family;
		font-size: 1.3rem;
		font-weight: 400;
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
	.card-title {
		margin-top: 1rem;
		color: $IncentiveCard-card-title-color;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	// padding: 1.6rem;
	font-size: 1rem;
	padding: 1.8rem;
	line-height:1.5rem;
	font-weight: 500;
	text-align: center;
	min-height: 200px;
	padding-bottom: 40px !important;
	.buttonLink {
		position: absolute;
		bottom: 10px;
		left: 0;
		width: 100%;
		button {
			width: 80%;
		}
	}
}

.IncentiveCardTop {
  border-radius: 0px;
  color: white;
  width: 100%;
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 1px;
  text-align: center;
  font-weight: 600;
}

.card-secondary-description {
	margin-top: 2rem;
	font-size: .8rem;
	text-align: center;
	white-space: nowrap;
}

.purple {
	background-color: $IncentiveCard-top-purple;
}
.orange {
	background-color: $IncentiveCard-top-orange;
}
.aqua {
	background-color: $IncentiveCard-top-aqua;
}
.green {
	background-color: $IncentiveCard-top-green;
}
.gray {
	background-color: $IncentiveCard-top-gray;
}
@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 32%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}