@import "src/client_customizations/scss/client_master.scss";

/* Three Steps to Get Charging */
.three-steps {

    .lean-charger-card {
        .row {
            margin: 0;
            min-height: 168px;
        }
    }
    .h1 {
        padding-top: 2rem;
        font-size: 32px;
        font-weight: 600;
    }

    h2 {
        font-size: 20px;
        font-weight: 400;
        font-family: Raleway;
        line-height: 1rem;
        margin-top: -5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    h1,
    h2 {
        color: #222222;
    }

    .middle-h2 {
        border-style: solid;
        border-width: 0 1px;
        border-color: $darkGray;
    }

    .center-row {
        margin: 0 auto;
    }

    .step-header {
        font-size: 24px;
        padding-top: 1rem;
        font-weight: 600;
    }

    .step-header,
    .redeem-step-text {
        color: $darkGray;
    }


    .three-steps-title,
    .three-steps-steps {
        font-family: "Raleway";

    }

    .three-steps-title {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1em;
    }

    .three-steps-steps {
        margin: 0;
        font-size: 16px;

        .col-md-4 {
            padding: 0;
            // text-align: center;
        }
    }

    .three-steps-title {
        text-transform: uppercase;
        font-family: $H1-font-family;
        text-align: center;
    }

    .three-steps-back-button {
        text-align: center;

        button {
            margin: 1rem 0;
            color: $gray;
            border-color: $lightGray;
            border-radius: 4px;
            border-width: 1px;
            height: 3rem;
        }
    }

    .iaa-link {
        width: 90%;
        font-weight: 700;
        font-size: 1.25rem;
        margin-top: 1rem;
        border-radius: 4px;
        padding: 0.5rem 1rem;
    }

    .incentive-card-button {
        color: $red;
        background-color: white;
        text-decoration: underline;
        font-weight: 700;
        font-size: 1.25rem;
    }

    .price-disclaimer {
        padding-bottom: 2rem;
        border-bottom: 1px solid $darkGray;
        width: 100%;
    }

    .lean-charge-card-buy-button {
        button {
            margin-top: 1rem;
            font-size: 16px;
            font-weight: 700;
            border-radius: 4px;
            padding: 0.5rem 1rem;
        }
    }
}
@media (max-width: 576px) {
    .three-steps {
        .IncentiveCard {
            min-width: 100%;
        }
    }
}

@media (min-width: 577px) {
    .three-steps {
        .IncentiveCard {
            min-width: calc(50% - 8px);
        }
        .IncentiveCard:nth-child(even) {
            position: relative;
            left: 8px;
        }
    }
}

@media only screen and (min-width : 768px) {
    .three-steps {
         text-align: center;

        .col-md-2:not(:first-child):not(:last-child) {
            border-left: 1px solid;
            border-right: 1px solid;
        }

        .three-steps-title {
            margin-top: 4rem;
        }
    }
}
@media only screen and (max-width : 768px) {
    .three-steps {
        line-height: 2rem;
        font-weight: 600;        
    }

    .three-steps-title {
        margin-top: 1rem;
    }
    
    .col-md-2 {
        margin-top: 1rem !important;
    }
}

@media only screen and (max-width : 1200px) {
    .three-steps-back-button {
        button {
            font-size: 1rem;   
            padding: 0 1rem; 
        }
    }
}

@media only screen and (min-width : 1201px) {
    .three-steps-back-button {
        button {
            font-size: 1.5rem;   
            padding: 0 2rem;
        }
    }
}
