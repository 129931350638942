/*
Naming Schema :
Component Outer Class
inner class Kebab Case
all lowercase with - separating words.
//$CalcPanels-nav-button-background-color_active:  #000000;

Include  this any componets scss file
@import "src/client_customizations/scss/client_master.scss";

*/

// @import "https://fonts.googleapis.com/css?family=Raleway%3A700%2Cregular%2C100%2C300%7CMontserrat%3A700%2Cregular%7CSource+Sans+Pro%3Aregular%2C300&subset=latin%2Clatin-ext%2Ccyrillic%2Ccyrillic-ext%2Cvietnamese%2Cgreek%2Cgreek-ext&ver=5.5.1";
// @import "https://fonts.googleapis.com/css?family=Roboto:400%7CRaleway:800";

$white: #e5e5e5;
$black: #000000;
$darkBlue: #00587c;
$green:#abd332;
$blue:#0f6b9d;
$yellow:#ffd72d;
$darkGray:#333333;
$gray: #616161;
$lightGray: #dadada;
$red: rgb(225, 66, 39);
$purple: #395299;
$orange: #EEA40A;
$aqua: #35BDD1;
$green: #749D24;
$gray: #5E717E;

$primary-color: rgb(153, 91, 0);
$twentyfive-px-border-radius: 0px 0px 10px 10px;
$focus-box-shadow: none;
$span-border-active: none;


/* Todo:  Move this to appropriate place */
.EVFilterControls {
    button {
        background-color: #fff !important;
    }

    .active {
        background-color: $red !important;
    }
}



/* Global */
.Raleway {
    font-family: "Raleway";
}

.IntroBold {
    font-family: "IntroBold";
}

.noMargin-noPadding {
    margin: 0px;
    padding: 0px;
}


/*
    Header
*/

$Header-background-color:#fff;
$Header-border-bottom: 5px solid #f3f4f4;
$Header-link-color:#000;
$Header-navbar-link-color:$red;
$Header-navbar-toggler-border-color:$red;
$Header-navbar-toggler-color:$red;

/*
    Main
*/

$Main-min-height:500px;

/* 
    Fonts
*/
$H1-font-family: "IntroBold";

/*
Page Layout
*/

$PageLayout-header-bold-color:#666666;
$PageLayout-link-focus-box-shadow:$focus-box-shadow;
$PageLayout-button-focus-box-shadow:$focus-box-shadow;
$PageLayout-form-control-focus-box-shadow:$focus-box-shadow;
$PageLayout-input-focus-box-shadow:$focus-box-shadow;
$PageLayout-select-focus-box-shadow:$focus-box-shadow;
$PageLayout-textarea-focus-box-shadow:$focus-box-shadow;
$PageLayout-image-gallery-thumbnail-focus-box-shadow: $focus-box-shadow;
$PageLayout-SimpleCopyPageBody-card-focus-box-shadow:$focus-box-shadow;
$PageLayout-SimpleCopyPageBody-card-background-color:white;
$PageLayout-SimpleCopyPageBody-card-link-hover-color:$darkGray;
$PageLayout-SimpleCopyPageBody-card-header-color:$darkGray;
$PageLayout-evc-card-focus-box-shadow:white;
$PageLayout-evc-card-background-color:$darkGray;
$PageLayout-evc-card-font-size:14px;
$PageLayout-evc-card-border:1px solid #dbdbdb;
$PageLayout-evc-card-color:$darkGray;
$PageLayout-evc-card-hover-border:1px solid white;
$PageLayout-evc-card-hover-box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.25);
$PageLayout-evc-card-hover-color:$darkGray;
$PageLayout-socialmedia-focus-box-shadow:$focus-box-shadow;
$PageLayout-faq-focus-box-shadow:$focus-box-shadow;
$PageLayout-faq-focus-color:inherit;
$PageLayout-container-background-color:$white;
$PageLayout-legal-disclaimer-color:#666666;
$PageLayout-header-strong-color:$red;
$PageLayout-SimpleCopy-color:$darkGray;
$PageLayout-evc-card-hover-border:1px solid white;
$PageLayout-evc-card-hover-box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.25);
$PageLayout-evc-card-hover-color:$darkGray;


/*
    Generic Footer
*/

$GenericFooter-background-color:$darkGray;
$GenericFooter-color:$darkGray;
$GenericFooter-heading-color:#a9a9a9;
$GenericFooter-heading-font-size:18px;
$GenericFooter-links-font-size:13px;
$GenericFooter-links-line-height:25px;
$GenericFooter-links-color:#fff;
$GenericFooter-links-hover-color:#fff;


/*
    CalcPanels
*/
$CalcPanels-nav-button-font-color: #000000;
$CalcPanels-nav-button-font-color_active: white;
$CalcPanels-nav-button-background-color: transparent;
$CalcPanels-nav-button-background-color_active: #000000;
$CalcPanels-nav-button-border_color_active: #000000;
$CalcPanels-font-size: 14px;
$CalcPanels-nav-button-font-size: 16px;

/*
    EVCard
*/

$EVCard-EVCardBottom-border:1px solid #dbdbdb;


/*
    PricePanels
*/

$PricePanels-background-color: white;
$PricePanels-border: 1px solid #dbdbdb;
$PricePanels-Nav-border: 1px solid #dbdbdb;
$PricePanels-li-a-font-weight: 500;
$PricePanels-li-a-font-color: rgb(33, 37, 41);
$PricePanels-li-a-font-size: 16px;
$PricePanels-li-a-span-border_active:$span-border-active;
$PricePanels-nav-pills-nav-link-background-color_active: inherit;
$PricePanels-nav-pills-nav-link-font-color_active: rgb(33, 37, 41);
$PricePanels-tab-pane-PanelItem-border: 1px #dbdbdb solid;
$MatchScoreBadge-background-color: white;
$MatchScoreBadge-border: 1px solid #dbdbdb;


/*
    Incentive Card
*/

$IncentiveCard-card-border-radius: 0;
$IncentiveCard-card-body-color: #000;
$IncentiveCard-card-title-color: $red;
$IncentiveCard-top-purple: $purple;
$IncentiveCard-top-orange: $orange;
$IncentiveCard-top-aqua: $aqua;
$IncentiveCard-top-green: $green;
$IncentiveCard-top-gray: $gray;
/*
    Incentive Preferences
*/

$IncentivePreferences-input-well-background-color:transparent;


/*
    Link Card
*/

$LinkCard-card-hover-body-color:#00527c;
$LinkCard-evc-card-background-color:#f2f2f2;


/*
    Charging Station
*/

$ChargingStation-card-background-color:white;

/*
    Dealer Location Card
*/

$DealerLocationCard-background-color:white;
$DealerLocationCard-border-radius:4px;
$DealerLocationCard-border:1px solid #dbdbdb;


/*
    ChargingMap
*/

$ChargingMap-map-container-border:1px solid #ccc;
$ChargingMap-legend-background:#fff;
$ChargingMap-legend-border:1px solid #ccc;
$ChargingMap-legend-border-radius:4px;
$ChargingMap-legend-font-size:0.75rem;
$ChargingMap-dealer-map-container-border:1px solid #ccc;
$ChargingMap-dealer-map-container-border-radius:4px;
$ChargingMap-dealer-legend-background:#fff;
$ChargingMap-dealer-legend-border:1px solid #ccc;
$ChargingMap-dealer-legend-border-radius:4px;


/*
    Render Item
*/

$RenderItem-title-color:#666666;
$RenderItem-item-value-color:#333;
$RenderItem-item-value-denominator-color:#252525;
$RenderItem-match-score-tr-last-child-border-top:1px solid #6f6f6f;
$RenderItem-match-score-tr-last-child-border-bottom:1px solid #6f6f6f;
$RenderItem-match-score-tr-th-color:#252525;


/*
    Button Group
*/

$ButtonGroup-btn-background-color:#fff;
$ButtonGroup-btn-hover-background-color: $red;
$ButtonGroup-btn-active-background-color: $red;


/*
    Input Range
*/

$InputRange-border:1px solid white;
$InputRange-runnable-track-background:#ddd;
$InputRange-slider-thumb-border-radius:50%;
$InputRange-slider-thumb-background:$red;
$InputRange-focus-runnable-track-background:#ccc;
$InputRange-fill-lower-background:#777;
$InputRange-fill-lower-radius:10px;
$InputRange-fill-upper-background:#ddd;
$InputRange-fill-upper-radius:10px;
$InputRange-ms-thumb-radius:50%;
$InputRange-ms-thumb-background:#5ca4d1;
$InputRange-ms-fill-lower:#888;
$InputRange-ms-fill-upper:#ccc;


/*
    Pages: Homepage
*/

$HomePage-big-promise-padding:120px 40px;
$HomePage-big-promise-background-color:rgba(0, 0, 0, 0.5);
$HomePage-big-promise-color:rgba(255, 255, 255, 0.9);
$HomePage-big-promise-text-shadow:2px 2px 3px rgba(255, 255, 255, 0.1);
$HomePage-big-promise-min-height:400px;
$RenderItem-match-score-value-greaterThanEightyFive-color: $green;
$RenderItem-match-score-value-greaterThanSeventy-color: $yellow;
$RenderItem-match-score-value-lessThanSeventy-color: $darkGray;
/*
    Pages: EVs
*/

$EVsSort-label-font-weight:bold;
$EVsSort-label-font-size:12px;
$EVsSort-label-color:#666;
$EVsSort-label-letter-spacing:0.5px;
$EVsSort-label-line-height:20px;
$EVsSort-select-background:#ffffff;
$EVsSort-select-border:1px solid #dbdbdb;
$EVsSort-select-border-radius:4px;
$EVsSort-select-font-size:14px;
$EVsSort-select-color:#666666;
$EVsSort-select-letter-spacing:0;

/* Pages Compare Vehicles */
$CompareVehicles-ViewVehicle-button-color: $darkBlue;
$CompareVehicles-ViewVehicle-button-fontcolor: #ffffff;
/* Pages Compare Vehicles */
$CompareVehicleToggle-MobileToggle-button-color: $darkBlue;
$CompareVehicleToggle-MobileToggle-button-fontcolor: #ffffff;

$CompareVehicleToggle-MobileToggle-button-fontcolor-active:#ffffff;
$CompareVehicleToggle-MobileToggle-button-color-active: $darkGray;


/* Lean Charge Card */
$lean-charge-card-make-fontFamily: "Introbold";
$lean-charge-card-make-fontSize: 2rem;
$lean-charge-card-make-lineHeight: 2.5rem;
$lean-charge-card-model-fontFamily: "Raleway";
$lean-charge-card-model-fontSize: 1.2rem;
$lean-charge-card-model-lineHeight: 2rem;


/* Forms */
.form-control {
    font-size: 1.754rem !important;
}

/* Maps */
.google-map-cluster-marker {
    width: 40px;
    height: 52px;
    color: #000;
    // background: #1978c8;
    background-image:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 80.555 100' enable-background='new 0 0 80.555 100' xml:space='preserve'><path d='M76.389,26.389V18.75c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-8.333V18.75  c0-1.15-0.934-2.083-2.084-2.083c-1.149,0-2.083,0.933-2.083,2.083v7.639h-4.167v4.167c0,5.947,4.157,10.915,9.723,12.18v15.598  l4.156,30.662c-0.059,3.014-2.518,5.449-5.545,5.449c-3.062,0-5.553-2.489-5.556-5.55h0.001V50.005L58.333,50  c0-4.595-3.738-8.333-8.333-8.333h-8.334V8.102C41.667,3.634,37.929,0,33.333,0h-25C3.738,0,0,3.634,0,8.102V100h41.667V47.222H50  c1.531,0,2.777,1.247,2.777,2.778l0.001,0.005v38.883h-0.001c0,6.127,4.984,11.111,11.111,11.111C70.015,100,75,95.016,75,88.889  l-4.167-30.744V42.735c5.565-1.265,9.722-6.232,9.722-12.18v-4.167H76.389z M10.524,73.299l5.983-11.965H9.028l10.47-17.95h7.478  L19.498,55.35h8.974L10.524,73.299z M36.111,29.398c0,1.404-1.247,2.546-2.778,2.546h-25c-1.531,0-2.778-1.142-2.778-2.546V8.102  c0-1.404,1.247-2.546,2.778-2.546h25c1.531,0,2.778,1.142,2.778,2.546V29.398z'/></svg>");
    border-radius: 10%;
    padding: 4px;
    display: flex;
    justify-content: left;
    align-items: top;

    .text {
        background-color: #fff;
        height: 12px;
    }
}

.google-map-location-marker-button {
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 17px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #2f6627;

    :hover {
        background-color: transparent;
    }

    :active {
        position: relative;
        top: 1px;
    }

    :hover {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        z-index: 9999;

    }

    :focus {
        outline: none;
    }
}

/** BEGIN VELOZ SPECIFIC CSS */
/* Home Charger Advisor landing page */
.home-charger-advisor-landing {
    .zappy-zip-input-group {
        font-size: 16px;

        .zappy-zip-input {
            margin-left: 0.5 rem !important;
            padding-right: 0px;
        }


    }

    .enter-different-zip {
        color: $white;
        margin: 0;
        font-size: 14px;
    }

    // @TODO I need to get this to be proper width. It should actually be ~47% of the screen; however I am
    // having a problem with every solition I have tried:
    .home-charger-advisor-welcome {
        background-color: $black;
        color: $white;
        padding: 0;
    }

    .home-charger-advisor-icons {

        .slim-icon {
       //      max-width: 26.25%;
        }

        .thick-icon {
       //      min-width: 37.5%;
        }

    }

    .discover-incentives-text-block {
        p {
            color: white;
        }    
    }

    .home-charger-advisor-incentives {
        .discover-incentives-text-block,
        .zappy-zip-block {
            h2 {
                // font-family: $H1-font-family;
                // color: $darkGray;

                .zappy-zip-input-group {
                    padding-left: 0px;
                }
            }

        }
    }

    .button-click-disabled {
        background-color: rgba($red, 0.3);

        &:hover {
            border-radius: 0;
            font-weight: 600;
            text-decoration: none;
            background-color: rgba($red, 0.3);
        }
    }
}

/* One-off(?) incentive card tweaks */
.IncentiveCardTop {
    padding: 1px 1px 1px 1rem;
    text-align: left;
}

.IncentiveCard {
    border: none !important;

    .IncentiveCardBody {
        padding: 0 1rem;
    }
}

/* END VELOZ SPECIFIC CSS */

// Footer
.social-media-icon {
    width: 36px;
    margin-top: -1em;
}

.footer-links {
    margin-top: -0.25rem;
    font-size: 11px;

    a {
        color: $white;
    }
}