@import "src/client_customizations/scss/client_master.scss";

.socketType-grid-container {
    .hardwired-btn {
        padding-left: 13px;
        padding-top: 8px;
        span {
            font-size: 9px;
        }
    }
}