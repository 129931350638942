@import "src/client_customizations/scss/client_master.scss";


.ChargerCard {
  cursor: default;
  p {
  width: 100%;
  margin-bottom: 0;
  font-size: .75rem;
}

.charger-compatibility-warning {
  font-size: 10px;
  line-height: 1.3em;
  min-width: 92px;
  margin-left: -10px;
}

.disableIcon {
  img {
    display: none;
   }        
}
.ChargerCardWifiIcon{
    position: relative;
    float: right;    
    right: 8px;
    top: 8px;
}

.ChargerCardTop {
  padding: 20px;
  position: relative;


  h3 {
    font-size: 1em !important;
    line-height: 1.625em !important;
    font-weight: 700 !important;
    font-family: "Raleway";
  }
  img {
    padding: 0;
  }
  .charger-details-and-image {

    display: flex;
    /* For image in top-right corner */
    .charger-details {
      align-content: center;
      align-items: center;
      display: flex;
      padding: 0px
    }



  }

  .badge-locally-available,
  .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}


.ChargerCardBottom {
  display: flex;
  flex-direction: column;

  .charger-pricing-block {
    border-color: #dadada;
    border-style: solid;
    border-width: 1px;
    width: 85%;
    margin: 0 auto;
    padding: 0.25rem;
  }

  .charger-incentive-value {
    // border-width: 1px !important;
    // border-style: solid !important;
    // border-color: black !important;
    border-bottom: 1px solid #dadada !important;
    margin-bottom: 0.5rem;
    
  }

  .charger-price-and-text {
    width: 100%;
    padding: 0;
    border: none;
    width: 95%;

    .charger-price, .charger-line-item-cash {
      padding: 0;
      padding-right: 1rem;
    }

    .charger-line-item-cash, .charger-price-exp, .charger-price {
      margin-top: 0px;
    }

    .charger-price {
      font-family: "IntroBold";
      font-weight: bold;
      color: #5E717E;
      justify-content: center;
      align-items: center;
    }

    .charger-price-exp {
      padding: 0px;
      font-size: 12px;
      line-height: 1rem;
      color: #5E717E;
      padding-top: 0.25rem;
    }

    .charger-price-total {
      font-weight: bold;
    }
  }

  .buy-charger {
    margin: 0 8px 8px 8px;
    padding: 8px;
    text-align: center;

    .btn {
      height: 48px;
    }
  }
}
}


.ChargerCardBottomWithLink {
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

.charger-row {
  display: flex;

  p {
    padding-left: 8px;
  }

  img {
    width: 16px;
  }
}

.charger-price {
  font-weight: 600;
}

@media (min-width: 1200px) {}

@media (max-width: 575px) {}

@media (min-width: 576px) {
  // .charger-price {
  //   padding-top: 1.0rem !important;
  // }

  // .ChargerCardTop {
  //   padding: 15px 15px 10px 15px;
  // }

  // .ChargerCardBottom > * {
  //   padding: 10px 15px;
  // }

  .ChargerCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}