@import "src/client_customizations/scss/client_master.scss"; 

.charger-compatibility-warning {
    font-size: 10px;
    margin-top: 1rem;
    line-height: 1.3em;
  }

.purchaseChargerTable{
    background-color: white;
    width: 100%;
    td{
        border: none !important;
        img{
            width:58%;
        }
    }
    td+td{
        width: 55%;
    }
}

.lean-charger-card {
 
    .row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display:         flex;
        flex-wrap: wrap;
    }
    .row > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }

    .card-side {
        background-color: white;
    }
    .card-right{
        min-height: 168px;
    }
    .card-left {
        border-right-style: solid;
        border-right-width: 1px;
        border-color: $white;
        max-width: 144px;
        min-height: 168px;
    }
    .lean-charge-card-make{
        font-family: $lean-charge-card-make-fontFamily;
        font-size: $lean-charge-card-make-fontSize;
        line-height: $lean-charge-card-make-lineHeight;
    }
    .lean-charge-card-model{
        font-family: $lean-charge-card-model-fontFamily;
        font-size: $lean-charge-card-model-fontSize;
        line-height: $lean-charge-card-model-lineHeight;        
    }
    .lean-charge-card-buy-button{
        padding-top: 2rem;
    }    
}