
/*
	* Style settings of LayerSlider
	*
	* (c) 2011-2021 George Krupa, John Gera & Kreatura Media
	*
	* LayerSlider home:		https://layerslider.kreaturamedia.com/
	* Licensing:			https://layerslider.kreaturamedia.com/licensing/
*/



/* Slider */

.ls-container {
    visibility: hidden;
    position: relative;
}

.ls-inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center center;
    z-index: 30;
}

.ls-wp-fullwidth-container {
    width: 100%;
    position: relative;
}

.ls-wp-fullwidth-helper {
    position: absolute;
}



/* Layers */

.ls-layers,
.ls-slide-backgrounds,
.ls-slide-effects,
.ls-background-videos,
.ls-bg-video-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.ls-wrapper {
    padding: 0 !important;
    visibility: visible !important;
    border: 0 !important;
    z-index: 1;
}

.ls-background-videos {
    z-index: 1;
}

.ls-bg-video-overlay {
    z-index: 21;
    background-repeat: repeat;
    background-position: center center;
}

.ls-slide-backgrounds {
    z-index: 2;
}

.ls-slide-effects {
    z-index: 4;
}

.ls-layers {
    z-index: 35;
}

.ls-parallax,
.ls-in-out,
.ls-z {
    position: absolute !important;
}

.ls-parallax,
.ls-z {
    width: 0 !important;
    height: 0 !important;
    top: 0 !important;
    left: 0 !important;
    bottom: auto !important;
    right: auto !important;
    display: block !important;
    overflow: visible !important;
}

.ls-in-out {
    display: none;
}

.ls-loop, .ls-clip {
    position: absolute !important;
    display: block !important;
}

.ls-parallax,
.ls-in-out,
.ls-loop,
.ls-z {
    background: none !important;
}

.ls-parallax,
.ls-bg-wrap,
.ls-loop {
    margin: 0 !important;
}

.ls-wrapper > .ls-layer,
.ls-wrapper > a > .ls-layer
.ls-wrapper > .ls-bg {
    line-height: normal;
    white-space: nowrap;
    word-wrap: normal;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

.ls-wrapper > .ls-layer,
.ls-wrapper > a > .ls-layer {
    position: static !important;
    display: block !important;
    -o-transform: translateZ(1px);
    -ms-transform: translateZ(1px);
    -moz-transform: translateZ(1px);
    -webkit-transform: translateZ(1px);
    transform: translateZ(1px);
}

.ls-bg-wrap,
.ls-bg-outer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.ls-bg-wrap {
    display: none;
}

.ls-bg-outer {
    overflow: hidden !important;
}

.ls-wrapper > a.ls-layer-link {
    cursor: pointer !important;
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 10;
    -o-transform: translateZ(100px);
    -ms-transform: translateZ(100px);
    -moz-transform: translateZ(100px);
    -webkit-transform: translateZ(100px);
    transform: translateZ(100px);
    background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7) !important;
}



/* GUI */



/* Common */

.ls-gui-element {
    z-index: 40;
    text-decoration: none !important;
}

.ls-loading-container {
    position: absolute !important;
    display: none;
    left: 50% !important;
    top: 50% !important;
}

.ls-loading-indicator {
    margin: 0 auto;
}



/* Media */

.ls-media-unmute {
    visibility: hidden;
    position: absolute;
    left: 16px;
    top: 16px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: .65;
}

.ls-media-unmute-active {
    visibility: visible;
}

.ls-media-unmute-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    border: 0 solid white;
    transform: scale(0.1);
    background: rgba(0,0,0,.25);
    box-shadow: 0 3px 10px rgba(0,0,0,.2);
}

.ls-icon-muted, .ls-icon-unmuted {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    transform: scale(0.1);
}

.ls-media-unmute-active .ls-media-unmute-bg {
    border: 2px solid white;
}

.ls-media-unmute-active .ls-icon-muted,
.ls-media-unmute-active .ls-icon-unmuted,
.ls-media-unmute-active .ls-media-unmute-bg {
    transform: scale(1) !important;
}

/* .ls-icon-muted {
    background-image: url(../img/icon-muted-white.png);
    opacity: 1;
}

.ls-icon-unmuted {
    background-image: url(../img/icon-unmuted-white.png);
    opacity: 0;
} */

.ls-media-unmute:hover {
    opacity: 1;
}

.ls-media-unmute:hover .ls-icon-muted {
    opacity: 0;
}

.ls-media-unmute:hover .ls-icon-unmuted {
    opacity: 1;
}

.ls-media-unmute-bg,
.ls-icon-unmuted,
.ls-media-unmute,
.ls-icon-muted {
    transition: all .3s ease-in-out !important;
}



/* Navigation */

    .ls-bottom-slidebuttons {
        text-align: left;
    }

    .ls-bottom-nav-wrapper,
    .ls-below-thumbnails {
        height: 0;
        position: relative;
        text-align: center;
        margin: 0 auto;
    }

    .ls-below-thumbnails {
        display: none;
    }

    .ls-bottom-nav-wrapper a,
    .ls-nav-prev,
    .ls-nav-next {
        outline: none;
    }

    .ls-bottom-slidebuttons {
        position: relative;
    }

    .ls-bottom-slidebuttons,
    .ls-nav-start,
    .ls-nav-stop,
    .ls-nav-sides {
        position: relative;
    }

    .ls-nothumb {
        text-align: center !important;
    }

    .ls-link,
    .ls-link:hover {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        left: 0 !important;
        top: 0 !important;
    }

    .ls-link {
        display: none;
    }

    .ls-link-on-top {
        z-index: 999999 !important;
    }

    .ls-link, .ls-wrapper > a > * {
        background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
    }

    .ls-bottom-nav-wrapper,
    .ls-thumbnail-wrapper,
    .ls-nav-prev,
    .ls-nav-next {
        visibility: hidden;
    }



/* Timers */

    .ls-bar-timer {
        position: absolute;
        width: 0;
        height: 2px;
        background: white;
        border-bottom: 2px solid #555;
        opacity: .55;
        top: 0;
    }

    .ls-circle-timer {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: .65;
        display: none;
    }

    .ls-container .ls-circle-timer,
    .ls-container .ls-circle-timer * {
        box-sizing: border-box !important;
    }

    .ls-ct-half {
        border: 4px solid white;
    }

    .ls-ct-left,
    .ls-ct-right {
        width: 50%;
        height: 100%;
        overflow: hidden;
    }

    .ls-ct-left,
    .ls-ct-right {
        position: absolute;
    }

    .ls-ct-left {
        left: 0;
    }

    .ls-ct-right {
        left: 50%;
    }

    .ls-ct-rotate {
        width: 200%;
        height: 100%;
        position: absolute;
        top: 0;
    }

    .ls-ct-left .ls-ct-rotate,
    .ls-ct-right .ls-ct-hider,
    .ls-ct-right .ls-ct-half {
        left: 0;
    }

    .ls-ct-right .ls-ct-rotate,
    .ls-ct-left .ls-ct-hider,
    .ls-ct-left .ls-ct-half {
        right: 0;
    }

    .ls-ct-hider,
    .ls-ct-half {
        position: absolute;
        top: 0;
    }

    .ls-ct-hider {
        width: 50%;
        height: 100%;
        overflow: hidden;
    }

    .ls-ct-half {
        width: 200%;
        height: 100%;
    }

    .ls-ct-center {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        border: 4px solid black;
        opacity: 0.2;
    }

    .ls-ct-half,
    .ls-ct-center {
        border-radius: 100px;
        -moz-border-radius: 100px;
        -webkit-border-radius: 100px;
    }

    .ls-slidebar-container {
        margin: 10px auto;
    }

    .ls-slidebar-container,
    .ls-slidebar {
        position: relative;
    }

    .ls-progressbar,
    .ls-slidebar-slider-container {
        position: absolute;
        left: 0;
        top: 0;
    }

    .ls-progressbar {
        width: 0;
        height: 100%;
        z-index: 1;
    }

    .ls-slidebar-slider-container {
        z-index: 2;
    }

    .ls-slidebar-slider {
        position: static !important;
        cursor: pointer;
    }

    /* slidebar default dark skin */

    .ls-slidebar {
        height: 10px;
        background-color: #ddd;
    }

    .ls-slidebar,
    .ls-progressbar {
        border-radius: 10px;
    }

    .ls-progressbar {
        background-color: #bbb;
    }

    .ls-slidebar-slider-container {
        top: 50%;
    }

    .ls-slidebar-slider {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #999;
    }

    /* slidebar default light skin */

    .ls-slidebar-light .ls-slidebar {
        height: 8px;
        background-color: white;
        border: 1px solid #ddd;
        border-top: 1px solid #ccc;
    }

    .ls-slidebar-light .ls-slidebar,
    .ls-slidebar-light .ls-progressbar {
        border-radius: 10px;
    }

    .ls-slidebar-light .ls-progressbar {
        background-color: #eee;
    }

    .ls-slidebar-light .ls-slidebar-slider-container {
        top: 50%;
    }

    .ls-slidebar-light .ls-slidebar-slider {
        width: 18px;
        height: 18px;
        border-radius: 20px;
        background-color: #eee;
        border: 1px solid #bbb;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #999;
    }




/* Thumbnails */

    .ls-tn {
        display: none !important;
    }

    .ls-thumbnail-hover {
        display: none;
        position: absolute;
        left: 0;
    }

    .ls-thumbnail-hover-inner {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: none;
    }

    .ls-thumbnail-hover-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .ls-thumbnail-hover-img {
        position: absolute;
        overflow: hidden;
    }

    .ls-thumbnail-hover img {
        max-width: none !important;
        position: absolute;
        display: inline-block;
        visibility: visible !important;
        left: 50%;
        top: 0;
    }

    .ls-thumbnail-hover span {
        left: 50%;
        top: 100%;
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
    }

    .ls-thumbnail-wrapper {
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .ls-thumbnail {
        position: relative;
        margin: 0 auto;
    }

    .ls-thumbnail-inner,
    .ls-thumbnail-slide-container {
        width: 100%;
    }

    .ls-thumbnail-slide-container {
        overflow: hidden !important;
        position: relative;
    }

    .ls-thumbnail-slide {
        text-align: center;
        white-space: nowrap;
        float: left;
        position: relative;
    }

    .ls-thumbnail-slide a {
        overflow: hidden;
        display: inline-block;
        width: 0;
        height: 0;
        position: relative;
    }

    .ls-thumbnail-slide img {
        max-width: none !important;
        max-height: 100% !important;
        height: 100%;
        visibility: visible !important;
    }

    .ls-touchscroll {
        overflow-x: auto !important;
    }



/* Shadow */

    .ls-shadow {
        display: none;
        position: absolute;
        z-index: 10 !important;
        top: 100%;
        width: 100%;
        left: 0;
        overflow: hidden !important;
        visibility: hidden;
    }

    .ls-shadow img {
        width: 100% !important;
        height: auto !important;
        position: absolute !important;
        left: 0 !important;
        bottom: 0 !important;
    }



/* Media */

.ls-media-layer {
    overflow: hidden;
}

.ls-vpcontainer,
.ls-videopreview {
    width : 100%;
    height : 100%;
    position : absolute;
    left : 0;
    top : 0;
    cursor : pointer;
}

.ls-videopreview {
    background-size: cover;
    background-position: center center;
}

.ls-videopreview {
    z-index: 1;
}

.ls-playvideo {
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 2;
}

.ls-autoplay .ls-playvideo {
    display: none !important;
}



/* Mobile features */

.ls-forcehide {
    display: none !important;
}



/* YourLogo */

.ls-yourlogo {
    position: absolute;
    z-index: 50;
}



/* Slide transitions */

/* .ls-lt-container */
.ls-slide-transition-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
}

.ls-slide-transition-wrapper,
.ls-slide-transition-wrapper * {
    text-align: left !important;
}

.ls-slide-transition-tile {
    position: relative;
    float: left;
}

.ls-slide-transition-tile img {
    visibility: visible;
    margin: 0 !important;
}

.ls-curtiles {
    overflow: hidden;
}

.ls-curtiles,
.ls-nexttiles {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.ls-curtile, .ls-nexttile {
    overflow: hidden;
    width: 100% !important;
    height: 100% !important;
    -o-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.ls-nexttile {
    display: none;
}

.ls-3d-container {
    position: relative;
    overflow: visible !important;
    -ms-perspective: none !important;
    -moz-perspective: none !important;
    -webkit-perspective: none !important;
    perspective: none !important;
}

.ls-3d-box {
    position: absolute;
}

.ls-3d-box div {
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: #888;
}

.ls-3d-box .ls-3d-top,
.ls-3d-box .ls-3d-bottom {
    background-color: #555;
}

.ls-slide-transition-wrapper,
.ls-slide-transition-tile {
    -ms-perspective: 1500px;
    -moz-perspective: 1500px;
    -webkit-perspective: 1500px;
    perspective: 1500px;
}

.ls-slide-transition-tile,
.ls-3d-box,
.ls-curtiles,
.ls-nexttiles {
    -ms-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.ls-curtile,
.ls-nexttile,
.ls-3d-box,
.ls-3d-box div {
    -ms-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}




/* Misc. */

.ls-fullsize:not(.ls-hidden),
.ls-fullwidth:not(.ls-hidden) {
    max-width: 100%;
}

.ls-visible {
    overflow: visible !important;
}

.ls-unselectable {
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.ls-overflow-hidden {
    overflow: hidden;
}

.ls-overflowx-hidden {
    overflow-x: hidden;
}

.ls-overflow-visible {
    overflow: visible !important;
}

.ls-overflowy-hidden {
    overflow-y: hidden;
}

.ls-hidden {
    width: 0px !important;
    height: 0px !important;
    position: absolute;
    left: -5000px;
    top: -5000px;
    z-index: -1;
    overflow: hidden !important;
    visibility: hidden !important;
    display: block !important;
}

.ls-slide {
    display: block !important;
    visibility: hidden !important;
    position: absolute !important;
    overflow: hidden;
    top: 0 !important;
    left: 0 !important;
    bottom: auto !important;
    right: auto !important;
    background: none;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.ls-slide > .ls-layer,
.ls-slide > .ls-l,
.ls-slide > .ls-bg,
.ls-slide > a > .ls-layer,
.ls-slide > a > .ls-l,
.ls-slide > a > .ls-bg {
    position: absolute !important;
    white-space: nowrap;
}

.ls-fullscreen {
    margin: 0 !important;
    padding: 0 !important;
    width: auto !important;
    height: auto !important;
}

.ls-fullscreen .ls-container {
    background-color: black;
    margin: 0 auto !important;
}

.ls-fullscreen .ls-fullscreen-wrapper {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    background-color: black;
    overflow: hidden !important;
    position: relative !important;
}

.ls-fullscreen .ls-fullscreen-wrapper .ls-container,
.ls-fullscreen .ls-fullscreen-wrapper .ls-wp-container {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
}

.ls-layer .split-item {
    font-weight: inherit !important;
    font-family: inherit !important;
    font-size: inherit;
    color: inherit;
}



/* Removing all default global styles of WordPress themes */

html body .ls-nav-prev,
#ls-global .ls-nav-prev,
html body .ls-nav-next,
#ls-global .ls-nav-next,
html body .ls-container img,
#ls-global .ls-container img,
html body .ls-bottom-nav-wrapper a,
#ls-global .ls-bottom-nav-wrapper a,
html body .ls-container .ls-fullscreen,
#ls-global .ls-container .ls-fullscreen,
html body .ls-thumbnail a,
#ls-global .ls-thumbnail a {
    transition: none;
    -ms-transition: none;
    -webkit-transition: none;
    line-height: normal;
    outline: none;
    padding: 0;
    border: 0;
}

html body .ls-wrapper > a,
#ls-global .ls-wrapper > a,
html body .ls-wrapper > p,
#ls-global .ls-wrapper > p,
html body .ls-wrapper > h1,
#ls-global .ls-wrapper > h1,
html body .ls-wrapper > h2,
#ls-global .ls-wrapper > h2,
html body .ls-wrapper > h3,
#ls-global .ls-wrapper > h3,
html body .ls-wrapper > h4,
#ls-global .ls-wrapper > h4,
html body .ls-wrapper > h5,
#ls-global .ls-wrapper > h5,
html body .ls-wrapper > img,
#ls-global .ls-wrapper > img,
html body .ls-wrapper > div,
#ls-global .ls-wrapper > div,
html body .ls-wrapper > span,
#ls-global .ls-wrapper > span,
html body .ls-wrapper .ls-bg,
#ls-global .ls-wrapper .ls-bg,
html body .ls-wrapper .ls-layer,
#ls-global .ls-wrapper .ls-layer,
html body .ls-slide .ls-layer,
#ls-global .ls-slide .ls-layer,
 html body .ls-slide a .ls-layer,
#ls-global .ls-slide a .ls-layer {
    transition: none;
    -ms-transition: none;
    -webkit-transition: none;
    line-height: normal;
    outline: none;
    padding: 0;
    margin: 0;
    border-width: 0;
}

html body .ls-container img,
#ls-global .ls-container img {
    background: none !important;
    min-width: 0 !important;
    max-width: none !important;
    border-radius: 0;
    box-shadow: none;
    border: 0;
    padding: 0;
}

html body .ls-wp-container .ls-wrapper > a > *,
#ls-global .ls-wp-container .ls-wrapper > a > * {
    margin: 0;
}

html body .ls-wp-container .ls-wrapper > a,
#ls-global .ls-wp-container .ls-wrapper > a {
    text-decoration: none;
}

.ls-wp-fullwidth-container,
.ls-wp-fullwidth-helper,
.ls-gui-element *,
.ls-gui-element,
.ls-container,
.ls-slide > *,
.ls-slide,
.ls-wrapper,
.ls-layer {
    box-sizing: content-box !important;
}

html body .ls-yourlogo,
#ls-global .ls-yourlogo {
    margin: 0;
}

html body .ls-tn,
#ls-global .ls-tn {
    display: none;
}

.ls-bottom-nav-wrapper,
.ls-bottom-nav-wrapper span,
.ls-bottom-nav-wrapper a {
    line-height: 0;
    font-size: 0;
}

html body .ls-container video,
#ls-global .ls-container video {
    max-width: none !important;
    object-fit: cover !important;
}

html body .ls-container.ls-wp-container,
#ls-global .ls-container.ls-wp-container,
html body .ls-fullscreen-wrapper,
#ls-global .ls-fullscreen-wrapper {
    max-width: none;
}

/* direction: ltr */

html body .ls-container,
#ls-global .ls-container,
html body .ls-container .ls-slide-transition-wrapper,
#ls-global .ls-container .ls-slide-transition-wrapper,
html body .ls-container .ls-slide-transition-wrapper *,
#ls-global .ls-container .ls-slide-transition-wrapper *,
html body .ls-container .ls-bottom-nav-wrapper,
#ls-global .ls-container .ls-bottom-nav-wrapper,
html body .ls-container .ls-bottom-nav-wrapper *,
#ls-global .ls-container .ls-bottom-nav-wrapper *,
html body .ls-container .ls-bottom-nav-wrapper span *,
#ls-global .ls-container .ls-bottom-nav-wrapper span *,
html body .ls-direction-fix,
#ls-global .ls-direction-fix {
    direction: ltr !important;
}

/* float: none */

html body .ls-container,
#ls-global .ls-container {
    float: none !important;
}



/* Notification */

.ls-slider-notification {
    position: relative;
    margin: 10px auto;
    padding: 10px 10px 15px 88px;
    border-radius: 5px;
    min-height: 60px;
    background: #de4528;
    color: white !important;
    font-family: 'Open Sans', arial, sans-serif !important;
}

.ls-slider-notification a {
    color: white !important;
    text-decoration: underline !important;
}

.ls-slider-notification-logo {
    position: absolute;
    top: 16px;
    left: 17px;
    width: 56px;
    height: 56px;
    font-size: 49px;
    line-height: 53px;
    border-radius: 56px;
    background-color: white;
    color: #de4528;
    font-style: normal;
    font-weight: 800;
    text-align: center;
}

.ls-slider-notification strong {
    color: white !important;
    display: block;
    font-size: 20px;
    line-height: 26px;
    font-weight: normal !important;
}

.ls-slider-notification span {
    display: block;
    font-size: 13px;
    line-height: 16px;
    text-align: justify !important;
}



/* Device Responsiveness */

@media screen and (max-width: 767px) {
.ls-container .ls-layers > .ls-hide-on-phone,
.ls-container .ls-background-videos .ls-hide-on-phone video,
.ls-container .ls-background-videos .ls-hide-on-phone audio,
.ls-container .ls-background-videos .ls-hide-on-phone iframe {
    display: none !important;
}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
.ls-container .ls-layers > .ls-hide-on-tablet,
.ls-container .ls-background-videos .ls-hide-on-tablet video,
.ls-container .ls-background-videos .ls-hide-on-tablet audio,
.ls-container .ls-background-videos .ls-hide-on-tablet iframe {
    display: none !important;
}
}

@media screen and (min-width: 1025px) {
.ls-container .ls-layers > .ls-hide-on-desktop,
.ls-container .ls-background-videos .ls-hide-on-desktop video,
.ls-container .ls-background-videos .ls-hide-on-desktop audio,
.ls-container .ls-background-videos .ls-hide-on-desktop iframe {
    display: none !important;
}
}

.ls-container .ls-background-videos .ls-vpcontainer {
z-index: 10;
}

.ls-container .ls-background-videos .ls-videopreview {
background-size: cover !important;
}

.ls-container .ls-background-videos video,
.ls-container .ls-background-videos audio,
.ls-container .ls-background-videos iframe {
position: relative;
z-index: 20;
}



/* Hacks, Optimization */

@media (transform-3d), (-ms-transform-3d), (-webkit-transform-3d) {
    #ls-test3d {
        position: absolute;
        left: 9px;
        height: 3px;
    }
}

[data-ls-animating-in],
[data-ls-animating-out] {
    will-change: transform, opacity;
}

.ls-loop {
    will-change: transform;
}

