.SelectedDealerCard {
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.SelectedDealerCard h5 {
  font-size: 1em;
}

.SelectedDealerCard .address-container {
  color: #666666; 
  font-weight: 200;
}

.SelectedDealerCard .oem-image {
  max-width: 150px;
  margin-bottom: 1.5em;
}
