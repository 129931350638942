@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: #9f1b96;
	padding-top: 20px;
	color: $GenericFooter-color;
	.social-media-icon {
		height: 20px;
	}
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			// padding-left: 5px;
			text-decoration: underline;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
	div {
		text-align: center;
	}
	.copyright-notice {
		margin: 0 0 10px;
	}

       .veloz-footer-row {
              padding:50px 0;
              border-bottom:1px solid white;
              margin-bottom: 25px;

              .container {
                     display:flex;

                     @media(max-width:768px) {
                            flex-direction: column;
                     }
              }

              .veloz-content {
                     text-align: left;
                     flex-basis: 50%;
                     padding-right:50px;

                     @media(max-width:768px) {
                            flex-basis: 100%;
                            margin-bottom: 25px;
                     }

                     .veloz-logo {
                            margin:0 0 30px;

                            img {
                                   text-align: left;
                                   display: block;
                                   max-width:380px;
                                   width:100%;
                            }
                     }

                     p {
                            color:white;
                     }
              }

              .veloz-form-container {
                     flex-basis: 50%;
                     text-align: left;

                     @media(max-width:768px) {
                            flex-basis: 100%;
                     }

                     h3 {
                            text-align: left;
                            color:white;
                            font-size:26px;
                            margin:0;
                     }

                     p {
                            color:white;
                            font-size:16px;
                     }

                     #mc_embed_signup {
                            form {
                                   margin:20px 0;

                                   p.full-width {
                                          margin:0 0 10px;
                                   }

                                   .form-container {
                                          display:flex;
                                          gap:50px;
       
                                          @media(max-width:768px) {
                                                 flex-direction: column;
                                                 gap:0px;
                                          }
       
                                          .form-left {
                                                 flex-basis: 50%;
                                          }
       
                                          .form-right {
                                                 flex-basis: 50%;
                                          }
                                   }

                                   .mc-field-group {
                                          text-align: left;
                                          margin:0 0 15px;
                                          color:#fff;

                                          p {
                                                 margin:0;
                                          }

                                          label {
                                                 width:100%;
                                                 margin:0;
                                                 color:white;
                                          }

                                          label.hide {
                                                 position: absolute;
                                                 width: 1px;
                                                 height: 1px;
                                                 margin: -1px;
                                                 padding: 0;
                                                 overflow: hidden;
                                                 clip: rect(0, 0, 0, 0);
                                                 border: 0;
                                          }

                                          input[type="text"], input[type="email"] {
                                                 padding:5px 10px;
                                                 width:100%;
                                          }

                                          input[type="checkbox"] {
                                                 margin:0 10px 0 0;
                                          }
                                   }

                                   input[type="submit"] {
                                          width:100%;
                                          height:37px;
                                          background-color:transparent;
                                          color:white;
                                          border:1px solid white;
                                          padding:5px;
                                          text-transform: uppercase;
                                          font-weight: bold;
                                          font-size:14px;
                                          transition: all 0.3s ease;
                                          border-radius: 3px;
                                          line-height: 1em;

                                          &:hover {
                                                 background-color:white;
                                                 color:#9f1b96;
                                          }
                                   }
                            }
                     }
              }
       }

       .CookieConsent {
              align-items: center !important;

              div {
                     text-align: left;

                     a {
                            font-size:14px;
                            color:#e14227;
                     }

                     #rcc-confirm-button {
                            background-color: transparent !important;
                            color:#fff !important;
                            font-weight: bold !important;
                            border:2px solid #fff !important;
                            border-radius: 25px !important;
                            padding:5px 20px !important;
                     }

                     #rcc-decline-button {
                            border-radius: 25px !important;
                            padding:5px 20px !important;
                            text-transform: uppercase !important;
                     }
              }
       }

.disclaimer_container,
.disclaimer_container * { /* Apply text-align to all child elements */
    text-align: left;
    font-size: 10px; /* Set the font size to 10px */
}

.disclaimer_container div {
    background-color: transparent !important;
}

.disclaimer_container img {
    max-width: 100%;
    margin: 0 0 25px;
    display: block; /* Ensures image takes the full width of its container */
}

.disclaimer_container p {
    color: white;
    /* The font size is already set to 10px by the universal selector above */
}

	
	

}