#site-header-wrapper {
	position: static;
	z-index: 1000;
	width: 100%;
}
#site-header-wrapper.site-header-wrapper-transparent {
	position: absolute;
}
#site-header-wrapper.site-header-wrapper-transparent #site-header:not(.shrink),
#site-header-wrapper.site-header-wrapper-transparent,
#site-header-wrapper.site-header-wrapper-transparent #site-header:not(.shrink) #top-area {
	background: transparent !important;
	border-bottom: 0 none;
}

#site-header:not(.shrink) .transparent-header-background #top-area,
#page > .transparent-header-background #top-area {
	background: transparent !important;
	border-bottom: 0 none;
}
#site-header-wrapper.site-header-wrapper-transparent #site-header:not(.shrink) .top-area-contacts:after,
#site-header-wrapper.site-header-wrapper-transparent #site-header:not(.shrink) .top-area-socials:after {
	border-right: 0 none;
}

#site-header-wrapper.header-on-slideshow {
	position: static !important;
	height: 0 !important;
	background-color: transparent;
}

#site-header.header-on-slideshow.init {
	top: 0 !important;
}

#site-header.header-on-slideshow {
	background: transparent;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
}

body #page:not(.vertical-header) #site-header.fixed {
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1000;
}
@media (min-width: 1260px) {
	#page.layout-boxed:not(.vertical-header) #site-header.fixed {
		width: 1260px;
		left: 50%;
		margin-left: -630px;
	}
}

#site-header.fixed.shrink {
	box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	-moz-box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	-webkit-box-shadow: 0 0 2px 0 rgba(56, 69, 84, 0.2);
	border-collapse: separate;
}

#site-header.without-transition {
	transition: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
}

#site-header.fixed.shrink .site-title {
	padding-top: 5px;
	padding-bottom: 5px;
	-webkit-transition: padding 0.3s;
	-moz-transition: padding 0.3s;
	transition: padding 0.3s;
}

#site-header.fixed.shrink.shrink-mobile {
	-webkit-transition: transform 0.7s 0.2s;
	-moz-transition: transform 0.7s 0.2s;
	transition: transform 0.7s 0.2s;
}

#site-header.fixed.shrink.shrink-mobile.hidden {
	-o-transform: translate3d(0px, -100%, 0px);
	-ms-transform: translate3d(0px, -100%, 0px);
	-moz-transform: translate3d(0px, -100%, 0px);
	-webkit-transform: translate3d(0px, -100%, 0px);
	transform: translate3d(0px, -100%, 0px);
	-webkit-transition: transform 0.7s 0.4s;
	-moz-transition: transform 0.7s 0.4s;
	transition: transform 0.7s 0.4s;
}

/*@media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) {
	#site-header.fixed,
	#site-header.fixed .site-title a,
	#site-header.fixed #primary-menu > li > a,
	#site-header.fixed .site-title,
	#site-header.fixed .header-style-3 #primary-menu.no-responsive > li.menu-item-current,
	#site-header.fixed .header-style-3 #primary-menu.no-responsive > li.menu-item-active,
	#site-header.fixed .header-style-4 #primary-menu.no-responsive > li.menu-item-current,
	#site-header.fixed .header-style-4 #primary-menu.no-responsive > li.menu-item-active,
	#site-header.fixed .header-style-3 #primary-menu.no-responsive > li,
	#site-header.fixed .header-style-4 #primary-menu.no-responsive > li {
		-webkit-transition: all 0s !important;
		-moz-transition: all 0s !important;
		transition: all 0s !important;
	}
}*/

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li > a {
	-webkit-transition: all 0s !important;
	-moz-transition: all 0s !important;
	transition: all 0s !important;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li > a {
	vertical-align: middle;
}


#site-header.fixed #primary-menu > li > a {
	-webkit-transition: padding 0.3s, color 0.3s, background-color 0.3s;
	-moz-transition: padding 0.3s, color 0.3s, background-color 0.3s;
	transition: padding 0.3s, color 0.3s, background-color 0.3s;
}

#site-header.scroll-counting .site-title,
#site-header.scroll-counting .site-title img,
#site-header.scroll-counting #primary-menu > li,
#site-header.scroll-counting #primary-menu > li > a,
#site-header.scroll-counting .menu-item-logo,
#site-header.scroll-counting .menu-item-logo img {
	-webkit-transition: all 0s !important;
	-moz-transition: all 0s !important;
	transition: all 0s !important;
}

#site-header.fixed.shrink #primary-menu.no-responsive > li > a {
	padding-top: 18px;
	padding-bottom: 18px;
}

#site-header.fixed.shrink .header-layout-fullwidth_hamburger #primary-menu.no-responsive > li > a {
	padding-top: 13px;
	padding-bottom: 13px;
}

#site-header.fixed.shrink .header-style-1 #primary-menu.no-responsive > li.menu-item-current > a,
#site-header.fixed.shrink .header-style-1 #primary-menu.no-responsive > li.menu-item-active > a {
	padding-bottom: 14px;
}

#site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li.menu-item-current,
#site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li.menu-item-active,
#site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li.menu-item-current,
#site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li.menu-item-active {
	-webkit-transition: padding 0.3s;
	-moz-transition: padding 0.3s;
	transition: padding 0.3s;
}

#site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li,
#site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li {
	padding-top: 18px;
	padding-bottom: 18px;
	-webkit-transition: padding 0.3s;
	-moz-transition: padding 0.3s;
	transition: padding 0.3s;
}

#site-header.fixed.shrink .header-layout-overlay.header-style-3 #primary-menu.no-responsive > li,
#site-header.fixed.shrink .header-layout-overlay.header-style-4 #primary-menu.no-responsive > li {
	padding: 0;
}

#site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li.menu-item-current,
#site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li.menu-item-active,
#site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li.menu-item-current,
#site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li.menu-item-active {
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 1px;
	padding-right: 1px;
}

#site-header.fixed.shrink .header-layout-overlay.header-style-3 #primary-menu.no-responsive > li.menu-item-current,
#site-header.fixed.shrink .header-layout-overlay.header-style-3 #primary-menu.no-responsive > li.menu-item-active,
#site-header.fixed.shrink .header-layout-overlay.header-style-4 #primary-menu.no-responsive > li.menu-item-current,
#site-header.fixed.shrink .header-layout-overlay.header-style-4 #primary-menu.no-responsive > li.menu-item-active {
	padding: 0;
}

body.one-pager #site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li,
body.one-pager #site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li {
	-webkit-transition: all 0s;
	-moz-transition: all 0s;
	transition: all 0s;
}

#site-header.fixed.shrink .header-style-3 #primary-menu.no-responsive > li > a,
#site-header.fixed.shrink .header-style-4 #primary-menu.no-responsive > li > a {
	padding: 0px 15px;
}

#site-header.fixed.shrink #primary-navigation .hamburger-toggle,
#site-header.fixed.shrink #primary-navigation .overlay-toggle,
#site-header.fixed.shrink #perspective-menu-buttons .perspective-toggle {
	margin-top: 15px;
	margin-bottom: 15px;
}

#site-header .site-title a,
#site-header .menu-item-logo a {
	white-space: nowrap;
}

#site-header .logo-position-right .site-title,
#site-header .logo-position-right .site-title a {
	text-align: right;
}

#site-header .site-title a img,
#site-header .menu-item-logo a img {
	position: relative;
}

#site-header.fixed.scroll-counting .site-title a img,
#site-header.fixed.scroll-counting .menu-item-logo a img {
	-webkit-transition: all 0s !important;
	-moz-transition: all 0s !important;
	transition: all 0s !important;
}

#site-header .site-title a img.default,
#site-header .menu-item-logo a img.default {
	opacity: 1;
	-webkit-transition: opacity .3s ease-in;
	-moz-transition: opacity .3s ease-in;
	transition: opacity .3s ease-in;
}

#site-header .site-title a img.small,
#site-header .menu-item-logo a img.small {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
}

#site-header.fixed.shrink .site-title a img.default,
#site-header.fixed.shrink .menu-item-logo a img.default {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
}

#site-header.fixed.shrink .site-title a img.small,
#site-header.fixed.shrink .menu-item-logo a img.small {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	-moz-opacity: 1;
	-khtml-opacity: 1;
	opacity: 1;
	-webkit-transition: opacity .3s ease-in;
	-moz-transition: opacity .3s ease-in;
	transition: opacity .3s ease-in;
}

#site-header.shrink-mobile .site-title a img {
	transition: opacity 0s;
}

#site-header.fixed.shrink .logo-position-center:not(.no-menu) > .site-title {
	display: none;
}
@media (max-width: 767px) {
	#site-header.fixed.shrink .header-main.logo-position-center > .site-title {
		display: table-cell;
	}
}

.site-title .logo,
.menu-item-logo .logo {
	display: inline-block;
	vertical-align: middle;
	min-height: 1px;
	min-width: 1px;
}

#site-header .site-title a img.small.light,
#site-header .menu-item-logo a img.small.light {
	display: none !important;
}

@media (max-width: 767px) {
	#site-header .site-title a img.default,
	#site-header .menu-item-logo a img.default {
		display: none;
	}
	#site-header .site-title a img.small,
	#site-header .menu-item-logo a img.small {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		-moz-opacity: 1;
		-khtml-opacity: 1;
		opacity: 1;
		display: inline-block !important;
		margin: 0 !important;
	}
	#site-header .site-title a img.small.light,
	#site-header .menu-item-logo a img.small.light {
		display: inline-block !important;
	}
	#site-header .site-title a img.small.light ~ img.small,
	#site-header .menu-item-logo a img.small.light ~ img.small {
		display: none !important;
	}
	#site-header.fixed.shrink .site-title a img.small.light,
	#site-header.fixed.shrink .menu-item-logo a img.small.light {
		display: none !important;
	}
	#site-header.fixed.shrink .site-title a img.small.light ~ img.small,
	#site-header.fixed.shrink .menu-item-logo a img.small.light ~ img.small {
		display: inline-block !important;
	}
}





/* TOP AREA SEARCH DROPDOWN FORM ON MENU ITEM CLICK */

#primary-navigation #primary-menu > li.menu-item-search > a:before {
	content: "\e612";
	display: block;
	font-family: "thegem-icons";
	font-style: normal;
	font-size: 24px;
	font-weight: normal;
	margin-right: 5px;
	text-align: center;
	vertical-align: top;
	width: 100%;
}
#primary-navigation #primary-menu > li.menu-item-search.active > a:before {
	content: "\e619";
	display: block;
	font-family: "thegem-icons";
	font-style: normal;
	font-size: 24px;
	font-weight: normal;
	margin-right: 5px;
	text-align: center;
	vertical-align: top;
	width: 100%;
}
.menu-item-search .minisearch{
	position: absolute;
	top: 100%;
	left: 0;
	padding: 20px;
	width: 280px;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
}
.menu-item-search.active .minisearch{
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
	transition: opacity 0.5s, transform 0.5s;
}
.minisearch.invert{
	left: auto;
	right: 0;
}
.minisearch .sf-input{
	font-size: 16px;
	width: 100%;
	height: 40px;
	border-radius: 4px;
	padding-right: 40px;
	outline: none;
}
.minisearch .sf-submit{
	position: absolute;
	right: 20px;
	width: 40px;
	height: 40px;
	margin: 0;
	background: rgba(0, 0, 0, 0);
	border: 0;
	cursor: pointer;
}
.minisearch .sf-submit:hover{
	background: transparent;
}
.minisearch .sf-submit:focus{
	outline: none;
}
.minisearch .sf-submit-icon{
	position: absolute;
	right: 20px;
	width: 40px;
	height: 40px;
}
.minisearch .sf-submit-icon:before{
	content: "\e612";
	display: block;
	font-family: "thegem-icons";
	font-style: normal;
	font-size: 24px;
	font-weight: normal;
	line-height: 40px;
	margin-right: 5px;
	padding-right: 3px;
	text-align: center;
	vertical-align: top;
	width: 100%;
	height: 40px;
}




/* RESPONSIVE DROPDOWN MENU */
.mobile-menu-layout-default .primary-navigation.responsive ul{
	border-top: 1px solid #dfe5e8;
	border-left: 1px solid #dfe5e8;
	border-right: 1px solid #dfe5e8;
}
.mobile-menu-layout-default .primary-navigation.responsive ul ul,
.mobile-menu-layout-default #primary-navigation.responsive ul ul {
	border: 0 none;
}
.mobile-menu-layout-default .primary-navigation.responsive ul.sub-menu,
.mobile-menu-layout-default .primary-navigation.responsive .dl-menu + ul.sub-menu{
	margin-top: 0;
}
.mobile-menu-layout-default .primary-navigation.responsive ul li.menu-item,
.mobile-menu-layout-default .primary-navigation.responsive ul li.dl-back {
	background: #f4f6f7;
}
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item,
.mobile-menu-layout-default .primary-navigation.responsive ul > li.dl-back {
	border-bottom: 1px solid #dfe5e8;
}
/*.mobile-menu-layout-default .primary-navigation.responsive li li:last-child,
.mobile-menu-layout-default #primary-navigation.responsive ul li:last-child {
	border-bottom: 0 none;
}*/
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item-current > a,
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item.menu-item-active a {
	background: #fff;
	border-left: 4px solid #00bcd4;
	padding-left: 26px;
	color: #3c3950;
}
#primary-navigation.responsive ul > li.menu-item-search > a{
	display: none;
}
.primary-navigation.responsive .minisearch{
	position: static;
	width: 100%;
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	background-color: #fff;
	border: none;
	visibility: visible;
	padding: 20px 30px;
}
.primary-navigation.responsive .minisearch .sf-input {
	border: 1px solid #dfe5e8;
	background-color: #f4f6f7;
	color: #3c3950;
	right: 30px;
}
.primary-navigation.responsive .minisearch .sf-submit-icon {
	right: 30px;
}
.primary-navigation.responsive .minisearch .sf-submit-icon:before{
	color: #3c3950;
}
.primary-navigation.responsive li a{
	color: #5f727f;
}
.primary-navigation.responsive li a:hover{
	color: #3c3950;
}




/* SEARCH BOX AND SOCIALS - VERTICAL MENU LAYER */
.header-style-vertical #primary-navigation .no-responsive li.menu-item-widgets{
	position: absolute;
	bottom: 50px;
	width: 100%;
}
.header-style-vertical #primary-navigation.responsive li.menu-item-widgets,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-search,
body #page.vertical-header #primary-menu.no-responsive li.menu-item-search{
	display: none;
}
.no-responsive .menu-item-widgets .vertical-minisearch{
	padding-right: 30px;
	width: 100%;
}
.no-responsive .vertical-minisearch .sf-input{
	width: 100%;
	font-family: "Source Sans Pro";
	font-size: 16px;
	font-weight: 400;
	height: 40px;
	padding-right: 50px;
	outline: none;
	background: #e0e5e9;
}
.no-responsive .vertical-minisearch .sf-submit{
	position: absolute;
	right: 30px;
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;
	border: 0;
	cursor: pointer;
	opacity: 0;
	background: #000;
	z-index: 1;
}
.no-responsive .vertical-minisearch .sf-submit:hover{
	background: transparent;
}
.no-responsive .vertical-minisearch .sf-submit:focus{
	outline: none;
}
.no-responsive .vertical-minisearch .sf-submit-icon{
	position: absolute;
	right: 45px;
	width: 40px;
	height: 40px;
	z-index: 0;
}
.no-responsive .vertical-minisearch .sf-submit-icon:after{
	background: #3d3950;
	content: "\e612";
	display: block;
	font-family: "thegem-icons";
	font-style: normal;
	font-size: 24px;
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	vertical-align: top;
	width: 100%;
	height: 40px;
	color: #fff;
}
.no-responsive .menu-item-socials,
.vertical-menu-item-widgets .menu-item-socials{
	margin-top: 35px;
}
.no-responsive .menu-item-socials .socials-item{
	margin: 0 9px;
}
.vertical-menu-item-widgets .menu-item-socials .socials-item{
	margin: 0 8px;
}
.no-responsive .menu-item-socials .socials-item:first-child,
.vertical-menu-item-widgets .menu-item-socials .socials-item:first-child{
	margin-left: 0;
}
.no-responsive .menu-item-socials .socials-item:last-child,
.vertical-menu-item-widgets .menu-item-socials .socials-item:last-child{
	margin-right: 0;
}
.no-responsive .menu-item-socials .socials-item .socials-item-icon,
.vertical-menu-item-widgets .menu-item-socials .socials-item .socials-item-icon{
	font-size: 24.5px;
}

.vertical-menu-item-widgets{
	position: absolute;
	bottom: 50px;
	width: 270px;
}
.vertical-menu-item-widgets .vertical-minisearch{
	width: 100%;
}
.vertical-menu-item-widgets .vertical-minisearch .sf-input{
	width: 100%;
	font-family: "Source Sans Pro";
	font-size: 16px;
	font-weight: 400;
	padding-right: 50px;
	height: 40px;
	outline: none;
	background: #e0e5e9;
}
.vertical-menu-item-widgets .vertical-minisearch .sf-submit{
	position: absolute;
	right: 0;
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 0;
	border: 0;
	cursor: pointer;
	opacity: 0;
	background: #000;
}
.vertical-menu-item-widgets .vertical-minisearch .sf-submit:hover{
	background: transparent;
}
.vertical-menu-item-widgets .vertical-minisearch .sf-submit:focus{
	outline: none;
}
.vertical-menu-item-widgets .vertical-minisearch .sf-submit-icon{
	position: absolute;
	right: 0;
	width: 40px;
	height: 40px;
	z-index: 0;
}
#page.vertical-header .vertical-menu-item-widgets .vertical-minisearch .sf-submit-icon{
	right: 21px;
}
.vertical-menu-item-widgets .vertical-minisearch .sf-submit-icon:after{
	background: #3d3950;
	content: "\e612";
	display: block;
	font-family: "thegem-icons";
	font-style: normal;
	font-size: 24px;
	font-weight: normal;
	line-height: 40px;
	text-align: center;
	vertical-align: top;
	width: 40px;
	height: 40px;
	color: #fff;
}

@media (max-height: 669px) {
	.header-style-vertical #primary-menu.no-responsive {
		padding-top: calc(100vh - 570px);
	}
}
@media (max-height: 590px) {
	.header-style-vertical #primary-menu.no-responsive {
		padding-top: 20px;
	}
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive .menu-item-widgets .vertical-minisearch form,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive .menu-item-widgets .menu-item-socials {
	padding-left: 15px;
	padding-right: 15px;
}
#page.vertical-header .vertical-menu-item-widgets {
	padding-left: 21px;
	padding-right: 21px;
}

/* VERTICAL HEADER RESPONSIVE */

@media (max-width: 767px){
	#page.vertical-header #site-header-wrapper{
		position: static;
		width: 100%;
		padding: 0;
	}
	#page.vertical-header{
		padding-left: 0;
	}
	#page.vertical-header #site-header .site-title {
		display: table-cell;
	}
	.vertical-menu-item-widgets{display: none;}
}

@media (max-height: 799px) {
	.header-style-vertical #primary-menu.no-responsive > .menu-item-language,
	.header-style-vertical #primary-menu.no-responsive > .menu-item-cart {
		display: none;
	}
}
@media (max-height: 689px) {
	.vertical-menu-item-widgets {
		display: none;
	}
}

#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .hamburger-toggle,
#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .overlay-toggle,
#site-header.fixed.shrink #perspective-menu-buttons :not(.hamburger-size-small) .perspective-toggle {
	width: 18px;
	height: 18px;
}

#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .hamburger-toggle [class^="menu-line-"],
#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .overlay-toggle [class^="menu-line-"],
#site-header.fixed.shrink #perspective-menu-buttons :not(.hamburger-size-small) .perspective-toggle [class^="menu-line-"] {
	height: 2px;
}

#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .hamburger-toggle .menu-line-1,
#site-header.fixed.shrink #perspective-menu-buttons :not(.hamburger-size-small) .perspective-toggle .menu-line-1,
#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .overlay-toggle .menu-line-1,
#site-header.fixed.shrink #primary-navigation.overlay-active.close :not(.hamburger-size-small) .overlay-toggle .menu-line-1 {
	top: 3px;
}

#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .hamburger-toggle .menu-line-2,
#site-header.fixed.shrink #perspective-menu-buttons :not(.hamburger-size-small) .perspective-toggle .menu-line-2,
#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .overlay-toggle .menu-line-2,
#site-header.fixed.shrink #primary-navigation.overlay-active.close :not(.hamburger-size-small) .overlay-toggle .menu-line-2 {
	top: 8px;
}

#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .hamburger-toggle .menu-line-3,
#site-header.fixed.shrink #perspective-menu-buttons :not(.hamburger-size-small) .perspective-toggle .menu-line-3,
#site-header.fixed.shrink #primary-navigation :not(.hamburger-size-small) .overlay-toggle .menu-line-3,
#site-header.fixed.shrink #primary-navigation.overlay-active.close :not(.hamburger-size-small) .overlay-toggle .menu-line-3 {
	top: 13px;
}

#site-header.fixed.shrink #primary-navigation.hamburger-active :not(.hamburger-size-small) .hamburger-toggle .menu-line-1,
#site-header.fixed.shrink #primary-navigation.hamburger-active :not(.hamburger-size-small) .hamburger-toggle .menu-line-3,
#site-header.fixed.shrink #primary-navigation.overlay-active :not(.hamburger-size-small) .overlay-toggle .menu-line-1,
#site-header.fixed.shrink #primary-navigation.overlay-active :not(.hamburger-size-small) .overlay-toggle .menu-line-3 {
	top: 8px;
}

/* fix logo alignment */
@media (max-width: 979px) {
	#page #site-header .no-menu > .mobile-cart{
		display: none;
	}
	#page #site-header .no-menu > .site-title{
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin: 0;
	}
}

.top-area-background,
.header-background {
	position: relative;
}
.top-area-background:before,
.header-background:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#site-header.fixed.shrink .top-area-background:before,
#site-header.fixed.shrink .header-background:before {
	opacity: 0.95;
}

#primary-menu.no-responsive .menu-item-socials,
.vertical-menu-item-widgets .menu-item-socials{
	margin-top: 25px;
}
#primary-menu.no-responsive .menu-item-socials .socials,
.vertical-menu-item-widgets .socials{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
}
#primary-menu.no-responsive .menu-item-socials .socials .socials-item,
.vertical-menu-item-widgets .socials .socials-item{
	padding: 0 5px;
	margin: 10px 0 0 0;
}
.vertical-menu-item-widgets .socials .socials-item{
	width: calc(100% / 6);
}

/*load header fix*/
@media only screen and (min-device-width : 320px) and (max-device-width : 992px) and (-webkit-min-device-pixel-ratio: 2) {
	#page:not(.vertical-header) #site-header.animated-header {
		height: 67px !important;
		overflow: hidden !important;
	}
	#page:not(.vertical-header) #site-header.animated-header.ios-load {
		height: auto !important;
		overflow: visible !important;
	}
}
	