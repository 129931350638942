@import "src/client_customizations/scss/client_master.scss";

.select-languege-dropdown {
	background-color: #ffffff;
    color: #9f1b96;
    font-size: 14px;
    padding: 5px;
    border-color: #9f1b96;
}

.menu-item-indented {
	a {
		padding-left: 4rem !important;
	}
}

/* Apply yellow span underline for mobile views */
.top-area-items {
	margin: 0px !important;
}

.top-area-socials {
	margin: 0px !important;
}

#primary-menu.no-responsive {
	margin: 0 38px !important;
}

.top-area-menu .nav-menu>li a {
	display: block;
	font-size: 14px;
	text-decoration: none;
}

#top-area {
	margin-top: 0;
}

.top-area-menu {
	.nav-menu {
		.active {
			// color: #FBEB71;
			font-weight: bold;
		}

		.language-toggle-button {
			&:hover {
				// color: #FBEB71;
				color: #9f1b96;
				font-weight: bold;
			}
		}
	}
}



.Header {
	background-color: $Header-background-color;
	border-bottom: $Header-border-bottom;

	a {
		color: $Header-link-color;
	}

	.navbar {
		padding-top: 0;
		padding-bottom: 0;

		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;

			img {
				width: 160px;
			}
		}

		.navbar-collapse {
			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}
		}
	}

	.nav-item {
		margin: 5px;
	}
}

.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}

a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}

	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}

	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}

.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color: $Header-navbar-toggler-color;

	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;

			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}

				a.active {
					font-weight: bold;
				}
			}
		}
	}
}

@media(max-width: 768px) {
	.dl-menuopen {
		transition: opacity .15s linear;
	}
}

@media (max-width: 480px) {
	.mobile-issue {
		display: none;
	}
}