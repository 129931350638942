// @TODO import client_master.scss for constants.
// @TODO actually... do we need this? YES WE DO!!
@import "src/client_customizations/scss/client_master.scss";

/* Charger Cost Breakdown Chart */
.breakdown-table-cat {
    font-size: 18px;
    background-color: white;
    padding: 1rem 1.5rem;
    // margin-right: 6%;
    
    .itemized-name {
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }

    .itemized-total-value {
        background-color: $white;
    }

    .itemized-value,
    .itemized-total-value {
        min-width: 60%;
        text-align: right;
    }

    .itemized-total-name,
    .itemized-total-value {
        font-weight: bold;
    }

    th {
        width: 75%;
        height: 50px;
    }

    .final-charger-cost {
        font-weight: bold;

        th {
            font-weight: bold;
        }

        .itemized-total-value {
            background-color: $gray;
            color: white;
        }
    }

    h3 {
        display: inline-block;
        padding-bottom: 1.5rem;
        margin: 0;
        font-size: 14px;
        line-height: 0px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: "Raleway";
        color: black;


    }
}