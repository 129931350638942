@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	flex-basis: 49%;
	margin-right: 1%;
	margin-bottom: 1%;
	border-radius: $IncentiveCard-card-border-radius;
	text-align: center;
	flex-direction: column;
	justify-content: space-between;

	.IncentiveCardTitle{
		color:#5F727F;
		font-family: $H1-font-family;
		font-size: 1.0rem;
		font-weight: 400;
		margin-top: 3rem;
		margin-bottom: 3rem;
		padding: 0 1rem;
		text-transform: uppercase;
	}

	.IncentiveCardIncentiveAmount{
		color:#5F727F;
		font-family: $H1-font-family;
		font-size: 3.0rem;
		font-weight: 400;
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	.incentive-card-button{
		border-radius: 4px;
		text-transform: uppercase;
		height: 52px;
		width: 100%;
	}

}

.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;


	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardBottom {
	// padding: 1.6rem;
	font-size: 1rem;
	padding: 1.8rem;
	line-height:1.5rem;
	font-weight: 500;
	text-align: center;
}

.IncentiveCardTop {
  border-radius: 0px;
  color: white;
  width: 100%;
  display: block;
  height: 30px;
  line-height: 30px;
  padding: 1px;
  text-align: center;
  font-weight: 600;
}

.card-secondary-description {
	margin-top: 2rem;
	font-size: .8rem;
	text-align: center;
}

.purple {
	background-color: $IncentiveCard-top-purple;
}
.orange {
	background-color: $IncentiveCard-top-orange;
}
.aqua {
	background-color: $IncentiveCard-top-aqua;
}
.green {
	background-color: $IncentiveCard-top-green;
}
.gray {
	background-color: $IncentiveCard-top-gray;
}
@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 32%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}