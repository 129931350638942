@import "src/client_customizations/scss/client_master.scss";

.all-incentives-modal {
    .modal-body {
        .modal-html-title {
            padding-top: 56px;
        }

        .close-button {
            position: absolute;
            font-size: 4rem;
            right: 8px;
            top: 8px;
            cursor: pointer;
        }
    }
}

@media only screen and (min-width : 0px) {
    .all-incentives-modal {
        max-width: 96% !important;

        .incentiveCardsContainer {
            flex-direction: column;
            padding: 8px;
        }

    }
}

@media only screen and (min-width : 768px) {
    .all-incentives-modal {
        max-width: 92% !important;

        .incentiveCardsContainer {
            flex-direction: initial;

            .IncentiveCard {
                max-width: 328px;
                min-width: 288px;
                flex-grow: 1;
            }
        }

        .disclaimer {
            margin-top: -1rem;
            margin-bottom: 2rem;
        }
    }
}

@media only screen and (max-width : 1199px) {
    .all-incentives-modal {
        .disclaimer {
            margin-top: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

@media only screen and (min-width : 1200px) {
    .all-incentives-modal {
        max-width: 80% !important;
    }
}