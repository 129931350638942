/*a.gflag {font-size:24px;padding:1px 0;background-repeat:no-repeat;background-image:url(24.png);} */
*a.gflag img {border:0;height:24px;display:inline;}
/*a.gflag:hover {background-image:url(24a.png);}*/
img.gstats {display:none;}
/*a.alt_flag {background-image:url(alt_flags.png);}*/
/*a.alt_flag:hover {background-image:url(alt_flagsa.png);}*/
a.us_flag {background-position:-0px -100px;}
a.br_flag {background-position:-100px -100px;}
a.mx_flag {background-position:-200px -100px;}

a.glink {text-decoration:none;}
a.glink span {margin-right:5px;font-size:15px;vertical-align:middle;}
a.glink img {vertical-align:middle;display:inline;border:0;padding:0;margin:0;opacity:0.8;}
a.glink:hover img {opacity:1;}