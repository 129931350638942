$color_1: #07679b;
$color_2: #666666;
$color_3: rgb(225, 66, 39);
$color_4: #fff;
$font_family_1: Raleway, Helvetica, sans-serif;
$background_color_1: #dadada;
$background_color_2: white;
$background_color_3: #e5e5e5;
$background_color_4: rgb(225, 66, 39);
$background_color_5: #292c2c;
$background_color_6: #c14227;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

body {
	background-color: $background_color_3 !important;
	font-family: $font_family_1;
	font-size: 12px;
	overflow-x: hidden;
}
h1 {
	font-size: 22px;
}
.h1 {
	font-size: 22px;
}
h2 {
	font-size: 18px;
}
.h2 {
	font-size: 18px;
}
.modal-title {
	font-size: 18px;
}
h3 {
	font-size: 14px;
}
.h3 {
	font-size: 14px;
}
h4 {
	font-size: 12px;
}
.h4 {
	font-size: 12px;
}
h5 {
	font-size: 10px;
}
.h5 {
	font-size: 10px;
}
.lead {
	font-size: 16px;
}
a {
	color: $color_1;
}
table {
	tbody {
		th {
			font-weight: normal;
		}
		tr {
			th {
				&:first-child {
					text-align: left;
				}
			}
		}
	}
	tfoot {
		tr {
			th {
				&:first-child {
					text-align: left;
				}
			}
		}
	}
}
table.table-bordered {
	tfoot {
		td {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
		th {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
	}
}
button.btn-sm {
	font-size: 80%;
}
/**
Fixing bootstrap columns for safari
 */
 .row:before, .row:after{
	display: inline-block !important;
}

.input-well {
	.button.btn-sm {
		font-size: 80%;
	}
	background-color: $background_color_2;
	padding: 25px;
	border-radius: 4px;
	border: 1px solid #dbdbdb;
	.form-group {
		margin-bottom: 1.5rem;
		p {
			margin-top: 8px;
			margin-bottom: 1rem;
		}
	}
	form {
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	label {
		font-weight: 700;
		font-size: 12px;
		color: $color_2;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.label-style {
		font-weight: 700;
		font-size: 12px;
		color: $color_2;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.btn {
		background-color: #fff;
		display: inline-block;
		border: 1px solid #dbdbdb;
		font-weight: 400;
		font-size: 1rem;
		font-weight: 700;
		font-family: "IntroBold";	
	}
	.socketType{
		text-align: left;
		margin-left: .5rem;
	}
	.socketType:last-of-type {
		text-align: center;
		
	}
	
	.btn.active {
		background-color: $background_color_4;
		border: 1px solid #5ca4d1;
		color: white;
	}
	.btn-block-container {
		.btn {
			display: block;
			width: 100%;
			margin-bottom: 6px;
			text-align: left;
			.badge {
				background-color: $background_color_2;
				border: 1px solid #dbdbdb;
				margin-right: 5px;
				img {
					width: 20px;
				}
			}
		}
	}
	.btn-grid-container {
		display: flex;
		flex-wrap: wrap;
		button {
			width: 48%;
			margin: 2%;
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
				margin-left: 0;
					}
		}
	}
	.btn-ae {
		padding: 6px 12px;
		border-radius: 0;
		border: 0;
		color: $color_4;
		font-weight: 600;
		background-color: $background_color_4;
	}
}
.btn {
	text-transform: uppercase;
	font-size: 12px;
}
.btn-default {
	border: 1px solid #ced4da;
}
strong {
	font-weight: 500;
}
hr {
	border-top: 2px solid rgba(0, 0, 0, 0.8);
	margin: 0;
	padding: 0;
}
.arrow-up {
	top: calc((1rem - 1px) * -1);
	position: absolute;
	display: block;
	width: 1rem;
	height: 1rem;
	margin: 0 0.3rem;
	&:before {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 0;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_1;
	}
	&:after {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 1px;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_2;
	}
}
.background-grey {
	background-color: $background_color_5;
}
.bottom-thick-margin {
	border-bottom: 2px solid #000000;
}
.color-primary {
	color: $color_3;
}
.btn-ae {
	padding: 6px 12px;
	border-radius: 0;
	border: 0;
	color: $color_4;
	font-weight: 600;
	background-color: $background_color_4;
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}
.PageLayout {
	a.btn-ae {
		&:hover {
			border-radius: 0;
			color: $color_4;
			font-weight: 600;
			text-decoration: none;
			background-color: $background_color_6;
		}
	}
}
a.btn-ae {
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}

// .select-ae {
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	-ms-appearance: none;
// 	appearance: none;
// 	background-repeat: no-repeat;
// 	background-position: right 15px top 11px, right 10px top 1em;
// 	background-size: 15px 15px, 15px 15px;
// 	background-image: url(../../assets/images/icons/Link-arrow.png);
// }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS */
	.select-ae {
		background-size: 0;
	}
 }
