@import "src/client_customizations/scss/client_master.scss";

.chargerWifiFilter {
    .btn {
        height: 3.5rem !important;
    }
}

.liheap-control-component {
    line-height: 1.2rem;

    .btn {
        height: 3.5rem !important;
    }
}

.charger-incentive-catalog-top,
.incentive-modifier {
    background-color: white;
    color: #222;
    border: none;
    font-family: "Raleway";

    .intro {
        font-size: 2.3rem;
        font-weight: 600;
        padding-top: 1rem;
    }

    .sectionBody {
        font-size: 1.3rem;
        font-weight: 500;
        padding-top: 1rem;
        color: #222;
    }
}

.incentive-modifier {
    .modify-charger-incentives-label {
        color: #222;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.5rem;
        margin-top: 1.5rem;
        padding-left: 0px;
    }

    .longLabel {
        margin-top: 0rem;
    }

    .btn {
        height: 2.25rem;
    }
}

.charger-incentive-catalog-top {
    width: 100vw;
    background-color: white;
    padding: 1rem;
    padding-left: 2rem;

    .intro {
        line-height: 1em;
        color: #222;
    }

    a {
        color: $red;
    }

    h2 {
        margin-top: 1.75rem;
        font-size: 28px;
        font-weight: 600;
    }

    .row,
    .input-well,
    .incentive-modifier {
        background-color: white;
    }

    .incentive-modifier {
        .zip-input-group, .install-cost-group, .chargerWifiFilter, .liheap-control-component {
            min-width: 250px;
            margin-top: 0;
        }
        button {
            margin-top: 0;
        } 
        .liheap-control-component.col-md-6 {
            // @TODO only apply width of 250 when at medium size.
            .longLabel {
                display: block;
                width: 250px !important;
            }
        }
    }
}

// @TODO You do meand ChargerFilterControls, right???
.ChargerFilterControls {
    select {
        font-size: 0.75rem !important;
    }
    .btn {
        font-size: 0.625rem !important;
    }
    .socket-text {
        float: left;
    }
    .socket-image {
        float: right;
    }

}

.incentive-modifier {
    padding: 0;
}

#charger-sort-dropdown {
    background-color: $white;
    color: $gray;
    border: none;
    text-align-last: right;
    font-size: 16px !important;


    option {
    
        direction: rtl;
    }
}

.show-all-incentives-button {
    background-color: $black;
    color: $white;
    max-width: 271px;
    max-height: 64px;
    padding: 1em;
    padding-left: 2em;
    text-transform: uppercase;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    font-family: $H1-font-family;

    .content {
        font-weight: 700;
        line-height: 1rem;
        font-size: 0.75rem;
    }
}

@media (min-width:0) {
    .modal-dialog{
        max-width: 100%;
    }    

    .show-all-incentives-button {
        img {
            margin-top: 0.25rem;
        }
        .content {
            font-size: 0.75rem;
            margin-top: 4rem;
            padding-left: 2rem;
        }
    }   
}    

@media (min-width:576px) {
    .show-all-incentives-button {
        img {
            margin-top: 0.25rem;
        }
        .content {
            font-size: 1rem;
            margin-top: 4.12rem;
        }
    }   
}    

@media (min-width: 768px) {
    .modal-dialog{
        max-width: 80%;
    }    

    .show-all-incentives-button {
        img {
            margin-top: 0.25rem;
        }
        .content {
            font-size: 0.75rem;
            margin-top: 3.5rem;
        }
    }   
}    

@media (min-width: 992px) {
    .show-all-incentives-button {
        img {
            margin-top: -0.25rem;
        }
        .content {
            font-size: 0.66rem;
            margin-top: 2.5rem;
        }
    }   
}    

@media (min-width: 1200px) {
    .show-all-incentives-button {
        img {
            margin-top: -0.1rem;
        }
        .content {
            font-size: 0.75rem;
            margin-top: -0.25rem;
        }
    }
}    

.product-incentive-counter {
    margin-top: 0.5rem;
    font-size: 16px;

    .product-counter-count {
        font-size: 1.25rem;
    }

    .product-incentive-upto,
    .product-counter-count {
        font-weight: 800;
    }
}

.small-to-mid-modal-button {
    button, .show-all-incentives-button {
        min-width: 100%;
        min-height: 5rem;    
    }
    button {
        font-size: 2rem;
        border-radius: 4px;
        font-family: $H1-font-family;
    }
}

.filters-launcher-button {
    width: 49px;
    height:49px;
    border-radius: 24px;
    background-color: white;
    text-align: center;
    padding-top:10px;
    position: fixed;
    right: 12px;
    top: 220px;
    z-index: 9999;
}

.charger-catalog-xs {
    .ChargerCard {
        min-width: 100%;
    }
}

.all-incentives-modal {
    .close-button-wrapper {
        font-size: 32px;
        font-weight: 700;
        width: 3rem;
        height: 3rem;
        border: none;
        cursor: pointer;
    }

    .modal-title-h1, .modal-title-h2 {
        color: $darkGray;
    }
    .modal-title-h1 {
        text-transform: uppercase;
        font-size: 32px;
        text-align: center;
        margin-top: 1em;
    }
    .modal-title-h2 {
        font-size: 20px;
        text-align: center;
        font-weight: 400;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .IncentiveCard {
        background-color: white;
        border-radius: 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 41px 0px;
    }
    .close {
        span {
            font-size: 32px;
        }
    }
    .modal-body {
        padding: 0;
    }
}

// Charger cards
.charger-details-and-image {
    font-size: 12px;
    line-height: 170%;
    color: #5f727f;
}

.ChargerCard {
    h2 {
        font-size: 24px;
        font-weight: 800;
    }

    h3 {
        font-size: 14px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    h2,
    h3 {
        color: $gray;
    }
}

.charger-price {
    font-family: $H1-font-family;
    font-weight: bold;
    color: $gray;
    padding: 0;
}



.charger-price-exp {
    font-size: 12px;
    color: $gray;
    padding-right: 0;
    padding-left: 2rem;
}

.charger-price-and-text {
    border-color: $lightGray;
    border-style: solid;
    border-width: 1px;
    width: 85%;
    margin: 0 auto;
    .charger-price-exp {
        line-height: 1rem;
    }
}

.buy-charger {
    font-family: $H1-font-family;
    font-size: 16px;
    font-weight: 700;

    .btn-ae {
        border-radius: 4px;
        width: 97%;
    }
}

.lean-charger-card {
    background-color: white;
    max-height: 10rem;

    img {
        max-height: 8em;
    }

    .card-left {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
    }

    .btn-ae {
        padding: 0.5rem 4rem;
        font-size: 16px;
        margin-left: 0.5rem;
        border-radius: 4px;
    }

    .card-right {
        padding: 1rem;
        padding-left: 2rem;

        h2 {
            font-size: 2rem;
        }
    }
}

.full-step-details {
    margin-top: 2rem;
    text-align: left !important;
}

.redeem-step-number {
    background-color: $black;
    color: $white;
    width: 48px;
    height: 48px;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 24px;
    line-height: 48px;
    white-space: nowrap;
}