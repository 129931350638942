
@font-face {
	font-family: 'Montserrat UltraLight';
	src: url('//www.electricforall.org/wp-content/themes/thegem/fonts/montserrat-ultralight.eot');
	src: url('//www.electricforall.org/wp-content/themes/thegem/fonts/montserrat-ultralight.eot?#iefix') format('embedded-opentype'),
		url('//www.electricforall.org/wp-content/themes/thegem/fonts/montserrat-ultralight.woff') format('woff'),
		url('//www.electricforall.org/wp-content/themes/thegem/fonts/montserrat-ultralight.ttf') format('truetype'),
		url('//www.electricforall.org/wp-content/themes/thegem/fonts/montserrat-ultralight.svg#montserratultra_light') format('svg');
		font-weight: normal;
		font-style: normal;
}


@font-face {
	font-family: 'IntroBold';
	src: url('//electricforall.wpengine.com/wp-content/uploads/2020/04/37C07C_0_0.eot');
	src: url('//electricforall.wpengine.com/wp-content/uploads/2020/04/37C07C_0_0.eot?#iefix') format('embedded-opentype'),
		url('//electricforall.wpengine.com/wp-content/uploads/2020/04/37C07C_0_0.woff') format('woff'),
		url('') format('truetype'),
		url('#') format('svg');
		font-weight: normal;
		font-style: normal;
}

/* GENERATED BY THEGEM THEME OPTIONS */



body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body .title-h1,
body .title-h2,
body .title-h3,
body .title-h4,
body .title-h5,
body .title-h6,
body h1 .light,
body h2 .light,
body h3 .light,
body h4 .light,
body h5 .light,
body h6 .light,
body .title-h1 .light,
body .title-h2 .light,
body .title-h3 .light,
body .title-h4 .light,
body .title-h5 .light,
body .title-h6 .light,
body .title-xlarge,
body .title-xlarge .light,
#primary-menu.no-responsive li,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th,
#primary-menu.no-responsive > li > a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
body #primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a {
	text-transform: none ;
}

@media (min-width: 768px) and (max-width: 979px) {
	#site-header .header-main {
		position: relative;
		display: table;
		width: 100%;
		z-index: 11;
	}
	#primary-navigation .menu-toggle,
	#perspective-menu-buttons .menu-toggle {
		display: inline-block;
	}
	.mobile-cart-position-top #site-header .mobile-cart {
		display: table-cell;
	}
	.mobile-cart-position-top #site-header .site-title {
		text-align: center;
		width: 99%;
	}
	.mobile-cart-position-top #site-header .site-title .site-logo {
		margin: 0 auto;
	}
	.mobile-cart-position-top #site-header .primary-navigation,
	.mobile-cart-position-top #site-header #perspective-menu-buttons {
		width: auto;
	}
	#perspective-menu-buttons .perspective-toggle,
	#perspective-menu-buttons .hamburger-minicart {
		display: none;
	}
	#primary-navigation .hamburger-toggle,
	#primary-navigation .overlay-toggle {
		display: none;
	}
	.primary-navigation .dl-menu {
		position: absolute;
		left: 0;
		right: 0;
		opacity: 0;
	}
	.mobile-menu-layout-overlay #primary-navigation .overlay-menu-wrapper {
		visibility: hidden;
		height: 0;
	}
	#page.vertical-header #site-header-wrapper{
		position: static;
		width: 100%;
		padding: 0;
	}
	#page.vertical-header{
		padding-left: 0;
	}
	#page.vertical-header #site-header .site-title {
		display: table-cell;
	}
	#page.vertical-header #site-header .primary-navigation,
	#page.vertical-header #site-header #perspective-menu-buttons {
		display: table-cell;
	}
	.vertical-menu-item-widgets{display: none;}
	#page #site-header .site-title {
		display: table-cell;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	#page #site-header .primary-navigation,
	#page #site-header #perspective-menu-buttons {
		display: table-cell;
		text-align: right;
	}
	#page #site-header .logo-position-right .primary-navigation,
	#page #site-header .logo-position-right #perspective-menu-buttons {
		text-align: left;
	}
	#page.vertical-header .vertical-toggle {
		display: none;
	}
	#page.vertical-header {
		padding-left: 0;
	}
	#page.vertical-header #site-header-wrapper {
		margin-left: 0;
	}
	#page.vertical-header #site-header-wrapper .header-main {
		display: table;
		visibility: visible;
	}
	#site-header .site-title a img.default,
	#site-header .menu-item-logo a img.default {
		display: none;
	}
	#site-header .site-title a img.small,
	#site-header .menu-item-logo a img.small {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		filter: alpha(opacity=100);
		-moz-opacity: 1;
		-khtml-opacity: 1;
		opacity: 1;
		display: inline-block !important;
		margin: 0 !important;
	}
	#site-header .site-title a img.small.light,
	#site-header .menu-item-logo a img.small.light {
		display: inline-block !important;
	}
	#site-header .site-title a img.small.light ~ img.small,
	#site-header .menu-item-logo a img.small.light ~ img.small {
		display: none !important;
	}
	#site-header.fixed.shrink .site-title a img.small.light,
	#site-header.fixed.shrink .menu-item-logo a img.small.light {
		display: none !important;
	}
	#site-header.fixed.shrink .site-title a img.small.light ~ img.small,
	#site-header.fixed.shrink .menu-item-logo a img.small.light ~ img.small {
		display: inline-block !important;
	}
}



#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	font-family: 'Raleway';
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	font-style: normal;
	font-weight: 700;
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	font-size: 18px;
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	line-height: 25px;
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	text-transform: none;
}

#primary-menu.no-responsive > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a {
	letter-spacing: 0px;
}

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	font-family: 'Raleway';
}
.primary-navigation.responsive li a {
	font-family: 'Raleway';
}

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	font-style: normal;
	font-weight: normal;
}
.primary-navigation.responsive li a {
	font-style: normal;
	font-weight: normal;
}


#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	font-size: 16px;
}
.primary-navigation.responsive li a {
	font-size: 16px;
}

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul {
	line-height: 20px;
}
.primary-navigation.responsive li a {
	line-height: 20px;
}

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul,
.primary-navigation.responsive li a {
	text-transform: none;
}

#primary-menu.no-responsive > li.megamenu-enable > ul li > a,
#primary-menu.no-responsive > li li > a,
#primary-menu.no-responsive > li .minicart-product-title,
.portfolio-filters-resp ul li a,
.widget_nav_menu ul.menu,
.widget_submenu > div > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_pages > ul,
.primary-navigation.responsive li a {
	letter-spacing: 0px;
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a {
	font-family: 'Raleway';
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a {
	font-style: normal;
	font-weight: 100;
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
	font-size: 12px;
}
@media (max-width: 767px) {
	.header-layout-overlay #primary-menu.no-responsive > li > a,
	.header-layout-overlay #primary-menu.no-responsive > li li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
	.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
	.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
		font-size: 9px;
	}
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
	line-height: 18px;
}
@media (max-width: 767px) {
	.header-layout-overlay #primary-menu.no-responsive > li > a,
	.header-layout-overlay #primary-menu.no-responsive > li li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
	.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
	.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
	.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
		line-height: 14px;
	}
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
	text-transform: none;
}

.header-layout-overlay #primary-menu.no-responsive > li > a,
.header-layout-overlay #primary-menu.no-responsive > li li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li > a,
.page-additional-menu.header-layout-overlay .nav-menu.no-responsive > li li > a,
.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before,
.page-additional-menu.header-layout-overlay #primary-navigation #primary-menu.no-responsive li .menu-item-parent-toggle:before {
	letter-spacing: 0px;
}

.styled-subtitle,
.diagram-circle .text div span.title,
.diagram-circle .text div span.summary,
.vc_pie_chart .vc_pie_chart_value,
form.cart .quantity .qty,
.shop_table .quantity .qty,
.woocommerce-before-cart .cart-short-info,
input[type="text"].coupon-code,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.gem-dropcap.gem-dropcap-style-medium,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title,
.single-product-content .price,
.widget_shopping_cart ul li .quantity,
.widget_shopping_cart .total span.amount {
	font-family: 'Raleway';
}

.styled-subtitle,
.diagram-circle .text div,
.diagram-circle .text div span.title,
.diagram-circle .text div span.summary,
.vc_pie_chart .vc_pie_chart_value,
form.cart .quantity .qty,
.shop_table .quantity .qty,
.woocommerce-before-cart .cart-short-info,
input[type="text"].coupon-code,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.gem-dropcap.gem-dropcap-style-medium,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title,
.single-product-content .price,
.widget_shopping_cart ul li .quantity,
.widget_shopping_cart .total span.amount {
	font-style: normal;
	font-weight: 300;
}

.styled-subtitle,
.vc_pie_chart,
form.cart .quantity .qty,
.shop_table .quantity .qty,
.woocommerce-before-cart .cart-short-info,
input[type="text"].coupon-code,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.gem-dropcap.gem-dropcap-style-medium,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title,
.widget_shopping_cart .total span.amount {
	font-size: 24px;
}
@media (max-width: 600px) {
	.styled-subtitle,
	.vc_pie_chart,
	form.cart .quantity .qty,
	.shop_table .quantity .qty,
	.woocommerce-before-cart .cart-short-info,
	input[type="text"].coupon-code,
	.cart_totals table th,
	.order-totals table th,
	.cart_totals table .shipping td,
	.woocommerce-message,
	.woocommerce-info,
	.woocommerce ul.woocommerce-error li,
	.woocommerce table.checkout-cart-info-table tr th
	.woocommerce table.checkout-cart-info-table tr.shipping td,
	.widget_calendar caption,
	.blog-style-timeline .post-time,
	.gem-dropcap.gem-dropcap-style-medium,
	.project-info-shortcode-style-default .project-info-shortcode-item .title,
	.project_info-item-style-2 .project_info-item .title,
	.diagram-legend .legend-element .title,
	.widget_shopping_cart .total span.amount{
		font-size: 4vw;
	}
}

.styled-subtitle,
.woocommerce-before-cart .cart-short-info,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title {
	line-height: 37px;
}
@media (max-width: 600px) {
	.styled-subtitle,
	.woocommerce-before-cart .cart-short-info,
	.cart_totals table th,
	.order-totals table th,
	.cart_totals table .shipping td,
	.woocommerce-message,
	.woocommerce-info,
	.woocommerce ul.woocommerce-error li,
	.woocommerce table.checkout-cart-info-table tr th,
	.woocommerce table.checkout-cart-info-table tr.shipping td,
	.widget_calendar caption,
	.blog-style-timeline .post-time,
	.project-info-shortcode-style-default .project-info-shortcode-item .title,
	.project_info-item-style-2 .project_info-item .title,
	.diagram-legend .legend-element .title {
		line-height: 6.16666666667vw;
	}
}



.styled-subtitle,
.woocommerce-before-cart .cart-short-info,
.cart_totals table th,
.order-totals table th,
.cart_totals table .shipping td,
.woocommerce-message,
.woocommerce-info,
.woocommerce ul.woocommerce-error li,
.woocommerce table.checkout-cart-info-table tr th,
.woocommerce table.checkout-cart-info-table tr.shipping td,
.widget_calendar caption,
.blog-style-timeline .post-time,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.project_info-item-style-2 .project_info-item .title,
.diagram-legend .legend-element .title {
	letter-spacing: 0px;
}

h1,
body .pricing-table-style-8 .pricing-cost,
.title-h1 {
	font-family: 'IntroBold';
}

h1,
body .pricing-table-style-8 .pricing-cost,
.title-h1 {
	font-style: normal;
	font-weight: normal;
}

h1,
.title-h1 {
	font-size: 50px;
}
@media (max-width: 1000px) {
	h1,
	.title-h1 {
		font-size: 5vw;
	}
}

h1,
.title-h1 {
	line-height: 69px;
}
@media (max-width: 1000px) {
	h1,
	.title-h1 {
		line-height: 6.9vw;
	}
}



h1,
.title-h1 {
	letter-spacing: 0px;
}

h2,
.title-h2,
h3.comment-reply-title,
body .pricing-table-style-6 .pricing-price-title {
	font-family: 'IntroBold';
}

h2,
.title-h2,
h3.comment-reply-title,
body .pricing-table-style-6 .pricing-price-title {
	font-style: normal;
	font-weight: normal;
}

h2,
.title-h2,
body .pricing-table-style-5  .pricing-price-title,
body .pricing-table-style-6 .pricing-price-title,
body .pricing-table-style-6 .pricing-price-subtitle,
h3.comment-reply-title,
body .pricing-table-style-2 .pricing-price-title {
	font-size: 56px;
}
@media (max-width: 1000px) {
	h2,
	.title-h2,
	body .pricing-table-style-5  .pricing-price-title,
	body .pricing-table-style-6 .pricing-price-title,
	body .pricing-table-style-6 .pricing-price-subtitle,
	h3.comment-reply-title,
	body .pricing-table-style-2 .pricing-price-title {
		font-size: 5.6vw;
	}
}

h2,
.title-h2,
body .pricing-table-style-6 .pricing-price-title,
h3.comment-reply-title {
	line-height: 64px;
}
@media (max-width: 1000px) {
	h2,
	.title-h2,
	body .pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		line-height: 6.4vw;
	}
}



h2,
.title-h2,
body .pricing-table-style-6 .pricing-price-title,
h3.comment-reply-title {
	letter-spacing: 0px;
}

h3,
.title-h3,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.gem-dropcap,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	font-family: 'IntroBold';
}

h3,
.title-h3,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.gem-dropcap,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	font-style: normal;
	font-weight: normal;
}

h3,
.title-h3,
.portfolio.columns-1 .portfolio-item .caption .title,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.gem-dropcap,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	font-size: 40px;
}

h3,
.title-h3,
.portfolio.columns-1 .portfolio-item .caption .title,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	line-height: 48px;
}



h3,
.title-h3,
.portfolio.columns-1 .portfolio-item .caption .title,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	letter-spacing: 0px;
}

h4,
.title-h4,
.widget .gem-teams-name,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-8 .pricing_row_title,
body .pricing-table-style-4 .pricing_row_title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title {
	font-family: 'IntroBold';
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		font-family: 'IntroBold';
	}
}

h4,
.title-h4,
.widget .gem-teams-name,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-4  .pricing_row_title,
body .pricing-table-style-8 .pricing_row_title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title {
	font-style: normal;
	font-weight: normal;
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		font-style: normal;
		font-style: normal;
	font-weight: normal;
	}
}

h4,
.title-h4,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-4 .pricing_row_title,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-2 .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-1 .pricing-price-title,
.widget .gem-teams-name,
body .pricing-table-style-8 .pricing_row_title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title,
body .vc_separator h4 {
	font-size: 24px;
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		font-size: 24px;
	}
}

h4,
.title-h4,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-5 .pricing-price-title,
body .pricing-table-style-4 .pricing_row_title,
body .pricing-table-style-3 .pricing_row_title,
body .pricing-table-style-2 .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-2 .pricing-price-title,
body .pricing-table-style-1 .pricing-price-title,
.widget .gem-teams-name,
.portfolio.columns-1 .portfolio-item .caption .title,
.gem-gallery-hover-gradient .gem-gallery-item-title,
.gem-gallery-grid.hover-gradient .gallery-item .overlay .title,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-item-title,
body .vc_separator h4 {
	line-height: 32px;
}
@media only screen and (min-width: 992px) and (max-width: 1150px) {
	.with-sidebar .portfolio.hover-horizontal-sliding .overlay .links .caption .title {
		line-height: 32px;
	}
}



h4,
.title-h4,
body .vc_separator h4 {
	letter-spacing: 0px;
}

h5,
.gem-table thead th,
.title-h5,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-family: 'IntroBold';
}

h5,
.title-h5,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-style: normal;
	font-weight: normal;
}

h5,
.title-h5,
.header-layout-overlay #primary-menu.no-responsive > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-size: 19px;
}

h5,
.title-h5,
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	line-height: 27px;
}



h5,
.title-h5,
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after,
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.gem-teams-phone,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	letter-spacing: 0px;
}

h6,
.title-h6,
body .woocommerce .shop_table thead tr th,
.project_info-item-style-1 .project_info-item .title,
.project-info-shortcode-style-2 .project-info-shortcode-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.hamburger-minicart .minicart-item-count,
.wpb_toggle,
.vc_toggle_title h4,
.testimonials-style-1-name.gem-testimonial-name,
.testimonials-style-2-name.gem-testimonial-name,
.diagram-wrapper .digram-line-box .skill-amount,
.diagram-wrapper.style-3 .digram-line-box .skill-title,
.row .vc_progress_bar .vc_label,
.woocommerce .shop_table thead tr th,
.pricing-column-top-choice .pricing-column-top-choice-text {
	font-family: 'IntroBold';
}

h6,
.title-h6,
body .woocommerce .shop_table thead tr th,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.hamburger-minicart .minicart-item-count,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.testimonials-style-1-name.gem-testimonial-name,
.testimonials-style-2-name.gem-testimonial-name,
.diagram-wrapper .digram-line-box .skill-amount,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	font-style: normal;
	font-weight: normal;
}

h6,
.title-h6,
body .woocommerce .shop_table thead tr th,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	font-size: 16px;
}

h6,
.title-h6,
body .woocommerce .shop_table thead tr th,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	line-height: 25px;
}



h6,
.title-h6,
body .woocommerce .shop_table thead tr th,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
#wp-calendar caption,
.wpb_toggle,
.gem-table-responsive .tabletolist.rh > li > .titles,
.vc_toggle_title h4,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.row .vc_progress_bar .vc_label {
	letter-spacing: 0px;
}

.title-xlarge {
	font-family: 'IntroBold';
}

.title-xlarge {
	font-style: normal;
	font-weight: normal;
}

.title-xlarge {
	font-size: 80px;
}
@media (max-width: 1000px) {
	.title-xlarge{
		font-size: 8vw;
	}
}

.title-xlarge {
	line-height: 90px;
}
@media (max-width: 1000px) {
	.title-xlarge {
		line-height: 9vw;
	}
}



.title-xlarge {
	letter-spacing: 0px;
}

h1 .light,
h2 .light,
h3 .light,
h4 .light,
h5 .light,
h6 .light,
.title-h1 .light,
.title-h2 .light,
.title-h3 .light,
.title-h4 .light,
.title-h5 .light,
.title-h6 .light,
.title-xlarge .light,
h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
.title-h1.light,
.title-h2.light,
.title-h3.light,
.title-h4.light,
.title-h5.light,
.title-h6.light,
.title-xlarge.light,
.widget .gem-teams-name,
.gem-counter-style-2 .gem-counter-number,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-quote.gem-quote-style-3 blockquote,
.row  .vc_pie_wrapper span,
.blog-style-styled_list1 .post-title,
.blog-style-styled_list2 .post-time,
.blog-style-styled_list2 .post-title,
.blog-style-timeline .post-title,
body .pricing-table-style-1 .pricing-price-title,
body .pricing-table-style-1  .pricing-cost,
body .pricing-table-style-2 .pricing-price-title,
body .pricing-table-style-2  .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-5  .pricing-price-title,
body .pricing-table-style-6 .pricing-price-subtitle,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-7 .pricing-cost,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item > span,
.gem-media-grid .vc_gitem-post-data-source-post_title a,
.gem-media-grid-2 .vc_gitem-post-data-source-post_title a,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-family: 'IntroBold';
}

h1 .light,
h2 .light,
h3 .light,
h4 .light,
h5 .light,
h6 .light,
.title-h1 .light,
.title-h2 .light,
.title-h3 .light,
.title-h4 .light,
.title-h5 .light,
.title-h6 .light,
.title-xlarge .light,
h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light,
.title-h1.light,
.title-h2.light,
.title-h3.light,
.title-h4.light,
.title-h5.light,
.title-h6.light,
.title-xlarge.light,
.widget .gem-teams-name,
.gem-counter-style-2 .gem-counter-number,
.gem-quote.gem-quote-style-3 blockquote,
.row  .vc_pie_wrapper span,
.blog-style-styled_list1 .post-title,
.blog-style-styled_list2 .post-time,
.blog-style-timeline .post-title,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item > span,
.gem-media-grid .vc_gitem-post-data-source-post_title a,
body .pricing-table-style-1 .pricing-price-title,
body .pricing-table-style-1 .pricing-cost,
body .pricing-table-style-2 .pricing-price-title,
body .pricing-table-style-2 .pricing-cost,
body .pricing-table-style-2 .time,
body .pricing-table-style-5 .pricing-price-title,
body .pricing-table-style-6 .pricing-price-subtitle,
body .pricing-table-style-7 .pricing-price-title,
body .pricing-table-style-7 .pricing-cost,
.gem-media-grid-2 .vc_gitem-post-data-source-post_title a,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th {
	font-style: normal;
	font-weight: normal;
}

body,
option,
.gem-table thead th,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.widget_archive li,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
body .hamburger-minicart > .minicart .total strong,
body .hamburger-minicart > .minicart ul li .quantity{
	font-family: 'Raleway';
}

body,
option,
.gem-table thead th,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
body .hamburger-minicart > .minicart .total strong,
body .hamburger-minicart > .minicart ul li .quantity{
	font-style: normal;
	font-weight: normal;
}

body,
.gem-table thead th,
.inline-column,
.inline-inside > *,
option,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.portfolio-filters a,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
body .hamburger-minicart > .minicart .total strong,
body .hamburger-minicart > .minicart ul li .quantity{
	font-size: 16px;
}

body,
option,
.inline-column,
.inline-inside > *,
.related-element-info > a,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
body .hamburger-minicart > .minicart .total strong,
body .hamburger-minicart > .minicart ul li .quantity{
	line-height: 25px;
}


body,
option,
.gem-table thead th,
.portfolio.columns-1 .portfolio-item .caption .subtitle,
.gallery-item .overlay .subtitle,
.cart_totals table .shipping td label,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
body .hamburger-minicart > .minicart .total strong,
body .hamburger-minicart > .minicart ul li .quantity{
	letter-spacing: 0px;
}

.page-title-excerpt,
.custom-title-excerpt {
	font-family: 'Raleway';
}

.page-title-excerpt,
.custom-title-excerpt {
	font-style: normal;
	font-weight: 300;
}

.page-title-excerpt,
.custom-title-excerpt {
	font-size: 24px;
}
@media (max-width: 600px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		font-size: 4vw;
	}
}

.page-title-excerpt,
.custom-title-excerpt {
	line-height: 37px;
}
@media (max-width: 600px) {
	.page-title-excerpt,
	.custom-title-excerpt {
		line-height: 6.16666666667vw;
	}
}



.page-title-excerpt,
.custom-title-excerpt {
	letter-spacing: 0px;
}

.widget-title {
	font-family: 'Raleway';
}

.widget-title {
	font-style: normal;
	font-weight: 700;
}

.widget-title {
	font-size: 19px;
}

.widget-title {
	line-height: 30px;
}


.widget-title {
	letter-spacing: 0px;
}

.gem-button,
input[type='submit'],
.gem-pagination a,
.gem-pagination .current,
.blog-load-more button,
body .wp-block-button .wp-block-button__link,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	font-family: 'Raleway';
}

.gem-button,
input[type='submit'],
.gem-pagination a,
.gem-pagination .current,
.blog-load-more button,
body .wp-block-button .wp-block-button__link,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	font-style: normal;
	font-weight: 700;
}



.gem-button.gem-button-text-weight-thin {
	font-family: 'Raleway';
}

.gem-button.gem-button-text-weight-thin {
	font-style: normal;
	font-weight: normal;
}



.gem-nivoslider-title {
	font-family: 'Raleway';
}

.gem-nivoslider-title {
	font-style: normal;
	font-weight: 700;
}

.gem-nivoslider-title {
	font-size: 50px;
}

.gem-nivoslider-title {
	line-height: 69px;
}


.gem-nivoslider-title {
	letter-spacing: px;
}

.gem-nivoslider-description {
	font-family: 'Raleway';
}

.gem-nivoslider-description {
	font-style: normal;
	font-weight: normal;
}

.gem-nivoslider-description {
	font-size: 16px;
}

.gem-nivoslider-description {
	line-height: 25px;
}


.gem-nivoslider-description {
	letter-spacing: px;
}

.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	font-family: 'Montserrat';
}

.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	font-style: normal;
	font-weight: 700;
}

.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	font-size: 16px;
}

.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	line-height: 24px;
}


.portfolio-item-title,
.portfolio-item .wrap > .caption .title,
.fancybox-title .slide-info-title,
.blog.blog-style-masonry article .description .title {
	letter-spacing: 0px;
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	font-family: 'Source Sans Pro';
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	font-style: normal;
	font-weight: normal;
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	font-size: 16px;
}

.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	line-height: 24px;
}
.portfolio:not(.columns-1):not(.portfolio-style-masonry) .portfolio-item .wrap > .caption .subtitle {
	max-height: 24px;
}


.portfolio-item-description,
.portfolio-item .caption .subtitle,
.fancybox-title .slide-info-summary,
.blog.blog-style-masonry article .description .summary {
	letter-spacing: 0px;
}

.quickfinder-item-title {
	font-family: 'Montserrat';
}

.quickfinder-item-title {
	font-style: normal;
	font-weight: 700;
}

.quickfinder-item-title {
	font-size: 24px;
}

.quickfinder-item.col-md-2 .quickfinder-item-title,
.quickfinder-item.col-md-3 .quickfinder-item-title {
	font-size: 19.2px;
}

.quickfinder-item-title {
	line-height: 38px;
}

.quickfinder-item.col-md-2 .quickfinder-item-title,
.quickfinder-item.col-md-3 .quickfinder-item-title {
	line-height: 30.4px;
}


.quickfinder-item-title {
	letter-spacing: 0px;
}

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	font-family: 'Montserrat UltraLight';
}

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	font-style: normal;
	font-weight: normal;
}

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	font-size: 24px;
}

.quickfinder.quickfinder-title-thin .quickfinder-item.col-md-2 .quickfinder-item-title,
.quickfinder.quickfinder-title-thin .quickfinder-item.col-md-3 .quickfinder-item-title {
	font-size: 19.2px;
}

.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	line-height: 38px;
}

.quickfinder.quickfinder-title-thin .quickfinder-item.col-md-2 .quickfinder-item-title,
.quickfinder.quickfinder-title-thin .quickfinder-item.col-md-3 .quickfinder-item-title {
	line-height: 30.4px;
}


.quickfinder.quickfinder-title-thin .quickfinder-item-title {
	letter-spacing: 0px;
}

.quickfinder-item-text {
	font-family: 'Source Sans Pro';
}

.quickfinder-item-text {
	font-style: normal;
	font-weight: normal;
}

.quickfinder-item-text {
	font-size: 16px;
}

.quickfinder-item-text {
	line-height: 25px;
}


.quickfinder-item-text {
	letter-spacing: 0px;
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption .gem-gallery-item-title{
	font-family: 'Montserrat UltraLight';
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption .gem-gallery-item-title{
	font-style: normal;
	font-weight: normal;
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption .gem-gallery-item-title{
	font-size: 24px;
}

.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption .gem-gallery-item-title {
	line-height: 30px;
}


.gem-gallery-hover-zooming-blur .gem-gallery-item-title,
.gem-gallery-grid .gallery-item .overlay .title,
.gem-gallery .gem-gallery-caption .gem-gallery-item-title {
	letter-spacing: 0px;
}

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title{
    font-family: 'Montserrat';
}

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
    font-weight: 700;
}

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
    font-size: 24px;
}

.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
    line-height: 31px;
}


.gem-gallery-grid.hover-default .gallery-item .overlay .title,
.gem-gallery-hover-default .gem-gallery-item .gem-gallery-item-title {
	letter-spacing: 0px;
}


.gallery-description,
.gem-gallery-item-description,
.gallery-item .overlay .subtitle{
	font-family: 'Source Sans Pro';
}

.gallery-description,
.gem-gallery-item-description,
.gallery-item .overlay .subtitle{
	font-style: normal;
	font-weight: 300;
}

.gallery-description,
.gem-gallery-item-description,
.gallery-item .overlay .subtitle{
	font-size: 17px;
}

.gallery-description,
.gem-gallery-item-description,
.gallery-item .overlay .subtitle{
	line-height: 24px;
}


.gallery-description,
.gem-gallery-item-description,
.gallery-item .overlay .subtitle{
	letter-spacing: 0px;
}


body .gem-testimonial-text,
body .testimonials-style-1-text {
	font-family: 'Raleway';
}
body .gem-testimonial-text,
body .testimonials-style-1-text {
	font-style: normal;
	font-weight: 300;
}
body .gem-testimonial-text,
body .testimonials-style-1-text {
	font-size: 24px;
}
@media (max-width: 600px) {
	body .gem-testimonial-text,
	body .testimonials-style-1-text {
		font-size: 4vw;
	}
}
body .gem-testimonial-text,
body .testimonials-style-1-text {
	line-height: 36px;
}
@media (max-width: 600px) {
	body .gem-testimonial-text,
	body .testimonials-style-1-text {
		line-height: 6vw;
	}
}
body .gem-testimonial-text,
body .testimonials-style-1-text {
	letter-spacing: 0px;
}

body .gem-testimonial-name{
	font-family: 'Montserrat';
}
body .gem-testimonial-name{
	font-style: normal;
	font-weight: 700;
}
body .gem-testimonial-name{
	font-size: 14px;
}
body .gem-testimonial-name{
	line-height: 25px;
}
body .gem-testimonial-name{
	text-transform: uppercase;
}

body .gem-testimonial-company{
	font-family: 'Source Sans Pro';
}
body .gem-testimonial-company{
	font-style: normal;
	font-weight: 300;
}
body .gem-testimonial-company{
	font-size: 14px;
}
body .gem-testimonial-company{
	line-height: 25px;
}
body .gem-testimonial-company{
	text-transform: none;
}

body .gem-testimonial-position{
	font-family: 'Source Sans Pro';
}
body .gem-testimonial-position{
	font-style: normal;
	font-weight: 300;
}
body .gem-testimonial-position{
	font-size: 14px;
}
body .gem-testimonial-position{
	line-height: 25px;
}
body .gem-testimonial-position{
	text-transform: none;
}


.gem-counter-number,
.diagram-circle .text div span {
	font-family: 'Raleway';
}

.gem-counter-number,
.diagram-circle .text div span {
	font-style: normal;
	font-weight: 700;
}

.gem-counter-number {
	font-size: 50px;
}

.gem-counter-number {
	line-height: 69px;
}


.gem-counter-number {
	letter-spacing: 0px;
}


.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-family: 'Raleway';
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-style: normal;
	font-weight: 600;
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	font-size: 16px;
}



.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab.vc_active > a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-modern .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel.vc_active .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-tabs.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-outline .vc_tta-panel .vc_tta-panel-title
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel.vc_tta-style-modern .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-modern .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion.vc_tta-style-simple_dashed .vc_tta-panel .vc_tta-panel-title {
	letter-spacing: 0px;
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	font-family: 'Raleway';
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	font-style: normal;
	font-weight: 600;
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	font-size: 16px;
}

.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	line-height: 18px;
}


.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-tab a,
.wpb-js-composer .vc_tta.vc_tta-tabs .vc_tta-panel .vc_tta-panel-title,
.wpb-js-composer .vc_tta.vc_tta-accordion .vc_tta-panel .vc_tta-panel-title {
	letter-spacing: 0px;
}

.widget_shopping_cart_content .quantity,
.widget_shopping_cart_content .total .amount {
	font-family: 'Montserrat';
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	font-family: 'Montserrat';
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	font-style: normal;
	font-weight: 700;
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	font-size: 16px;
}

.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	line-height: 25px;
}


.product-info .product-title.title-h6,
.products .portfolio-item .wrap > .caption .title {
	letter-spacing: 0px;
}

.single-product-content .product_title {
	font-family: 'Montserrat UltraLight';
}

.single-product-content .product_title {
	font-style: normal;
	font-weight: normal;
}

.single-product-content .product_title {
	font-size: 28px;
}

.single-product-content .product_title {
	line-height: 42px;
}


.single-product-content .product_title {
	letter-spacing: 0px;
}

.widget .gem-products-title {
	font-family: 'Source Sans Pro';
}

.widget .gem-products-title {
	font-style: normal;
	font-weight: normal;
}

.widget .gem-products-title {
	font-size: 16px;
}

.widget .gem-products-title {
	line-height: 25px;
}


.widget .gem-products-title {
	letter-spacing: 0px;
}

.shop_table .product-name .product-title {
	font-family: 'Source Sans Pro';
}

.shop_table .product-name .product-title {
	font-style: normal;
	font-weight: normal;
}

.shop_table .product-name .product-title {
	font-size: 16px;
}

.shop_table .product-name .product-title {
	line-height: 25px;
}


.shop_table .product-name .product-title {
	letter-spacing: 0px;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	font-family: 'Source Sans Pro';
}

.products .product-info .price ins,
.products .product-info .price .amount {
	font-style: normal;
	font-weight: normal;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	font-size: 16px;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	line-height: 25px;
}


.products .product-info .price ins,
.products .product-info .price .amount {
	letter-spacing: 0px;
}

.single-product-content .price {
	font-family: 'Source Sans Pro';
}

.single-product-content .price {
	font-style: normal;
	font-weight: 300;
}

.woocommerce .single-product-content .price {
	font-size: 36px;
}
.woocommerce .single-product-content .price del,
.woocommerce .single-product-content .single_variation .price del {
	font-size: 24px;
}
.woocommerce .single-product-content .single_variation .price {
	font-size: 50px;
}
.woocommerce .single-product-content .single_variation .price del {
	line-height: 24px;
}
.woocommerce .single-product-content .single_variation .price {
	line-height: 50px;
}

.woocommerce .single-product-content .price {
	line-height: 36px;
}
.woocommerce .single-product-content .price del {
	line-height: 24px;
}


.woocommerce .single-product-content .price {
	letter-spacing: 0px;
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	font-family: 'Source Sans Pro';
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	font-style: normal;
	font-weight: 300;
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	font-size: 20px;
}

.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	line-height: 30px;
}


.widget .gem-products-price ins,
.widget .gem-products-price .amount {
	letter-spacing: 0px;
}

.woocommerce .woocommerce-cart-form .shop_table td.product-price,
.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	font-family: 'Source Sans Pro';
}

.woocommerce .woocommerce-cart-form .shop_table td.product-price,
.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	font-style: normal;
	font-weight: 300;
}

.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	font-size: 24px;
}
.woocommerce .woocommerce-cart-form .shop_table td.product-price {
	font-size: 19.9992px;
}

.woocommerce .woocommerce-cart-form .shop_table td.product-price,
.woocommerce .wishlist_table .product-info .product-info-content .product-price {
	line-height: 30px;
}
.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal {
	line-height: 24.999px;
}


.woocommerce .woocommerce-cart-form .shop_table td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price,
.woocommerce .woocommerce-cart-form .shop_table td.product-price {
	letter-spacing: 0px;
}


.combobox-wrapper,
#page .woocommerce .select2-container .select2-selection--single,
.select2-container .select2-dropdown,
.gem-team-style-5 .team-person:hover .team-person-hover,
.gem-table td,
body .gem-table-style-2 thead,
body .gem-table-style-2 tr:nth-child(2n) td,
.gem-table .tabletolist.nrh > li > ul > li,
.resp-tabs-container,
.resp-tab-content,
.resp-tab-active,
.gem-clients-type-carousel-grid .gem-client-item a:hover,
.gem_client-carousel .gem-client-item a:hover,
.products .product-inner,
.gem-table .shop_table tbody tr,
.widget_product_search input.search-field,
.woocommerce-message:before,
.woocommerce-info:before,
.woocommerce table.shop_table.order-details tr.cart_item,
.woocommerce table.shop_table.order-details tr.order_item,
.triangle-with-shadow:after,
.blog-style-default article .post-image .gem-dummy,
.blog-style-default article.sticky .post-image .gem-dummy:after,
.blog-style-timeline.blog-style-styled_list1 .item-post .post-text,
.blog-style-default article.no-image .post-image .gem-dummy,
.wpb_toggle,
.vc_toggle_title,
.page-title-style-2,
.post-tags-list a:after,
.portfolio .portfolio-item .overlay-line,
.portfolio-item .wrap > .caption,
.portfolio.background-style-dark .portfolio-item:hover .wrap > .caption,
.portfolio.background-style-dark .caption-bottom-line .project-button .gem-button,
.quickfinder-item .quickfinder-animation,
.gem-counter .gem-counter-animation,
.mc4wp-form input[type='email'],
.page-title-block.page-title-style-2,
.page-title-block.has-background-image,
body .widget .tagcloud a,
.wpcf7-form.gem-contact-form-white .combobox-wrapper,
.wpcf7-form.gem-contact-form-white  input:not(.gem-button-wpcf-custom),
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-button,
.wpcf7-form.gem-contact-form-white .combobox-wrapper .combobox-text,
.wpcf7-form.gem-contact-form-white .checkbox-sign,
.wpcf7-form.gem-contact-form-white  span.radio-sign,
.wpcf7-form.gem-contact-form-white textarea,
.diagram-wrapper.style-1 .digram-line-box .skill-line,
.blog-style-styled_list1 .post-item,
.blog-style-styled_list2 article,
.blog-style-styled_list2 .post-item,
.blog-style-styled_list2 .sticky,
.blog-style-timeline .post-item,
.blog-style-timeline .post-img,
.blog-style-timeline .post-img a .dummy,
.gem-basic-grid .vc_grid-item-mini,
.gem-basic-grid .vc_gitem-zone-c,
.shop-widget-area .widget .gem-products-content,
.blog-style-compact article,
.gem-search-form.gem-search-form-style-light .search-field,
.page-title-block.custom-page-title,
.custom-footer,
.portfolio.extended-products-grid .portfolio-top-panel .portfolio-top-panel-right .search-filter .form,
.portfolio-filters-list.style-standard:not(.single-filter) .filter-item .list,
.portfolio-sorting-select ul,
.portfolio-filters-list.style-hidden .filters-outer .filters-list,
.portfolio-filters-list.style-standard-mobile .filters-outer .filters-list,
.portfolio-filters-list.style-sidebar-mobile .filters-outer .filters-list,
.thegem-popup-notification .notification-message,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab.vc_active > a {
	background-color: #ffffff;
}
.portfolio.hover-gradient .portfolio-item .image .overlay .links a.icon:before,
.gem-gallery-grid.hover-gradient .gallery-item .overlay a.icon::before,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after {
	background-color: rgba(255, 255, 255, 0.8);
}
.portfolio-item .wrap > .caption .caption-sizable-content:after,
.portfolio.background-style-dark .portfolio-item:hover .wrap > .caption .caption-sizable-content:after {
	box-shadow: 0 0 30px 45px #ffffff;
}
.blog-style-compact article .gem-compact-item-content:after {
	box-shadow: 0 5px 15px 15px #ffffff;
}
.fullwidth-block .marker-direction-inside svg,
.blog-style-styled_list1 .wrap-style,
.blog-style-styled_list2 .wrap-style,
.blog-style-timeline .wrap-style {
	fill: #ffffff;
}
.gem-image a:after,
.gem-textbox-picture a:after,
.blog-style-default article.sticky .sticky-label,
.blog-style-masonry article.sticky .sticky-label,
.blog-style-styled_list1 article.sticky .sticky-label,
.blog-style-styled_list2 article.sticky .sticky-label,
.blog-style-timeline article.sticky .sticky-label,
.justified-style-1 article.sticky .sticky-label,
.justified-style-2 article.sticky .sticky-label,
.blog-style-multi-author article.sticky .sticky-label,
.blog article .post-featured-content > a:after,
.blog .gem-simple-gallery .gem-gallery-item a:after,
.blog-style-styled_list1 .wrap-style,
.blog-style-styled_list2 .wrap-style,
.blog-style-styled_list1 article a.default:after,
.blog-style-styled_list2 article a.default:after,
.blog-style-compact article a.default:after,
.blog-style-timeline .wrap-style,
#primary-navigation #primary-menu > li.menu-item-cart > a .minicart-item-count,
.mobile-cart-position-top #site-header .mobile-cart .minicart-item-count,
.hamburger-minicart > a .minicart-item-count,
.woocommerce ul.woocommerce-error li:before,
.pricing-column-top-choice .pricing-column-top-choice-text,
.widget .onsale:after,
.widget_shopping_cart ul li.sale .minicart-image:before,
#wp-calendar caption,
.widget.widget_calendar a:hover,
.shop-widget-area .onsale,
.socials-sharing li a:hover:after,
.wpb_wrapper .vc_progress_bar .vc_label_units,
.portfolio-item .image .overlay .links a.icon:before,
.portfolio.hover-title .portfolio-item .caption .title,
.portfolio.hover-title.columns-4 .portfolio-item .caption .title,
.portfolio.hover-title .portfolio-item .caption .subtitle,
.portfolio.hover-title .portfolio-item .caption .info,
.portfolio.hover-title .portfolio-item .caption .info a,
.portfolio.hover-default .portfolio-item .image .overlay .links .caption .info a,
.portfolio .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.portfolio.products .portfolio-item .image .overlay .portfolio-icons.product-bottom a.added_to_cart,
.portfolio.products .portfolio-item .image .overlay .portfolio-icons.product-bottom .yith-wcwl-wishlistaddedbrowse a,
.news-grid.portfolio.hover-title .portfolio-item .caption .title > *,
.news-grid.portfolio.hover-default .comments-link a,
.news-grid.portfolio.hover-vertical-sliding .comments-link a,
.news-grid.portfolio.hover-gradient .comments-link a,
.news-grid.portfolio.hover-circular .comments-link a,
.news-grid.portfolio.hover-default .zilla-likes,
.news-grid.portfolio.hover-vertical-sliding .zilla-likes,
.news-grid.portfolio.hover-gradient .zilla-likes,
.news-grid.portfolio.hover-circular .zilla-likes,
.news-grid.hover-default .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-default .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-default .portfolio-item .image .links .caption .description .subtitle,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .description .subtitle,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .description .subtitle,
.news-grid.hover-vertical-sliding .portfolio-item .image .links .caption .post-date,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .post-date,
.news-grid.hover-gradient .portfolio-item .image .links .caption .post-date,
.news-grid.hover-circular .portfolio-item .image .links .caption .post-date,
.news-grid.portfolio.hover-vertical-sliding .portfolio-item .image .overlay .links .caption .info a,
.news-grid.portfolio.hover-vertical-sliding .portfolio-item .image .overlay .links .caption .info span.sep,
.news-grid.hover-gradient .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-gradient .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-circular .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-circular .portfolio-item .image .links .caption .author .author-name a,
.news-grid.hover-gradient.title-on-page.title-style-dark .portfolio-item .image .overlay .links .caption .subtitle,
.news-grid.hover-circular.title-on-page.title-style-dark .portfolio-item .image .overlay .links .caption .subtitle,
.news-grid.hover-circular .portfolio-item .image .links .caption .post-date,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .post-date,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .title > *,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .info a,
.portfolio-filters a:hover,
.portfolio-filters a.active,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .title,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption .title,
.gallery-item .overlay .title,
.gallery-item .overlay .subtitle,
.gallery-item .overlay a.icon:before,
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-prev:after,
.gem-gallery .gem-gallery-preview-carousel-wrap:hover .gem-next:after,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-caption,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.gem-simple-gallery .gem-gallery-item a:after,
.post-tags-list a:hover,
div.blog article a.youtube:after,
div.blog article a.vimeo:after,
div.blog article a.self_video:after,
#colophon  .recentcomments .comment-author-link a:hover,
#colophon .project_info-item-style-1 .project_info-item-title,
#colophon .gem-contacts-item a:hover,
#colophon .widget_calendar td a,
#colophon .widget.widget_search .gem-button:hover,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
#colophon #mc4wp_submit:hover,
body .widget .tagcloud a:hover,
.gem-table-responsive.gem-table-style-3 .tabletolist > li .titles,
.gem-table-style-3 thead tr th,
.gem-table-style-3 thead h6,
.breadcrumbs a,
.bc-devider:before,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item.vc_active > span,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item:hover > span,
.vc_grid-item.gem-media-grid .vc_gitem-post-data-source-post_title a,
.gem-media-grid-2 .midia-grid-item-post-author,
.gem-media-grid-2 .midia-grid-item-post-author span a,
.widget .gem-dummy:after,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info a,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .subtitle,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .title,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.diagram-wrapper.style-3 .digram-line-box  .skill-title,
.gem-blog-slider .post-title a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li .mega-label,
.gem-search-form.gem-search-form-style-dark .search-submit {
	color: #ffffff;
}
.news-grid.portfolio.hover-default .portfolio-item .grid-post-meta .comments-link,
.news-grid.portfolio.hover-vertical-sliding .portfolio-item .grid-post-meta .comments-link,
.news-grid.portfolio.hover-gradient .portfolio-item .grid-post-meta .comments-link,
.news-grid.portfolio.hover-circular .portfolio-item .grid-post-meta .comments-link {
	border-right-color: #ffffff;
}
.portfolio.hover-default .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-vertical-sliding .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info span.sep,
.portfolio.hover-circular .portfolio-item .image .overlay .links .caption .info span.sep,
.news-grid .portfolio-item.double-item-style-alternative .highlight-item-alternate-box .info span.sep,
.news-grid.portfolio.hover-circular .portfolio-item .image .overlay .links .caption .info span.sep {
	border-left-color: #ffffff;
}
@media (min-width: 768px) {
	.blog-style-timeline .item-post .post-text:after {
		border-right: 20px solid #ffffff;
	}
}
@media (max-width: 768px) {
	.blog-style-timeline .item-post .post-text:after {
		border-bottom: 30px solid #ffffff;
	}
}
@media (max-width: 768px) {
	.blog-style-timeline .post-text:after,
	.blog-style-timeline.blog-style-styled_list1 .post-text:after,
	.blog-style-timeline.blog-style-styled_list2 .post-text:after {
		border-bottom: 30px solid #ffffff;
	}
}
.blog-style-styled_list1 .post-img a,
.blog-style-styled_list2 .post-img a,
.blog-style-timeline .post-img a {
	border: 3px solid #ffffff;
}
@media (min-width: 768px) {
	body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels {
		background-color: #ffffff;
	}
}


#colophon .gem-custom-mailchimp input[type="email"] {
	background-color: #9F1B96FF;
}

.gem-dummy,
.default-background,
.sc-wrapbox-style-6 .sc-wrapbox-inner,
.gem-table thead,
.pricing-title-new,
.highlighted .pricing-title,
.gem-clients-type-carousel-grid .gem-client-item a,
.gem_client-carousel .gem-client-item a,
.before-products-list,
.shipping-calculator-form-wrap,
.woocommerce-message,
.woocommerce-info,
ul.woocommerce-error li,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .minicart-bottom,
.hamburger-minicart .widget_shopping_cart_content .minicart-bottom,
.woocommerce .checkout #payment .payment_methods li .payment_box,
.checkout-cart-info > td,
.woocommerce table.shop_table.order-details thead tr th,
.woocommerce #customer_login .col .login-box,
.widget_recently_viewed_products ul.product_list_widget,
.widget .searchform,
.gem-tabs-style-1 .gem_tabs_nav li,
.gem-tour-style-1 .gem_tabs_nav li,
.gem-tabs-style-1 .resp-accordion.resp-tab-active,
.gem-tour-style-1 .resp-accordion.resp-tab-active,
.gem-tabs-style-2 .resp-accordion.resp-tab-active,
.blog-style-timeline.blog-style-styled_list1 .item-post,
.blog-style-default article .post-image .gem-dummy:after,
.blog-style-default article.sticky .post-image .gem-dummy,
.widget_recent_reviews .product_list_widget .wp-post-image,
.price_slider.ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all,
.post-author-block,
.digram-line-box,
#calendar_wrap,
.widget_recent_comments li,
.project_info-item-style-1,
.widget_mc4wp_widget form,
.widget_submenu  ul ul,
.widget_nav_menu  ul ul,
.widget_pages ul ul,
.widget_categories ul ul,
.widget_product_categories ul ul,
.widget_recent_entries ul,
body .testimonials-style-2-text.gem-testimonial-text,
.gem-teams-item-wrap,
.gem-picturebox-text,
body .testimonials-style-1-image,
body .testimonials-style-1-text.gem-testimonial-text,
body .testimonials-style-1-name,
body .testimonials-style-1-post,
body .testimonials-style-1-teg,
body .gem-table-style-3 tr:nth-child(odd) td:nth-child(odd),
.blog-style-masonry  .description,
.style2.gem-testimonials,
.style2.gem-testimonials.fullwidth-block  .container,
.diagram-wrapper.style-3 .digram-line-box .skill-line,
.blog-style-styled_list1 article,
.blog-style-styled_list1 .post-img a .dummy,
.blog-style-styled_list1 .sticky .post-item,
.blog-style-styled_list2 .sticky .post-item,
.blog-style-timeline .sticky .post-item,
.project-info-shortcode-style-default .project-info-shortcode-item,
.project_info-item-style-2 .project_info-item,
.gem-basic-grid-2 .vc_gitem-zone-c .vc_gitem-col,
.quickfinder-style-vertical-1 .quickfinder-item-info,
.quickfinder-style-vertical-2 .quickfinder-item-info,
.justified-style-2 .post-content-wrapper,
.blog-style-multi-author .post-item,
.project-info-shortcode-style-2 .project-info-shortcode-item,
.portfolio-item:hover .wrap > .caption,
.portfolio.background-style-gray .portfolio-item .wrap > .caption,
.products .product-inner:hover,
.portfolio-filters-list .filter-item .list ul li span.count,
.portfolio-filters-list .widget ul li span.count,
.portfolio-selected-filters .filter-item,
.search-filter .form input,
.portfolio-filters-list.style-standard .filter-item .list .price-range-slider .slider-amount,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading:hover,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading:focus,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a:hover,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a:focus {
	background-color: #f4f6f7;
}
.portfolio-item:hover .wrap,
.portfolio-items-masonry .portfolio-item:hover .caption {
	border-bottom-color: #f4f6f7;
}
.testimonials_svg svg,
.blog-style-styled_list1 .sticky .wrap-style,
.blog-style-styled_list2 .sticky .wrap-style,
.blog-style-timeline .sticky .wrap-style,
body .testimonials-style-2-item .wrap-style,
body .testimonials-style-1-item .wrap-style,
.qf-svg-arrow-left,
.qf-svg-arrow-right,
.blog-style-multi-author .wrap-style {
	fill: #f4f6f7;
}
.blog-style-styled_list2 .sticky .wrap-style {
	stroke: #f4f6f7;
}
.gem-team-style-1 .team-person,
.gem-team-style-6 .team-person,
.gem-wrapbox-style-2,
.gem-team-style-5 .team-person:hover .image-hover,
.products .product-inner:hover {
	border-color: #f4f6f7;
}
.portfolio-item:hover .wrap > .caption .caption-sizable-content:after,
.portfolio.background-style-gray .portfolio-item .wrap > .caption .caption-sizable-content:after {
	box-shadow: 0 0 30px 45px #f4f6f7;
}

.gem-styled-color-1,
.gem-date-color,
.gem-table thead th,
.woocommerce table.shop_table.order-details thead tr th,
.gem_accordion_header a,
.gem-testimonial-name,
.post-author-avatar:after,
.comments-link a:hover,
.shipping_calculator .shipping-calculator-button:before,
.widget_calendar caption,
.widget.widget_search .gem-button,
#wp-calendar thead tr th,
.widget.widget_calendar a:hover,
.gem-teams-phone,
.resp-tab-active,
.wpb_toggle,
.vc_toggle_title h4,
.account-tittle,
.blog-style-timeline .post-time span,
.blog.blog-style-masonry article .description .tags a.active,
.widget_tweets .twitter-box .jtwt_tweet:before,
.jtwt_tweet_text.icon-twitter a,
.gem-pp-posts-date,
.gem-latest-news-title span,
.widget.widget_search .gem-button:hover,
.mc-text span,
.blog-style-styled_list1 .post-time,
.blog-style-styled_list2 .post-time,
.blog-style-multi-author .post-date,
.comment-form label em,
.comment-awaiting-moderation,
.woocommerce-before-cart .cart-short-info .items-count,
.shop_table td.product-remove .remove,
.products .product-info .price ins,
.products .product-info .price .amount,
.comment-form-rating .stars a.active,
.comment-form-rating .stars a:hover,
.woocommerce .checkout .woocommerce-billing-collumn .form-row label abbr,
.woocommerce .edit-address-form .woocommerce-billing-collumn .form-row label abbr,
.woocommerce ul.woocommerce-error li,
.woocommerce .checkout-login-box .login .form-row label .required,
.woocommerce .checkout-lost-password-box .form-row label .required,
.woocommerce #customer_login .col .login-box .form-row label .required,
body .mobile-cart .mobile-cart-header .mobile-cart-header-title:before,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total .order-total td .amount,
.read-more-link:before,
.post-related-posts h2:before,
.gem_accordion_header.ui-accordion-header-active .ui-accordion-header-icon:before,
.form-edit-adress abbr,
.post-author-info .categories a,
.post-author-info .categories,
.block-navigation-prev a:before,
.block-navigation-next a:after,
h4.wpb_toggle_title_active:after,
.vc_toggle_active .vc_toggle_title:after,
.resp-accordion.resp-tab-active:after,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .info a,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .info span.sep,
.blog.blog-style-masonry article .description .info .more-link:before,
.portfolio.hover-new-horizontal-sliding .portfolio-item .image .overlay .links .caption .info a,
.portfolio.hover-new-horizontal-sliding .portfolio-item .image .overlay .links .caption .info span.sep,
.version-new.news-grid.hover-new-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .grid-post-meta-comments-likes a,
.portfolio.hover-default-horizontal-sliding .portfolio-item .image .overlay .links .caption .info a,
#colophon .widget_nav_menu ul.menu li.menu-item-current > a,
#colophon .widget_submenu > div > ul li.menu-item-current > a,
#colophon .widget_nav_menu ul.menu li.menu-item-active > a,
#colophon .widget_submenu > div > ul li.menu-item-active > a {
	color: #5E6467;
}

.portfolio.hover-default-horizontal-sliding .portfolio-item .image .overlay .links .caption .info span.sep {
	border-left-color: #5E6467;
}

.portfolio-filters a.active,
.post-tags-list a:hover,
.portfolio .portfolio-item .caption .caption-separator,
.portfolio .portfolio-item .caption .caption-separator-line:after,
.portfolio .portfolio-item .caption .caption-separator-line-hover:after,
body.search .page-title-block,
.widget_pages > ul li.current_page_item  > a:before,
.widget_pages > ul  li li .current_page_item > a:before,
.widget_nav_menu li li .current-menu-item  > a:before,
.widget_submenu li li .menu-item-active  > a:before,
.widget_nav_menu .current-menu-ancestor > a:before,
.widget_submenu .menu-item-active> a:before,
.widget_submenu .current-menu-ancestor > a:before,
.widget_nav_menu li li a:hover:before,
.widget_submenu li li a:hover:before,
.widget_categories li li a:hover:before,
.widget_categories .current-cat a:before,
.widget_categories > ul li.current-cat-parent > a:before,
.widget_product_categories li li a:hover:before,
.widget_product_categories .current-cat a:before,
.widget_product_categories > ul li.current-cat-parent > a:before,
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a:before,
.widget_pages  ul li li a:hover:before,
#colophon .widget_calendar td a,
body .widget .tagcloud a:hover,
.gem-table-responsive.gem-table-style-3 .tabletolist > li:nth-child(odd) .titles,
.gem-table-style-3 thead tr th:nth-child(odd),
.site-footer .widget_text ul li:before,
.page-content.content-none .searchform .searchform-submit .gem-button,
.wpcf7-form .gem-button:not(.gem-button-wpcf-custom),
.wpcf7-form input[type='submit']:not(.gem-button-wpcf-custom),
.wpcf7-form.gem-contact-form-white input[type='submit']:not(.gem-button-wpcf-custom),
.wpcf7-form.gem-contact-form-dark input[type='submit']:not(.gem-button-wpcf-custom),
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item.vc_active,
.gem-media-grid-2 .vc_gitem-animated-block .vc_gitem-zone-b,
.ui-slider-handle.ui-state-default.ui-corner-all,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li.sale .minicart-image:before,
body .hamburger-minicart > .minicart ul li.sale .minicart-image:before,
body.mobile-cart-position-top #site-header .mobile-cart ul li.sale .minicart-image:before,
.products .product-info .rating-empty,
.woocommerce-cart-form .actions .checkout-button,
.wc-proceed-to-checkout .checkout-button,
.widget_shopping_cart_content .buttons a.checkout,
body .products .category-overlay-separator,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .buttons a.checkout,
.hamburger-minicart .widget_shopping_cart_content .buttons a.checkout,
.woocommerce ul.woocommerce-error li:before,
.widget .product_list_widget .onsale,
.shop-widget-area .onsale,
.widget_shopping_cart ul li.sale .minicart-image:before,
.gem-team-style-5 .team-person:hover .image-hover,
.blog-style-default article.sticky .sticky-label,
.blog-style-default article.sticky .sticky-label:after,
.blog-style-masonry article.sticky .sticky-label,
.blog-style-masonry article.sticky .sticky-label:before,
.blog-style-masonry article.sticky .sticky-label:after,
.blog-style-styled_list1 article.sticky .sticky-label,
.blog-style-styled_list1 article.sticky .sticky-label:before,
.blog-style-styled_list1 article.sticky .sticky-label:after,
.blog-style-styled_list2 article.sticky .sticky-label,
.blog-style-styled_list2 article.sticky .sticky-label:before,
.blog-style-styled_list2 article.sticky .sticky-label:after,
.blog-style-timeline article.sticky .sticky-label,
.blog-style-timeline article.sticky .sticky-label:before,
.blog-style-timeline article.sticky .sticky-label:after,
.justified-style-1 article.sticky .sticky-label,
.justified-style-2 article.sticky .sticky-label,
.justified-style-1 article.sticky .sticky-label:before,
.justified-style-2 article.sticky .sticky-label:before,
.justified-style-1 article.sticky .sticky-label:after,
.justified-style-2 article.sticky .sticky-label:after,
.blog-style-multi-author article.sticky .sticky-label,
.blog-style-multi-author article.sticky .sticky-label:before,
.blog-style-multi-author article.sticky .sticky-label:after,
.mc-form #mc4wp_submit:hover,
#primary-navigation #primary-menu > li.menu-item-cart > a .minicart-item-count,
#primary-navigation #primary-menu > li.menu-item-cart > a .minicart-item-count:after,
body.mobile-cart-position-top #site-header .mobile-cart .minicart-item-count,
.hamburger-minicart > a .minicart-item-count,
body .woocommerce .checkout-signin .checkout-create-account button,
.gem-featured-posts-slider-dots a.selected > span {
	background-color: #5E6467;
}
.header-style-2 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-2 #primary-menu.no-responsive li li:hover > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive li li:hover > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-right #primary-menu.no-responsive li li:hover > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li.menu-item-current > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li.menu-item-active > a,
.header-layout-fullwidth_hamburger.logo-position-left #primary-menu.no-responsive li li:hover > a,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li li.menu-item-current > a,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li li.menu-item-active > a,
#thegem-perspective .perspective-navigation #primary-menu.no-responsive li li:hover > a,
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item-current > a,
.mobile-menu-layout-default .primary-navigation.responsive ul > li.menu-item.menu-item-active a,
body .widget .tagcloud a:hover,
.gem-media-grid .vc_gitem-animated-block .vc_gitem-zone-b .vc_separator .vc_sep_holder .vc_sep_line,
.products .product .product-info .product-rating .empty-rating:before {
	border-color: #5E6467;
}
.header-style-2 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-2 #primary-menu.no-responsive li li:hover > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a,
.header-style-2 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive li li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive li li:hover > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-current > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li.menu-item-active > a,
.header-style-3 #primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li > ul li:hover > a {
	border-color: none;
}
body .woocommerce-checkout-one-page #customer_details > div:after {
	border-bottom-color: #5E6467;
}

.gallery-item .overlay .subtitle a,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .zilla-likes:hover,
.single-product-content .price del,
.shop_table td.product-subtotal,
.page-content.content-none .searchform input,
.woocommerce table.shop_table.order-details tr.cart_item td.product-name .product-info .styled-subtitle,
.woocommerce table.shop_table.order-details tr.order_item td.product-name .product-info .styled-subtitle,
.woocommerce table.shop_table.order-details tr.cart_item td.product-name .product-info h4 a,
.woocommerce table.shop_table.order-details tr.order_item td.product-name .product-info h4 a,
.widget_shopping_cart_content .quantity,
.woocommerce .checkout_coupon .styled-subtitle,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount,
.woocommerce .gem-products-price ins,
.gem-products-price > span,
.widget-area .reviewer,
.widget_shopping_cart_content .quantity,
.widget_shopping_cart_content .total .amount,
.zilla-likes.active,
.zilla-likes.active:hover,
body .pricing-table-style-1 .pricing-price-subtitle,
.post-tags-list a,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta a.icon.share:before,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .comments-link a,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .portfolio-sharing-pane .socials-item,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .grid-post-meta a.icon.share:before,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .links .caption .grid-post-meta .comments-link a {
	color: #000000;
}
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .comments-link,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .post-meta-likes,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .comments-link,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .grid-post-share + .grid-post-meta-comments-likes .post-meta-likes {
	border-left-color: #000000;
}
.widget.widget_search .gem-button,
.widget_nav_menu li li a:before,
.widget_submenu li li a:before,
.widget_pages li li a:before,
.widget_categories li li a:before,
.widget_product_categories li li a:before,
#wp-calendar caption,
body .vc_grid-container ul.vc_grid-filter li.vc_grid-filter-item:hover {
	background-color: #000000;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	border: 2px solid #000000;
}
body .widget .tagcloud a{
	border: 1px solid #000000;
}

.zilla-likes,
span.req,
#wp-calendar thead tr th:last-child,
.widget_pages > ul  li li li.current_page_item > a,
.widget_nav_menu li li li a:hover,
.widget_nav_menu li li li.current-menu-item > a,
.widget_submenu li li li.menu-item-active > a,
.widget_submenu li li li a:hover,
.widget_pages ul li li li a:hover,
.widget_categories li li li a:hover,
.widget.widget_categories > ul li li.current-cat > a,
.widget_product_categories li li li a:hover,
.widget.widget_product_categories > ul li li.current-cat > a,
.products .product-bottom .yith-wcwl-wishlistexistsbrowse a:before,
.portfolio.products .portfolio-item .image .overlay .portfolio-icons.product-bottom .yith-wcwl-wishlistexistsbrowse a:before,
.news-grid.portfolio.hover-zooming-blur .zilla-likes.active,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .links .caption .grid-post-meta .post-meta-likes a,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .links .caption .grid-post-meta .post-meta-likes a,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .zilla-likes:before,
.version-default.news-grid.hover-default-horizontal-sliding .portfolio-item .links .caption .grid-post-meta .grid-post-meta-comments-likes .zilla-likes {
	color: #5E6467;
}
.widget_pages > ul  li li li.current_page_item > a:before,
.widget_nav_menu li li li.current-menu-item > a:before,
.widget_submenu li li li.menu-item-active > a:before,
.widget_nav_menu li li li a:hover:before,
.widget_submenu li li li a:hover:before,
.widget_pages li li li a:hover:before,
.widget_categories li li li a:hover:before,
.widget_product_categories li li li a:hover:before,
.widget_nav_menu div ul li li li a:hover:before,
.widget_submenu div ul li li li a:hover:before,
.widget_pages  ul li li li a:hover:before,
.widget_categories ul li li li a:hover:before,
.widget.widget_categories > ul li.current-cat > a:before,
.widget_product_categories ul li li li a:hover:before,
.widget.widget_product_categories > ul li.current-cat > a:before,
#primary-menu.no-responsive > li.megamenu-enable > ul > li .mega-label {
	background-color: #5E6467;
}

#primary-menu.no-responsive .vertical-minisearch .sf-submit-icon:after,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .checkout-button-button .checkout-button,
body .woocommerce .wc-proceed-to-checkout .checkout-button-button .checkout-button,
body .woocommerce .payment_methods .radio-sign:before,
body .products .product-category a:hover .category-overlay,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-prev:hover,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-next:hover,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption {
	background-color: #BDBDBD;
}
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .checkout-button-button .checkout-button:hover,
body .woocommerce .wc-proceed-to-checkout .checkout-button-button .checkout-button:hover,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-prev:hover,
.related-posts-carousel .related-posts-navigation .gem-button.related-posts-next:hover {
	border-color: #BDBDBD;
}
.zilla-likes:hover,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .checkout-button-button .checkout-button:hover,
body .woocommerce .wc-proceed-to-checkout .checkout-button-button .checkout-button:hover,
.news-grid.portfolio.hover-default .zilla-likes:hover,
.news-grid.portfolio.hover-vertical-sliding .zilla-likes:hover,
.news-grid.portfolio.hover-gradient .zilla-likes:hover,
.news-grid.portfolio.hover-circular .zilla-likes:hover,
.news-grid.portfolio.hover-default .comments-link a:hover,
.news-grid.portfolio.hover-vertical-sliding .comments-link a:hover,
.news-grid.portfolio.hover-gradient .comments-link a:hover,
.news-grid.portfolio.hover-circular .comments-link a:hover,
.version-new.news-grid .portfolio-item .wrap > .caption .author,
.gem-featured-posts-slider-nav.style-icon-1 a:hover,
.gem-featured-posts-slider-nav.style-icon-2 a:hover,
.gem-featured-posts-slider-nav.style-icon-3 a:hover {
	color: #BDBDBD;
}
.version-new.news-grid.portfolio.hover-new-default .portfolio-item .image .links .caption .info,
.version-new.news-grid.portfolio.hover-new-zooming-blur .portfolio-item .image .links .caption .info,
.version-new.news-grid.portfolio.hover-new-vertical-sliding .portfolio-item .image .links .caption .info {
	background-color: rgba(189, 189, 189, 0.7);
}

.gem-default-divider,
.portfolio-filters-list.style-hidden .list-scrollable > .filter-item,
.portfolio-filters-list.style-standard-mobile .list-scrollable > .filter-item,
.portfolio-filters-list.style-sidebar-mobile .list-scrollable > .filter-item,
.portfolio-filters-list.style-sidebar .list-scrollable > .filter-item,
.portfolio-filters-list.style-hidden .list-scrollable .widget,
.portfolio-filters-list.style-sidebar-mobile .list-scrollable .widget,
.portfolio-filters-list.style-sidebar .list-scrollable .widget {
	border-top: 1px solid #BDBDBD;
}
.blog-style-default article:not(.sticky) {
	border-bottom: 1px solid #BDBDBD;
}
.skill-line,
.portfolio-filters-list .filter-item .list .price-range-slider .slider-range {
	background-color: #BDBDBD;
}

.bordered-box,
.portfolio.columns-1.background-style-white .portfolio-item .wrap > .caption,
.gem-table-responsive .tabletolist.nrh > li > ul,
*:not(.gem-table):not(.cart_totals) > table:not(.shop_table):not(.group_table):not(.variations) td,
*:not(.gem-table):not(.cart_totals) > table:not(.shop_table):not(.group_table):not(.variations) th,
.products .product-inner,
.widget_recent_reviews ul.product_list_widget li,
.widget_shopping_cart_content ul.product_list_widget li,
form.cart .quantity .minus,
form.cart .quantity .plus,
.shop_table .quantity .minus,
.shop_table .quantity .plus,
.woocommerce-cart-form.responsive .cart-item,
.form-edit-adress-fields,
.woocommerce .checkout_coupon,
.woocommerce .checkout-login-box .login-message-box,
.triangle-with-shadow:after,
.gem-tabs-style-1 .resp-tabs-container,
.gem-tabs-style-1 .gem_tabs_nav li,
.gem-tour-style-1 > .gem_tour_tabs_wrapper,
.gem-tour-style-1 .gem_tabs_nav li,
.gem-tabs-style-2 .resp-accordion,
.blog-style-timeline .post-text,
.widget_layered_nav ul,
.gem-table,
.gem-table-style-1 td,
.blog-style-styled_list1 .post-item,
.blog-style-styled_list2 .post-item,
.blog-style-timeline .post-item,
.gem-basic-grid .vc_grid-item-mini,
.gem-media-grid-2 .vc_grid-item-mini,
.justified-style-1 .post-content-wrapper,
.widget-area .widget_recently_viewed_products .gem-products-image,
form.cart .quantity .qty,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-heading,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-heading,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body::before,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-body::after,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-controls-icon::before,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-controls-icon::after,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::before,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_active .vc_tta-panel-heading .vc_tta-controls-icon::after,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab.vc_active > a {
	border: 1px solid #BDBDBD;
}
.blog-style-styled_list1 .wrap-style,
.blog-style-styled_list1 .sticky .wrap-style,
.blog-style-styled_list2 .wrap-style,
.blog-style-timeline .wrap-style {
	stroke: #BDBDBD;
}
.gem-wrapbox-style-1,
.gem-wrapbox-style-4,
.gem-wrapbox-style-5,
.gem-wrapbox-style-6,
.gem-wrapbox-style-7,
.gem-wrapbox-style-8,
.gem-wrapbox-style-11,
.gem-wrapbox-style-10,
.gem-wrapbox-style-3,
body .woocommerce .shop_table tbody tr td,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total th,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total td,
body .woocommerce .woocommerce-checkout .shop_table tfoot > tr > td,
body .woocommerce .cart_totals table th,
body .woocommerce .cart_totals table td,
body .woocommerce .woocommerce-checkout #order_review .woocommerce-checkout-payment .payment_methods li,
body .woocommerce-checkout-one-page #order_review table tbody th,
body .woocommerce-checkout-one-page #order_review table tbody td,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart,
body .hamburger-minicart > .minicart,
.portfolio.extended-products-grid .portfolio-item.product .image,
.portfolio.extended-products-grid .portfolio-item.product .caption {
	border-color: #BDBDBD;
}
.gem-table-style-2 td,
.gem-table-style-2 thead {
	border: 2px solid #BDBDBD;
}
.shop_table .quantity .qty,
.woocommerce table.shop_table.order-details,
.woocommerce table.shop_table.order-details tr.cart_item,
.woocommerce table.shop_table.order-details tr.order_item,
.widget .product-dummy-wrapper,
.post-related-posts,
.sidebar .widget .gem-contacts-item.gem-contacts-address,
.gem-tabs-style-2 .resp-tabs-container,
.gem-tabs-style-1 .resp-accordion.resp-tab-active,
.gem-tour-style-1 .resp-accordion.resp-tab-active,
.widget_submenu > div > ul,
.widget_nav_menu > div > ul,
.widget.widget_pages > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget_nav_menu ul.menu,
.portfolio-item .wrap,
.portfolio.news-grid.background-style-transparent .portfolio-item .wrap,
.Custom_Recent_Posts ul li,
.Custom_Popular_Posts ul li,
.widget_recent_entries li,
.widget_meta li,
.gem-latest-news,
.gem-latest-news ul,
.widget .gem-products-content,
.widget-area .gem-products-image,
.widget.widget_shopping_cart ul li .minicart-image,
.project_info-item-style-1  .project_info-item.iconed,
.widget_tweets .twitter-box .jtwt_tweet,
.blog-style-default article:not(.sticky),
.project-info-shortcode-style-2 .project-info-shortcode-item,
.single-product-content .rating-divider {
	border-bottom: 1px solid #BDBDBD;
}

.gem_accordion_section + .gem_accordion_section,
.shop_table .quantity .qty,
.gem-table .shop_table tr + tr td,
.cart_totals table tr + tr td,
.cart_totals table tr + tr th,
.order-totals table tr + tr td,
.order-totals table tr + tr th,
.woocommerce table.checkout-cart-info-table tr,
.mobile-cart-position-top #site-header .mobile-cart .widget_shopping_cart_content ul.cart_list > li,
.gem-tabs-style-2 .resp-tabs-container,
.gem-tour-style-2 .gem_tabs_nav li + li,
.gem-tour-style-1 .resp-accordion,
.gem-tour-style-2 .resp-accordion,
.gem-tabs-style-1 .resp-accordion,
.wpb_toggle_content + .wpb_toggle,
.vc_toggle + .vc_toggle .vc_toggle_title,
.widget .product-dummy-wrapper,
.widget_layered_nav ul li,
.widget_submenu li,
.widget_nav_menu li,
.widget.widget_pages li,
.widget_categories li,
.widget_product_categories li,
.widget_recently_viewed_products ul.product_list_widget li + li,
.widget .gem-products-content,
.widget-area .gem-products-image,
.widget.widget_shopping_cart ul li .minicart-image,
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_submenu > div > ul > li > ul,
.single-product-content .rating-divider {
	border-top: 1px solid #BDBDBD;
}
.widget_submenu > div > ul,
.widget_nav_menu > div > ul,
.widget.widget_pages > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.gem-tour-style-2 .resp-tabs-container,
.gem-tabs-style-2 .gem_tabs_nav li + li,
.widget-area .gem-products-image,
.widget.widget_shopping_cart ul li .minicart-image,
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_submenu > div > ul > li > ul {
	border-left: 1px solid #BDBDBD;
}
.widget_submenu > div > ul,
.widget_nav_menu > div > ul,
.widget.widget_pages > ul,
.widget_categories > ul,
.widget_product_categories > ul,
.widget .gem-products-content,
.gem-table-style-2 .tabletolist .row_headers,
#colophon .widget_nav_menu ul.menu > li > ul,
#colophon .widget_submenu > div > ul > li > ul {
	border-right: 1px solid #BDBDBD;
}
.portfolio-filters a,
body .testimonials-style-2-item,
.widget_calendar td a,
.widget-area .widget.widget_search form,
form.cart .quantity .minus,
form.cart .quantity .plus,
.shop_table .quantity .minus,
.shop_table .quantity .plus,
.blog-style-timeline article:after,
#colophon .widget_calendar td a:hover,
.diagram-wrapper.style-2 .digram-line-box .skill-line,
.blog-style-styled_list1 .sticky,
.blog-style-styled_list1 .sticky .post-img a .dummy,
.widget .gem-dummy,
.quickfinder-style-vertical .quickfinder-item:first-child .quickfinder-item-connector.connector-bot,
body .quickfinder-item .quickfinder-item-connector,
.sharing-popup,
.widget-gem-portfolio-item.gem-portfolio-dummy,
.blog-style-timeline article:before,
.post-tags-list a {
	background-color: #BDBDBD;
}
.sharing-popup .sharing-styled-arrow {
	fill: #BDBDBD;
}
@media (min-width: 768px) {
	.blog-style-timeline .item-post .post-text:before {
		border-right: 20px solid #BDBDBD;
	}
}
.blog-style-styled_list1 .post-img,
.blog-style-styled_list2 .post-img,
.blog-style-timeline .post-img {
	border: 3px solid #BDBDBD;
}
@media (max-width: 768px) {
	.gem-table-responsive .tabletolist > li span {
		border-top: 1px solid #BDBDBD;
	}
	.gem-table-responsive .tabletolist > li {
		border: 1px solid #BDBDBD;
	}
	.gem-table-responsive .tabletolist .row_headers {
		border-right: 1px solid #BDBDBD;
	}
	.gem-table-responsive.gem-table-style-2 .tabletolist > li {
		border: 2px solid #BDBDBD;
	}
	.gem-table-responsive.gem-table-style-2 .tabletolist > li span {
		border-top: 2px solid #BDBDBD;
	}
	.gem-table-responsive.gem-table-style-2 .tabletolist .row_headers,
	.gem-table-responsive.gem-table-style-2 .tabletolist .row_headers {
		border-right: 2px solid #BDBDBD;
	}
	.blog-style-timeline .item-post .post-text:before,
	.blog-style-timeline .post-text:before,
	.blog-style-timeline.blog-style-styled_list1 .post-text:before,
	.blog-style-timeline.blog-style-styled_list2 .post-text:before {
		border-bottom: 30px solid #BDBDBD;
	}
}
.comments-title:before,
.comment-reply-title:before,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-controls-icon::before,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-controls-icon::after {
	color: #BDBDBD;
}
@media (min-width: 768px) {
	body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels,
	body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels::before,
	body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic.vc_tta-tabs .vc_tta-panels::after {
		border-color: #BDBDBD;
	}
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	font-family: 'Raleway';
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	font-style: normal;
	font-weight: normal;
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	font-size: 16px;
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	line-height: 20px;
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	text-transform: none;
}

#primary-navigation.responsive #primary-menu li > a,
#primary-navigation.responsive #primary-menu li > span.megamenu-column-header > a {
	letter-spacing: 0px;
}


#primary-navigation.responsive #primary-menu > li > a,
#primary-navigation.responsive #primary-menu > li > .menu-item-parent-toggle,
#primary-navigation.responsive ul.dl-submenu.level1 > li > a,
.primary-navigation.responsive .minisearch .sf-input,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"],
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch .sf-submit-icon:before,
.mobile-cart-position-top .menu-overlay.mobile.active + #site-header .mobile-cart > a:before {
	color: #5E6467;
}
.primary-navigation.responsive .minisearch .sf-input::placeholder,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"]::placeholder {
	color: #5E6467;
}
.primary-navigation.responsive .minisearch .sf-input::-webkit-input-placeholder,
.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"]::-webkit-input-placeholder{
	color: #5E6467;
}

#page #site-header.mobile-menu-layout-overlay .header-main #primary-navigation.overlay-active:not(.close) .menu-toggle .menu-line-1,
#page #site-header.mobile-menu-layout-overlay .header-main #primary-navigation.overlay-active:not(.close) .menu-toggle .menu-line-2,
#page #site-header.mobile-menu-layout-overlay .header-main #primary-navigation.overlay-active:not(.close) .menu-toggle .menu-line-3,
#page #site-header.mobile-menu-layout-overlay .header-main #perspective-menu-buttons.overlay-active:not(.close) .menu-toggle .menu-line-1,
#page #site-header.mobile-menu-layout-overlay .header-main #perspective-menu-buttons.overlay-active:not(.close) .menu-toggle .menu-line-2,
#page #site-header.mobile-menu-layout-overlay .header-main #perspective-menu-buttons.overlay-active:not(.close) .menu-toggle .menu-line-3 {
	background-color: #5E6467;
}

.mobile-menu-layout-overlay #primary-navigation.responsive #primary-menu > li.menu-item-search > .minisearch input[type="text"] {
	border-color: #5E6467;
}

.mobile-menu-layout-slide-horizontal #primary-navigation.responsive .mobile-menu-slide-wrapper,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive .mobile-menu-slide-wrapper,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu,
#primary-navigation.responsive #primary-menu > li > a,
#primary-navigation.responsive ul.dl-submenu.level1 > li > a,
#primary-navigation.responsive #primary-menu li.menu-item-search,
#primary-navigation.responsive #primary-menu li.menu-item-search .minisearch {
	background-color: #f4f6f7;
}

#primary-navigation.responsive #primary-menu > li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li.opened > a,
#primary-navigation.responsive #primary-menu > li.menu-item-current > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li.menu-item-active > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li.opened > .menu-item-parent-toggle,
#primary-navigation.responsive ul.dl-submenu.level1 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level1 > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li.menu-overlay-item-open > a,
#primary-navigation.responsive #primary-menu > li.menu-overlay-item-open > .menu-item-parent-toggle {
	color: #5E6467;
}

#primary-navigation.responsive #primary-menu > li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li.opened > a,
#primary-navigation.responsive ul.dl-submenu.level1 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level1 > li.menu-item-active > a {
	background-color: #ffffff;
}

#primary-navigation.responsive #primary-menu > li > ul > li > a,
#primary-navigation.responsive #primary-menu > li > ul > li > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li > span.megamenu-column-header > a,
.mobile-menu-layout-default .primary-navigation.responsive li.dl-back > a,
.mobile-menu-layout-default .primary-navigation.responsive li.dl-back:after{
	color: #5E6467;
}

#primary-navigation.responsive #primary-menu > li > ul > li > a,
#primary-navigation.responsive #primary-menu > li > ul > li > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li > span.megamenu-column-header > a,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"],
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"] {
	background-color: #f4f6f7;
}

#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-active > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-overlay-item-open > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-overlay-item-open > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-overlay-item-open > .menu-item-parent-toggle {
	color: #5E6467;
}

#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li.opened > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-active > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level2 > li.menu-item-active > span.megamenu-column-header > a {
	background-color: #ffffff;
}

#primary-navigation.responsive #primary-menu > li > ul > li > ul li > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li > a {
	color: #5E6467;
}

#primary-navigation.responsive #primary-menu > li > ul > li > ul li > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li > a {
	background-color: #f4f6f7;
}

#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > .menu-item-parent-toggle,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-overlay-item-open > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-overlay-item-open > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-overlay-item-open > .menu-item-parent-toggle {
	color: #5E6467;
}

#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-current > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.menu-item-active > span.megamenu-column-header > a,
#primary-navigation.responsive #primary-menu > li > ul > li > ul li.opened > span.megamenu-column-header > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li.menu-item-current > a,
#primary-navigation.responsive ul.dl-submenu.level3 > li.menu-item-active > a {
	background-color: #ffffff;
}

.mobile-menu-layout-default #primary-navigation.responsive ul li.menu-item,
.mobile-menu-layout-default #primary-navigation.responsive ul li.dl-back,
.mobile-menu-layout-default #primary-navigation.responsive ul,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li,
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li,
.mobile-menu-layout-slide-horizontal #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"],
.mobile-menu-layout-slide-vertical #primary-navigation.responsive #primary-menu li.menu-item-search .minisearch input[type="text"] {
	border-color: #dfe5e8;
}


#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:before,
#primary-navigation.responsive .mobile-menu-slide-wrapper .mobile-menu-slide-close:after {
	background-color: #626262FF;
}

body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li a.remove,
body .hamburger-minicart > .minicart ul li a.remove,
body.mobile-cart-position-top #site-header .mobile-cart ul li a.remove {
    color: #626262FF;
}

#primary-menu.no-responsive > li > a {
	color: #222222FF;
}


#primary-menu.no-responsive > li:hover > a {
	color: #E14227FF;
}
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li > a:hover,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-active > a {
	background-color: #E14227FF;
}


#primary-menu.no-responsive > li.menu-item-current > a,
#primary-menu.no-responsive > li.menu-item-active > a {
	color: #9F1B96FF;
}


#primary-menu.no-responsive > li > ul > li > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li > a {
	color: #626262FF;
}

#primary-menu.no-responsive > li > ul > li > a,
#primary-menu.no-responsive > li.megamenu-enable > ul,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li > span.megamenu-column-header > a,
.header-style-2 .minisearch .sf-input,
.header-style-3 .minisearch .sf-input,
.header-style-1 .minisearch,
.header-style-4 .minisearch {
	background-color: #f4f6f7;
}

#primary-menu.no-responsive > li > ul > li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li:hover > a {
	color: #E14227FF;
}

#primary-menu.no-responsive > li > ul > li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li:hover > a {
	background-color: #ffffff;
}

#primary-menu.no-responsive > li > ul > li.menu-item-current > a,
#primary-menu.no-responsive > li > ul > li.menu-item-active > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.minisearch .sf-submit-icon:before,
.minisearch .sf-input {
	color: #E14227FF;
}

#primary-menu.no-responsive > li > ul > li.menu-item-current > a,
#primary-menu.no-responsive > li > ul > li.menu-item-active > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul li.menu-item-active > a,
.header-style-2 .minisearch,
.header-style-3 .minisearch,
.header-style-1 .minisearch .sf-input,
.header-style-4 .minisearch .sf-input {
	background-color: #ffffff;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a.mega-no-link {
	color: #E14227FF;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a:hover {
	color: #626262FF;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li.menu-item-active > span.megamenu-column-header a {
	color: #9F1B96FF;
}

#primary-menu.no-responsive > li li li > a {
	color: #626262FF;
}

#primary-menu.no-responsive > li li li > a {
	background-color: #ffffff;
}

#primary-menu.no-responsive > li li li:hover > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-current > a,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-default > ul li.menu-item-active > a {
	color: #E14227FF;
}


#primary-menu.no-responsive > li li li.menu-item-current > a,
#primary-menu.no-responsive > li li li.menu-item-active > a {
	color: #E14227FF;
}

#primary-menu.no-responsive > li li li.menu-item-current > a,
#primary-menu.no-responsive > li li li.menu-item-active > a {
	background-color: #ffffff;
}

#primary-menu.no-responsive ul > li,
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header,
.minisearch,
.minisearch .sf-input {
	border-top: 1px solid #dfe5e8;
}
#primary-menu.no-responsive ul,
#primary-menu.no-responsive > li.megamenu-enable.megamenu-style-grid > ul > li,
.minisearch,
.minisearch .sf-input {
	border-bottom: 1px solid #dfe5e8;
	border-left: 1px solid #dfe5e8;
	border-right: 1px solid #dfe5e8;
}
#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header {
	border-bottom: 1px solid #dfe5e8;
}
#primary-menu.no-responsive > li.megamenu-enable > ul {
	border-top: 1px solid #dfe5e8;
}
#primary-menu.no-responsive ul ul {
	margin-top: -1px;
}

#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive > li > a {
	color: #222222FF;
}

#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger) #primary-menu.no-responsive > li:hover > a {
	color: #E14227FF;
}

#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .header-colors-light:not(.header-layout-fullwidth_hamburger):not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-active > a {
	color: #9F1B96FF;
}
#site-header:not(.shrink) .header-colors-light.header-style-3:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .header-colors-light.header-style-3:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-active > a,
#site-header:not(.shrink) .header-colors-light.header-style-4:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-current > a,
#site-header:not(.shrink) .header-colors-light.header-style-4:not(.header-layout-overlay) #primary-menu.no-responsive > li.menu-item-active > a {
	border: 2px solid #9F1B96FF;
}

#primary-menu.no-responsive > li.megamenu-enable > ul > li span.megamenu-column-header a.megamenu-has-icon:before,
#primary-menu.no-responsive > li.megamenu-enable > ul > li > ul > li > a:before {
	color: #E14227FF;
}

	body .menu-overlay {
		background-color: #ffffff;
	}




.hamburger-minicart > a,
.hamburger-minicart:hover > a {
	color: #626262FF;
}
#primary-navigation .hamburger-toggle > span,
#primary-navigation .overlay-toggle > span,
#primary-navigation.overlay-active.close .overlay-toggle > span,
#perspective-menu-buttons.primary-navigation .perspective-toggle span,
#page.vertical-header .vertical-toggle > span,
#thegem-perspective #primary-navigation .perspective-menu-close:before,
#thegem-perspective #primary-navigation .perspective-menu-close:after {
	background-color: #626262FF;
}

#site-header:not(.shrink) .header-colors-light .hamburger-minicart > a,
#site-header:not(.shrink) .header-colors-light .hamburger-minicart:hover > a {
	color: #ffffff;
}
#site-header:not(.shrink) .header-colors-light #primary-navigation .hamburger-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation:not(.overlay-active) .overlay-toggle > span,
#site-header:not(.shrink) .header-colors-light #primary-navigation.overlay-active.close .overlay-toggle > span,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .perspective-toggle span {
	background-color: #ffffff;
}


#primary-navigation .menu-toggle .menu-line-1,
#primary-navigation .menu-toggle .menu-line-2,
#primary-navigation .menu-toggle .menu-line-3,
#perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-1,
#perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-2,
#perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-3 {
	background-color: #626262FF;
}
body.mobile-cart-position-top #site-header .mobile-cart > a:before {
	background-color: #626262FF;
}

#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle .menu-line-1,
#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle .menu-line-2,
#site-header:not(.shrink) .header-colors-light #primary-navigation .menu-toggle .menu-line-3,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-1,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-2,
#site-header:not(.shrink) .header-colors-light #perspective-menu-buttons.primary-navigation .menu-toggle .menu-line-3 {
	background-color: #626262FF;
}
body.mobile-cart-position-top #site-header:not(.shrink) .header-colors-light .mobile-cart > a:before {
	background-color: #626262FF;
}



body,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .info,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption .info,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item .gem-gallery-caption,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay .title,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay .subtitle,
.widget.widget_contacts a:hover,
.widget_recent_comments a,
.digram-line-box  .skill-title,
.shipping_calculator .shipping-calculator-button,
.widget_shopping_cart_content ul.cart_list li a,
.shop-widget-area .gem-products-title a,
.woocommerce-page ul.product_list_widget li a,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .subtitle,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .info,
.portfolio.hover-horizontal-sliding .overlay .links .caption .info span.sep,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .caption .subtitle,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .caption .post-date,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .caption .grid-post-meta a.icon.share:before,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .links .caption .grid-post-meta .comments-link a,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .subtitle,
.version-default.news-grid.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .post-date,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .comments-link a,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .zilla-likes,
.version-new.news-grid .portfolio-item .wrap > .caption .grid-post-meta .grid-post-share .icon,
#colophon .jtwt_tweet:before,
#colophon .project_info-item-style-1 .project_info-item .icon,
#colophon .widget_recent_comments li:before,
#colophon .widget_recent_entries ul li:before,
#colophon .portfolio-filters a .icon,
#colophon .comment-form label:before,
#colophon .socials-sharing a:after,
#colophon .widget_archive li:before,
#colophon .widget_nav_menu ul.menu li > a:before,
#colophon .widget_submenu > div > ul li > a:before,
#colophon .widget_categories > ul li > a:before,
#colophon .widget_product_categories > ul li > a:before,
#colophon .widget_product_categories > ul li:before,
#colophon .widget_pages > ul li > a:before,
#colophon .widget_meta li:before,
#colophon .widget_rss li:before,
#colophon .project_info-item-style-2 .icon,
#colophon .gem_accordion_header,
#colophon .widget_layered_nav ul li a:before,
#colophon .widget .gem-contacts-address:before,
#colophon .widget .gem-contacts-phone:before,
#colophon .widget .gem-contacts-fax:before,
#colophon .widget .gem-contacts-email:before,
#colophon .widget .gem-contacts-website:before,
#colophon .widget_meta li:before,
#colophon .widget .mc-text.small-body,
#colophon .widget.widget_search input, #colophon #mc4wp_email,
blockquote,
.widget_price_filter .price_label span,
.gem-quote.gem-quote-style-default blockquote,
.gem-quote.gem-quote-style-1,
.wpcf7-form.gem-contact-form-white span,
.wpcf7-form.gem-contact-form-white .wpcf7-list-item-label,
.wpcf7-form.gem-contact-form-dark span,
.wpcf7-form.gem-contact-form-dark .wpcf7-list-item-label,
.blog-style-styled_list1 .post-content,
.blog-style-styled_list2 .post-content,
.blog-style-timeline .post-content,
body .thegem_testimonial a .gem-testimonial-text,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-title > a,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-panel.vc_active .vc_tta-panel-title > a,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab > a,
body .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab.vc_active > a,
body .widget .vc_tta.vc_tta-color-thegem.vc_tta-style-classic .vc_tta-tab.vc_active > a {
	color: #5f727f;
}
#colophon .widget.widget_search .gem-button:hover,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
.wpcf7-form.gem-contact-form-dark input[type='submit']:hover {
	background-color: #5f727f;
}
.wpcf7-form.gem-contact-form-dark input[type='submit']:hover {
	border-color: #5f727f;
}

h1,
.title-h1,
.gem-counter-number,
body .gem-testimonial-item:after,
blockquote p:after,
body .testimonials-style-1-teg,
body .testimonials-style-2-teg,
.pricing-title {
	color: #626262;
}

h2,
.title-h2,
.blog-style-multi-author .post-title a,
.single-product-content .price {
	color: #626262;
}
.skill-line > div {
	background-color: #626262;
}

h3,
.title-h3,
.blog-style-styled_list1 .post-title a,
.blog-style-styled_list2 .post-title a,
.blog-style-timeline .post-title a,
.woocommerce .woocommerce-checkout-one-page #order_review table thead th {
	color: #626262;
}

h4,
.title-h4,
.team-person-name,
.widget .gem-teams-name,
body .testimonials-style-2-item .testimonials-style-2-text.gem-testimonial-text,
.diagram-wrapper.style-3 .digram-line-box .skill-title span,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .caption .title,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links .portfolio-sharing-pane .socials-item,
.blog-style-masonry .entry-title.title-h4 a,
.gem-basic-grid .post-title h4.entry-title a,
.gem-basic-grid-2 .vc_gitem-zone-c .post-title a,
.justified-style-1 .entry-title.title-h4 a,
.justified-style-2 .entry-title.title-h4 a,
.portfolio.hover-gradient .portfolio-item .image .overlay .links a.icon:before,
.gem-gallery-grid.hover-gradient .gallery-item .overlay a.icon::before,
.gem-gallery-hover-gradient .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.news-grid.portfolio.hover-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.news-grid.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .links .caption .author,
.news-grid.portfolio.hover-default-zooming-blur .portfolio-item.double-item-squared:hover .image .overlay .caption .title > *,
.version-new.news-grid .portfolio-item .wrap > .caption .title a {
	color: #626262;
}
.portfolio.hover-zooming-blur .portfolio-item .image .overlay .links a.icon:before,
.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay .links .portfolio-icons a.icon:before,
.portfolio.hover-new-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:after,
.portfolio.hover-default-zooming-blur .portfolio-item .image .overlay .links .portfolio-icons a.icon:before,
.portfolio.hover-default-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:after,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item a:after,
.portfolio.news-grid.hover-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:after,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay a.icon:before,
.blog article .post-featured-content > a:after,
.blog .gem-simple-gallery .gem-gallery-item a:after,
body .blog-style-styled_list1 article a.default:after,
body .blog-style-styled_list2 article a.default:after,
body .blog-style-compact article a.default:after {
	background-color: #626262;
}

h5,
.title-h5,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large,
.woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total th,
.news-grid.hover-default .portfolio-item .image .links .caption .post-date,
.news-grid.portfolio.hover-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.news-grid.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay .caption .title > *,
.news-grid.portfolio.hover-default-zooming-blur .portfolio-item:hover .image .overlay .caption .title > *,
.news-grid.version-default.portfolio.hover-default-zooming-blur .portfolio-item:hover .image .overlay .caption .title > *,
.news-grid.version-default.portfolio.hover-default-zooming-blur .portfolio-item .image .overlay .links .caption .post-date,
.news-grid.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info a,
.version-new.news-grid.hover-new-zooming-blur .portfolio-item .image .links .caption .author,
.version-default.news-grid.portfolio.hover-default-zooming-blur .portfolio-item .image .links .caption .info a {
	color: #626262;
}
.news-grid.portfolio.hover-gradient .portfolio-item .image .overlay .links .caption .info span.sep,
.version-default.news-grid.portfolio.hover-default-zooming-blur .portfolio-item .image .links .caption .info .sep {
	border-left-color: #626262;
}

h6,
.title-h6,
.widget_nav_menu > div > ul > li > a,
.widget_submenu > div > ul > li > a,
.widget_pages > ul > li > a,
.widget_categories > ul > li > a,
.widget_product_categories > ul > li > a,
.project_info-item-style-1 .project_info-item .title,
.portfolio-item:hover .wrap > .caption .title {
	color: #626262;
}

a,
.widget_recent_comments .comment-author-link a,
.widget .team-person-email a:hover:before,
.gem-team-style-5 .team-person:hover .team-person-name,
.portfolio.hover-vertical-sliding .overlay .links .caption .info a,
.portfolio.hover-horizontal-sliding .overlay .links .caption .info a,
.portfolio-set-comma,
.blog-style-masonry .entry-title.title-h4 a:hover,
.portfolio-item .caption .title a:hover,
.version-new.news-grid .portfolio-item:hover .wrap > .caption .title a,
.version-default.news-grid .portfolio-item:hover .wrap > .caption .title a,
.blog-style-styled_list1 .post-title a:hover,
.blog-style-styled_list2 .post-title a:hover,
.blog-style-timeline .post-title a:hover,
.products .product-info .product-title a:hover,
.products .portfolio-item .wrap > .caption .title a:hover,
.gem-basic-grid .post-title h4.entry-title a:hover,
.gem-basic-grid-2 .vc_gitem-zone-c .post-title a:hover,
.gem-media-grid .midia-grid-item-post-author span a:hover,
.justified-style-1 .entry-title.title-h4 a:hover,
.justified-style-2 .entry-title.title-h4 a:hover,
.blog-style-multi-author .post-title a:hover,
.reverse-link-color a:hover,
a.reverse-link-color:hover,
.gem-blog-slider .post-title a:hover,
.socials-list.socials-colored a:hover .socials-item-icon,
#footer-navigation li a:hover,
.custom-footer li a:hover,
body.woocommerce-cart .custom-header .breadcrumbs span a,
body.woocommerce-checkout .custom-header .breadcrumbs span a,
body.woocommerce-wishlist .custom-header .breadcrumbs span a,
body.woocommerce-account .page-title-block .breadcrumbs span a,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li a:hover,
body .hamburger-minicart > .minicart ul li a:hover,
.version-new.news-grid .portfolio-item .wrap > .caption .title a:hover,
.version-default.news-grid.hover-default-vertical-sliding .portfolio-item .image .links .caption .info a {
	color: #e14227;
}
.version-default.news-grid.hover-default-vertical-sliding .portfolio-item .image .links .caption .info .sep {
	border-left-color: #e14227;
}

a:hover,
body.woocommerce-cart .custom-header .breadcrumbs span a:hover,
body.woocommerce-checkout .custom-header .breadcrumbs span a:hover,
body.woocommerce-wishlist .custom-header .breadcrumbs span a:hover,
body.woocommerce-account .page-title-block .breadcrumbs span a:hover,
body .shop_table .product-name .product-title, .shop_table .product-name .product-title a:hover {
	color: #e14227;
}

a:active {
	color: #e14227;
}

#footer-navigation,
#footer-navigation li a,
.custom-footer,
.custom-footer li a {
	color: #bfbfbf;
}

.footer-site-info {
	color: #ffffff;
}

.footer-site-info a {
	color: #ffffff;
}

.wpb_widgetised_column .mc4wp-form-fields,
.sidebar .mc4wp-form-fields {
	background-color: #FFFFFFFF;
}

.top-area {
	border-top: 5px solid #9F1B96FF;
}

.top-area {
	border-bottom: 1px solid #EFEFEFFF;
}
.top-area-contacts:after,
.top-area-socials:after {
	border-right: 1px solid #EFEFEFFF;
}

.top-area {
	color: #9F1B96FF;
}


.top-area a,
.top-area .gem-contacts .gem-contacts-item a {
	color: #9F1B96FF;
}

.top-area a:hover,
.top-area .gem-contacts .gem-contacts-item a:hover {
	color: #626262FF;
}

.top-area .gem-button{
	color: #ffffff;
}

.top-area .gem-button{
	background-color: #494c64;
}


.top-area .gem-button:hover {
	color: #ffffff;
}

.top-area .gem-button:hover {
	background-color: #00bcd4;
}




#footer-menu li + li:before {
	border-right-color: #FFFFFFFF;
}


.gem-news-item-date,
.related-element-info .gem-post-date,
.date-color,
.combobox-wrapper .combobox-text,
#page .woocommerce .select2-container--default .select2-selection--single .select2-selection__rendered,
.socials-sharing a.socials-item,
body .gem-testimonial-position,
.gem-testimonial-company,
.jtwt_date,
.blog.blog-style-timeline .comments,
.entry-date,
.portfolio-filters a,
.comments-link a,
.widget_recent_entries .post-date,
.post-author-info .date,
.block-date,
.block-tags .sep,
.styled-blog-meta .comments:after,
.related-element-info .date,
.login label,
.register label,
.woocommerce-password-hint,
body .woocommerce .shop_table.wishlist_table td.product-price del,
body .woocommerce .wishlist_table .product-info .product-info-content .product-price del,
body .woocommerce .woocommerce-checkout .checkout-payment .shop_table td.product-total .amount,
body .woocommerce input[type="text"].coupon-code,
body .woocommerce .woocommerce-shipping-calculator .form-row input.input-text,
body .woocommerce .checkout .form-row .input-text,
body .woocommerce .edit-address-form .form-row .input-text,
body .woocommerce .checkout .form-row textarea.input-text,
body .woocommerce .edit-address-form .form-row textarea.input-text,
body .select2-search .select2-input,
body .woocommerce .checkout-login .login .form-row .input-text,
body .woocommerce .lost_reset_password .input-text,
body .woocommerce .my-account-signup .register .form-row .input-text,
body .chosen-container-single .chosen-single span,
body .select2-container .select2-choice > .select2-chosen,
body .select2-results .select2-result-label,
body .woocommerce .chosen-container-single .chosen-single div:before,
body .woocommerce .edit-address-form .form-row .chosen-container-single .chosen-single div:before,
body .woocommerce .select2-container .select2-choice .select2-arrow:before,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total .shipping td,
body .checkout-login .login .form-row .checkout-login-remember label,
body .shop_table .product-name dl.variation,
body.woocommerce-cart .custom-header .breadcrumbs .current,
body.woocommerce-checkout .custom-header .breadcrumbs .current,
body.woocommerce-wishlist .custom-header .breadcrumbs .current,
body.woocommerce-account .page-title-block .breadcrumbs .current,
body .woocommerce .woocommerce-checkout.woocommerce-checkout-one-page #order_review .woocommerce-checkout-payment .payment_methods .payment_box,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li .quantity,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart .total,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li.empty,
body .hamburger-minicart > .minicart .total,
body .hamburger-minicart > .minicart ul li.empty,
body .hamburger-minicart > .minicart .total strong,
body .hamburger-minicart > .minicart ul li .quantity,
body.mobile-cart-position-top #site-header .mobile-cart ul li .quantity,
body.mobile-cart-position-top #site-header .mobile-cart .total,
body.mobile-cart-position-top #site-header .mobile-cart ul li.empty,
.form-edit-adress label,
.teaser_grid_container .gem-post-date,
.gem-post-grid-item-date,
.page-title-style-2 .page-title-excerpt,
.blog.blog-style-masonry article .description .info .date,
.blog.blog-style-masonry article .description .info .sep,
.blog.blog-style-masonry article .description .info .comments,
.widget_categories > ul li,
.widget_product_categories > ul li,
.widget_pages > ul,
.gem-teams-position.body-small,
.widget .mc-text.small-body,
.widget .team-person-email,
.widget .jtwt_date,
.widget_recent_comments li,
.widget_price_filter .price_label,
.project_info-item-style-2 .project_info-item .title,
.mc4wp-form input[type='email'],
.gallery-caption,
#colophon .widget_tweets .jtwt_tweet a:hover,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-website a:hover,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-email a:hover,
#colophon .widget_calendar td a:hover,
.widget_meta li:before,
#colophon .skill-title,
#colophon .widget.widget_search .gem-button,
#colophon .wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit,
#colophon #mc4wp_submit,
#colophon .widget_meta li:before,
.gem-quote.gem-quote-style-5 blockquote,
.blog-style-masonry .post-meta-likes:hover,
.wpcf7-form .combobox-wrapper .combobox-text,
.blog-style-styled_list1 .post-date,
.blog-style-styled_list1 .post-author,
.blog-style-styled_list2 .post-date,
.blog-style-styled_list2 .post-author,
.gem-media-grid .vc_gitem-animated-block .vc_gitem-zone-b .vc_gitem-post-data-source-post_excerpt > div,
.midia-grid-item-post-author,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a,
.project-info-shortcode-style-default .project-info-shortcode-item .title,
.woocommerce-variation-availability,
.gem-search-form.gem-search-form-style-dark .search-field,
.version-new.news-grid .portfolio-item .wrap > .caption .post-date,
.version-new.news-grid .portfolio-item .wrap > .caption .post-author-date-separator,
.portfolio-selected-filters .filter-item,
.search-filter .form input,
.portfolio-filters-list .filter-item .list .price-range-slider .slider-amount .text {
	color: #99a9b5;
}

.version-default.news-grid.title-on-page.hover-horizontal-sliding .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes,
.version-default.news-grid.title-on-page.hover-zooming-blur .portfolio-item .image .links .caption .grid-post-meta .comments-link + .post-meta-likes {
	border-left-color: #99a9b5;
}

.blog.blog-style-timeline article .comments:before,
.widget .gem-contacts-email:before,
.widget .gem-contacts-phone:before,
.widget .gem-contacts-address:before,
.widget .gem-contacts-fax:before,
.top-area-search .searchform button:before,
.blog.blog-style-masonry article .description .info .comments:before,
.blog-style-default .post-image .gem-dummy:after,
.widget_product_search button,
.products .product-bottom .bottom-product-link,
.products .product-bottom .add_to_cart_button,
.products .product-bottom .added_to_cart,
.products .product-bottom .add_to_wishlist,
.products .product-bottom .yith-wcwl-wishlistaddedbrowse a,
.combobox-wrapper .combobox-button:before,
#page .woocommerce .select2-container--default .select2-selection--single .select2-selection__arrow {
	color: #99a3b0;
}

.project_info-item-style-1 .project_info-item .icon,
.widget_recent_comments li:before,
.widget_recent_entries ul li:before,
.portfolio-filters a .icon,
.comment-form label:before,
.jtwt_tweet:before,
.post-related-posts .gem-dummy:after,
.socials-sharing a:after {
	color: #b6c6c9;
}

.gem-button,
body .wp-block-button .wp-block-button__link,
.caption-bottom-line .post-footer-sharing .gem-button,
input[type='submit'],
.page-links a,
.blog-load-more button span,
.blog-load-more button span:before,
.widget a.gem-button,
#wp-calendar #prev a:after,
#wp-calendar #next a:after,
#wp-calendar caption:after,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .buttons a.checkout,
.hamburger-minicart .widget_shopping_cart_content .buttons a.checkout {
	color: #ffffff;
}

.gem-button:hover,
body .wp-block-button .wp-block-button__link:hover,
input[type='submit']:hover,
.caption-bottom-line .project-button .gem-button,
.portfolio.background-style-dark .portfolio-item:hover .caption-bottom-line .project-button .gem-button,
.page-links a,
.blog-load-more button span:hover,
.widget a.gem-button:hover,
#wp-calendar #prev a:hover:after,
#wp-calendar #next a:hover:after,
#primary-menu.no-responsive > li.menu-item-cart .widget_shopping_cart_content .buttons a:hover,
.hamburger-minicart .widget_shopping_cart_content .buttons a:hover {
	color: #E14227;
}

.gem-button,
body .wp-block-button .wp-block-button__link,
.caption-bottom-line .post-footer-sharing .gem-button,
.portfolio-filters a:hover,
input[type='submit'],
.radio-sign.checked:before,
.page-links a,
.widget a.gem-button,
#wp-calendar #prev a,
#wp-calendar #next a,
.blog-load-more button,
.ui-slider-range.ui-widget-header.ui-corner-all,
.gem-blog-slider .gem-blog-slider-prev:hover,
.gem-blog-slider .gem-blog-slider-next:hover {
	background-color: #E14227;
}
.comment-form .comment-author-input:after,
.comment-form .comment-email-input:after,
.comment-form .comment-url-input:after,
.team-person-socials .socials-item,
.checkbox-sign.checked:before,
body .wpcf7-form .combobox-button:before,
.wpcf7-form .phone:after,
.wpcf7-form .website:after,
.wpcf7-form .email:after,
.wpcf7-form  .name:after,
.wpcf7-form .gem-form-icon-phone:after,
.wpcf7-form .gem-form-icon-website:after,
.wpcf7-form .gem-form-icon-email:after,
.wpcf7-form .gem-form-icon-name:after,
.blog-style-styled_list1 .post-img a .dummy,
.blog-style-styled_list2 .post-img a .dummy,
.blog-style-timeline article:before,
.blog-style-timeline .post-img a .dummy {
	color: #E14227;
}
.checkbox-sign,
.radio-sign {
	border: 1px solid #E14227;
}

.gem-button:hover,
body .wp-block-button .wp-block-button__link:hover,
input[type='submit']:hover,
.comment-form .form-submit .gem-button.submit:hover,
.caption-bottom-line .project-button .gem-button,
.portfolio.background-style-dark .portfolio-item:hover .caption-bottom-line .project-button .gem-button,
.page-links a,
.widget a.gem-button:hover,
#wp-calendar #prev a:hover,
#wp-calendar #next a:hover,
.blog-load-more button:hover,
.page-content.content-none .searchform .searchform-submit .gem-button:hover,
.wpcf7-form.gem-contact-form-white input[type='submit']:hover,
.wpcf7-form .gem-button:not(.gem-button-wpcf-custom):hover,
.wpcf7-form input[type='submit']:not(.gem-button-wpcf-custom):hover,
.wpcf7-form.gem-contact-form-white input[type='submit']:not(.gem-button-wpcf-custom):hover,
.wpcf7-form.gem-contact-form-dark input[type='submit']:not(.gem-button-wpcf-custom):hover,
.gem-blog-slider .gem-blog-slider-prev,
.gem-blog-slider .gem-blog-slider-next,
.gem-search-form.gem-search-form-style-dark .search-field,
body .woocommerce .checkout-signin .checkout-create-account button:hover {
	background-color: #ffffff;
}
.portfolio.background-style-dark .caption-bottom-line .project-button .gem-button {
	color: #ffffff;
}

.gem-button.gem-button-style-outline,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button:hover,
body .checkout-navigation-buttons .checkout-place-order button:hover {
	color: #E14227FF;
}

.gem-button.gem-button-style-outline:hover,
#colophon.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
body #colophon .tagcloud a:hover,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button,
body .checkout-navigation-buttons .checkout-place-order button {
	color: #626262FF;
}

.gem-button.gem-button-style-outline,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button:hover,
body .checkout-navigation-buttons .checkout-place-order button:hover,
body .checkout-navigation-buttons .checkout-place-order button {
	border-color: #E14227FF;
}
.gem-button.gem-button-style-outline:hover,
.comment-form .form-submit .gem-button.submit,
.portfolio-item:hover .caption-bottom-line .project-button .gem-button,
#colophon #mc4wp_submit:hover,
#colophon.wpcf7 .wpcf7-form .contact-form-footer .wpcf7-submit:hover,
body #colophon .tagcloud a:hover,
.portfolio-item-page-bottom .project-button .gem-button,
body .woocommerce .woocommerce-cart-form .actions .submit-buttons .update-cart button,
body .checkout-navigation-buttons .checkout-place-order button {
	background-color: #E14227FF;
}

.top-area .gem-button.gem-button-style-outline{
	color: #ffffff;
}

.top-area .gem-button.gem-button-style-outline{
	background-color: #494c64;
}


.top-area .gem-button.gem-button-style-outline:hover {
	color: #ffffff;
}

.top-area .gem-button.gem-button-style-outline:hover {
	background-color: #00bcd4;
}


.widget-title,
.woocommerce .widget .gem-products-price ins,
.woocommerce .widget .gem-products-price .amount,
.widget_shopping_cart ul li .quantity,
.widget_shopping_cart .total span.amount {
	color: #E14227;
}

.widget a {
	color: #E14227;
}

.widget a:hover,
.gem-contacts-item.gem-contacts-email a,
.gem-contacts-item.gem-contacts-website a,
.widget_nav_menu li a:hover,
.widget_submenu li a:hover,
.widget_pages ul li a:hover,
.widget_categories > ul > li > a:hover,
.widget_product_categories > ul > li > a:hover,
.widget_nav_menu .current-menu-ancestor > a,
.widget_submenu .menu-item-active> a,
.widget_submenu .current-menu-ancestor > a,
.widget_categories > ul li.current-cat > a,
.widget_categories > ul > li.cat-parent.current-cat-ancestor:after,
.widget_categories > ul > li.cat-parent.current-cat-parent:after,
.widget_categories > ul > li.cat-parent.current-cat:after,
.widget_categories > ul li.cat-parent.current-cat-ancestor > a,
.widget_categories > ul li.cat-parent.current-cat-parent > a,
.widget_product_categories > ul li.current-cat > a,
.widget_product_categories > ul > li.cat-parent.current-cat-ancestor:after,
.widget_product_categories > ul > li.cat-parent.current-cat-parent:after,
.widget_product_categories > ul > li.cat-parent.current-cat:after,
.widget_product_categories > ul li.cat-parent.current-cat-ancestor > a,
.widget_product_categories > ul li.cat-parent.current-cat-parent > a,
.widget_pages > ul li li.current_page_item > a,
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a,
.widget_pages > ul li li.page_item_has_children.current_page_ancestor > a:after,
.portfolio-filters-list .filter-item .list ul li a:not(.disable):hover,
.portfolio-filters-list .filter-item .list ul li a.active,
.portfolio-filters-list .widget ul li a:hover,
.portfolio-filters-list .widget ul li.chosen > a,
.portfolio-filters-list .widget ul li.current-cat > a,
.portfolio-sorting-select ul li a:hover,
.portfolio-sorting-select ul li.current,
.thegem-popup-notification .notification-message:before {
	color: #626262FF;
}
.portfolio-filters-list .filter-item .list ul li a:not(.disable):hover .count,
.portfolio-filters-list .filter-item .list ul li a.active .count,
.portfolio-filters-list .widget ul li a:hover + .count,
.portfolio-filters-list .widget ul li.chosen > .count,
.portfolio-filters-list .widget ul li.current-cat > .count,
.portfolio-filters-list .filter-item .list .price-range-slider .slider-range .ui-slider-range,
.portfolio-filters-list .filter-item .list .price-range-slider .slider-range .ui-slider-handle,
.portfolio-filters-list .filter-item .list .price-range-slider .slider-range .ui-slider-handle + span,
.portfolio-filters-list .widget .price_slider .ui-slider-range,
.portfolio-filters-list .widget .price_slider .ui-slider-handle,
.portfolio-filters-list .widget .price_slider .ui-slider-handle + span {
	background-color: #626262FF;
}

.widget a:active {
	color: #E14227;
}

#colophon .widget-title {
	color: #FFFFFFFF;
}

#colophon .widget,
body #colophon .widget .gem-testimonial-position,
#colophon .widget.widget_recent_entries .post-date {
	color: #FFFFFFFF;
}

#colophon .widget a,
#colophon .gem-contacts .gem-contacts-item:before {
	color: #FFFFFFFF;
}

#colophon .widget a:hover,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-website a,
#colophon .gem-contacts .gem-contacts-item.gem-contacts-email a,
#colophon #wp-calendar #prev a:hover:after,
#colophon #wp-calendar #next a:hover:after,
#colophon  .widget_tweets .jtwt_tweet a,
#colophon  .recentcomments .comment-author-link a,
#colophon .widget .jtwt_date,
#colophon  .widget_tweets .twitter-box .jtwt_tweet:before,
#colophon .recentcomments .comment-author-link,
#colophon .comment-author-link,
#colophon .gem-latest-news-title span,
#colophon .gem-pp-posts-date,
#colophon .gem-contacts a:before {
	color: #ffffff;
}

#colophon .widget a:active {
	color: #ffffff;
}

#colophon .widget-title:after {
	color: #FFFFFFFF;
	display: inline-block;
}


.portfolio-item .caption .title,
.portfolio-item .caption .title a {
	color: #5f727f;
}

.portfolio-item .caption .subtitle {
	color: #5f727f;
}

.portfolio-item .caption .info,
.portfolio.background-style-gray .portfolio-item:hover .wrap > .caption .subtitle,
.portfolio.background-style-dark .portfolio-item:not(:hover) .wrap > .caption .subtitle,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .author .author-name,
.news-grid.hover-horizontal-sliding .portfolio-item .image .links .caption .author .author-name a {
	color: #99a9b5;
}











.slideinfo .fancybox-title {
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.8);
}

.fancybox-title .slide-info-title,
.fancybox-title .gem-gallery-item-title {
	color: #ffffff;
}

.fancybox-title .slide-info-summary,
.fancybox-title .gem-gallery-item-description {
	color: #ffffff;
}

.nivo-directionNav .gem-prev:after,
.nivo-directionNav .gem-next:after,
.ls-thegem a.ls-nav-prev:after,
.ls-thegem a.ls-nav-next:after {
	background-color: #394050;
	font-size: 27px;
}

.vc_images_carousel .vc_carousel-control .icon-prev:before,
.vc_images_carousel .vc_carousel-control .icon-next:before,
.nivo-directionNav .gem-prev:after,
.nivo-directionNav .gem-next:after,
.ls-thegem a.ls-nav-prev:after,
.ls-thegem a.ls-nav-next:after {
	color: #ffffff;
}

.gem-prev:after,
.gem-next:after,
.row .wpb_gallery .wpb_flexslider .flex-direction-nav a,
.row .theme-default .nivo-directionNav a,
.row .vc_carousel-control .icon-next,
.row .vc_carousel-control .icon-prev,
.thegem_pf_item .post-meta-navigation a,
.thegem_pf_item .post-meta-left .post-meta-date:before {
	color: #3c3950;
}

.gem-prev:after,
.gem-next:after,
.row .theme-default .nivo-directionNav a,
.row .vc_images_carousel .vc_carousel-control .icon-prev,
.row .vc_images_carousel .vc_carousel-control .icon-next,
.portfolio.portfolio-slider .portolio-slider-prev span,
.portfolio.portfolio-slider .portolio-slider-next span,
.gem-gallery .gem-gallery-thumbs-navigation .gem-prev:not(:hover):after,
.gem-gallery .gem-gallery-thumbs-navigation .gem-next:not(:hover):after{
	background-color: #b6c6c9;
}

.gem-prev:hover:after,
.gem-next:hover:after,
.row .wpb_gallery .wpb_flexslider .flex-direction-nav a:hover,
.row .theme-default .nivo-directionNav a:hover,
.row .vc_images_carousel .vc_carousel-control .icon-prev:hover,
.row .vc_images_carousel .vc_carousel-control .icon-next:hover,
.row .vc_images_carousel .vc_carousel-control .icon-prev:hover:before,
.row .vc_images_carousel .vc_carousel-control .icon-next:hover:before,
.portfolio.portfolio-slider .portolio-slider-prev span,
.portfolio.portfolio-slider .portolio-slider-next span,
.portfolio.portfolio-slider .portolio-slider-prev:hover span,
.portfolio.portfolio-slider .portolio-slider-next:hover span {
	color: #ffffff;
}

.gem-prev:hover:after,
.gem-next:hover:after,
.gem-testimonials.fullwidth-block .gem-prev:hover:after,
.gem-testimonials.fullwidth-block .gem-next:hover:after,
.row .wpb_gallery .wpb_flexslider .flex-direction-nav a:hover,
.row .theme-default .nivo-directionNav a:hover,
.row .vc_images_carousel .vc_carousel-control .icon-prev:hover,
.row .vc_images_carousel .vc_carousel-control .icon-next:hover,
.portfolio.portfolio-slider .portolio-slider-prev:hover span,
.portfolio.portfolio-slider .portolio-slider-next:hover span {
	background-color: #00bcd4;
}





.gem-image a:before,
.gem-textbox-picture a:before,
.team-person-image a:before,
.team-person-image span:before,
.gem-gallery .gem-gallery-preview-carousel-wrap .gem-gallery-item a:before,
.gem-simple-gallery .gem-gallery-item a:before,
.portfolio-item .image .overlay:before,
.gallery-item .overlay:before,
.widget .flickr-item a:before,
.widget-gem-portfolio-item .widget-gem-portfolio-item-hover,
div.blog article a.youtube:before,
div.blog article a.vimeo:before,
div.blog article a.self_video:before,
div.blog article a.default:before,
.widget .gem-pp-posts a:before,
.widget .gem-latest-news-image a:before {
		background-color: rgba(0, 188, 212, 0.8);
	}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item a:before,
.gem-gallery-hover-default .active .gem-gallery-item-image:after {
	background-color: #00bcd4;
}
.gem-gallery .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg {
	fill: #00bcd4;
}
.gem-gallery-hover-default .active .gem-gallery-item-image:after {
	background-color: #00bcd4;
}
.portfolio.products.hover-zooming-blur .portfolio-item .overlay .product-rating .star-rating > span:before,
.portfolio.products.hover-zooming-blur .portfolio-item .image .links .caption .product-price .amount,
.portfolio.products.hover-zooming-blur .portfolio-item .image .links .caption .product-price,
.portfolio.products.hover-zooming-blur .portfolio-item .image .overlay .portfolio-icons.product-bottom a.added_to_cart,
.portfolio.products.hover-zooming-blur .portfolio-item .image .overlay .portfolio-icons.product-bottom .yith-wcwl-wishlistaddedbrowse a {
	color: #00bcd4;
}
body .products .product-image .woo-product-overlay,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li .minicart-image:after,
body .hamburger-minicart > .minicart ul li .minicart-image:after,
body.mobile-cart-position-top #site-header .mobile-cart ul li .minicart-image:after,
body .widget_shopping_cart ul li .minicart-image:after,
body ul.product_list_widget .gem-products-image a:before {
		background-color: rgba(0, 188, 212, 0.3);
	}

.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap .gem-gallery-item a:before,
.gem-gallery-hover-zooming-blur .gem-gallery-preview-carousel-wrap:hover .gem-gallery-item a:before,
.portfolio.hover-zooming-blur .portfolio-item .image .overlay:before,
.portfolio.hover-new-zooming-blur .portfolio-item .image .overlay:before,
.hover-new-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.portfolio.hover-default-zooming-blur .portfolio-item .image .overlay:before,
.hover-default-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-zooming-blur .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.gem-gallery-grid.hover-zooming-blur .gallery-item .overlay:before,
.gem-gallery-hover-zooming-blur .gem-gallery-item .gem-gallery-item-image a:before,
body .blog-style-styled_list1 article a.default:before,
body .blog-style-styled_list2 article a.default:before,
body .blog-style-compact article a.default:before,
.blog article .post-featured-content > a:before,
.blog .gem-simple-gallery .gem-gallery-item a:before {
		background-color: rgba(255, 255, 255, 0.8);
	}
.gem-gallery.gem-gallery-hover-zooming-blur .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg,
.gem-gallery.gem-gallery-hover-gradient .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg{
	fill: #ffffff;
}
.gem-gallery.gem-gallery-hover-zooming-blur .active .gem-gallery-item-image:after,
.gem-gallery.gem-gallery-hover-gradient .active .gem-gallery-item-image:after
{
	background-color: #ffffff;
}
.portfolio.products.hover-zooming-blur .portfolio-item .overlay .product-rating .star-rating:before {
	color: #ffffff;
}

.gem-gallery-hover-horizontal-sliding .gem-gallery-item .gem-gallery-item-image a:before,
.portfolio.hover-horizontal-sliding .overlay:before,
.gem-gallery-grid.hover-horizontal-sliding .gallery-item .overlay:before,
.hover-new-horizontal-sliding .portfolio-item .image .overlay:before,
.hover-new-horizontal-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-default-horizontal-sliding .portfolio-item .image .overlay:before,
.hover-default-horizontal-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-horizontal-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before {
		background-color: rgba(70, 72, 92, 0.8);
	}
.gem-gallery-hover-horizontal-sliding .active .gem-gallery-item-image:after {
	background-color: #46485c;
}
.gem-gallery.gem-gallery-hover-horizontal-sliding .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg{
	fill: #46485c;
}
.team-person-image span:before,
.gem-team-style-5 .team-person-image a:before,
.gem-team-style-5 .team-person-image span:before {
		background-color: rgba(70, 72, 92, 0.7);
	}



.gem-gallery-hover-vertical-sliding .gem-gallery-item .gem-gallery-item-image a:before,
.portfolio.hover-vertical-sliding .overlay:before,
.portfolio.hover-new-vertical-sliding .overlay:before,
.portfolio.hover-new-vertical-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.portfolio.hover-default-vertical-sliding .overlay:before,
.portfolio.hover-default-vertical-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.hover-vertical-sliding .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
.gem-gallery-grid.hover-vertical-sliding .gallery-item .overlay:before {
		background-color: rgba(225, 66, 39, 0.8);
	}
.gem-gallery-hover-vertical-sliding .active .gem-gallery-item-image:after {
	background-color: #E14227FF;
}
.gem-gallery.gem-gallery-hover-vertical-sliding .gem-gallery-thumbs-carousel-wrap .gem-gallery-item svg{
	fill: #E14227FF;
}

.quickfinder-item-title,
.quickfinder-item-title a,
.project_info-item-style-2 .title {
	color: #4c5867;
}

.quickfinder-item-text,
.quickfinder-item-text a {
	color: #5f727f;
}





.widget_archive li:before,
.widget_nav_menu ul.menu li > a:before,
.widget_submenu > div > ul li > a:before,
.widget_categories > ul li > a:before,
.widget_product_categories > ul li > a:before,
.widget_pages > ul li > a:before,
.widget_meta li:before,
.widget_rss li:before,
.project_info-item-style-2 .icon,
.gem_accordion_header .ui-accordion-header-icon:before,
.wpb_toggle:after,
.vc_toggle_title:after,
.widget_layered_nav ul li a:before,
.widget .gem-contacts-address:before,
.widget .gem-contacts-phone:before,
.widget .gem-contacts-fax:before,
.widget .gem-contacts-email:before,
.widget .gem-contacts-website:before,
.resp-accordion:after {
	color: #626262FF;
}

.gem-icon,
.widget .team-person-email a:before,
.woocommerce-message:before,
.woocommerce-info:before {
	color: #91a0ac;
}


.gem-pagination a,
.gem-pagination .current {
	border: 2px solid #626262FF;
}
.gem-pagination .prev,
.gem-pagination .next {
	background-color: #626262FF;
}
.gem-pagination a,
.gem-pagination .current {
	color: #626262FF;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	border: 2px solid #626262FF !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a {
	background-color: #626262FF !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	color: #626262FF !important;
}

.gem-pagination a,
.gem-pagination .current {
	background-color: #ffffff;
}
.gem-pagination a:hover,
.gem-pagination .current,
.gem-pagination .prev,
.gem-pagination .next {
	color: #ffffff;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a {
	background-color: #ffffff !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page.vc_grid-active a {
	color: #ffffff !important;
}

.gem-pagination a:hover {
	background-color: #9F1B96FF;
}
.gem-pagination a:hover {
	border-color: #9F1B96FF;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-prev a:hover,
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-next a:hover {
	background-color: #9F1B96FF;
}

.gem-pagination .current {
	background-color: #222222FF;
}
.gem-pagination .current {
	border-color: #222222FF;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page.vc_grid-active a {
	background-color: #222222FF !important;
}
body .vc_grid-pagination .vc_grid-pagination-list li.vc_grid-page.vc_grid-active a{
	background-color: #222222FF !important;
}

.gem-mini-pagination a,
.flexslider .flex-control-paging li a,
.flexslider .flex-control-paging li a:hover,
.vc_carousel-indicators li,
.row .gem-mini-pagination a,
.row .flexslider .flex-control-paging li a,
.row .flexslider .flex-control-paging li a:hover,
.row .vc_carousel-indicators li,
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_pagination-trigger,
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_pagination-trigger:hover,
.row .theme-default .nivo-controlNav a,
.product-gallery .owl-dots .owl-dot span{
	background-color: #626262FF;
}
.portfolio-filters-list .filter-item.single .list ul li a:before {
	border-color: #626262FF;
}

.gem-mini-pagination a.selected,
.gem-mini-pagination a.active,
.flexslider .flex-control-paging li a.flex-active,
.flexslider .flex-control-paging li a.flex-active:hover,
.vc_carousel-indicators .vc_active,
.row .flexslider .flex-control-paging li a.flex-active,
.row .flexslider .flex-control-paging li a.flex-active:hover,
.row .vc_carousel-indicators .vc_active,
.vc_pagination.vc_pagination-style-thegem.vc_pagination-shape-circle .vc_active .vc_pagination-trigger,
.row .theme-default .nivo-controlNav a.activer,
.product-gallery .owl-dots .owl-dot.active span{
	background-color: #222222FF;
}




#footer-socials a.socials-item {
	color: #FFFFFFFF;
}



input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
.chosen-container,
.select2-container,
.checkbox-sign,
.radio-sign {
	background-color: #EFEFEFFF;
}
.chosen-container-single .chosen-single div:before,
.select2-container .select2-choice .select2-arrow:before {
	color: #EFEFEFFF;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
select,
.chosen-container-single .chosen-single,
.select2-container .select2-choice,
.widget_product_search form:before {
	color: #626262FF;
}

input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="month"],
input[type="week"],
textarea,
.combobox-wrapper,
#page .woocommerce .select2-container .select2-selection--single,
.select2-container .select2-dropdown,
.chosen-container-single .chosen-single,
.select2-container .select2-choice,
select.wpcf7-form-control.wpcf7-select {
	border: 1px solid #BDBDBDFF;
}
.chosen-container-single .chosen-single div,
.edit-address-form .form-row .chosen-container-single .chosen-single div,
.select2-container .select2-choice .select2-arrow {
	background-color: #BDBDBDFF;
}

body .breadcrumbs,
body .breadcrumbs a,
body .bc-devider:before {
	color: #bdbdbd;
}

body .breadcrumbs .current {
	color: #222222FF;
	border-bottom: 3px solid #222222FF;
}


.products .product .product-inner:not(:hover) .product-info .product-title,
.products .portfolio-item:not(:hover) .wrap > .caption .title {
	color: #5f727f;
}

.single-product-content .product_title {
	color: #3c3950;
}

.widget .gem-products-content .gem-products-title,
.widget .gem-products-content .gem-products-title a {
	color: #5f727f;
}

.shop_table .product-name .product-title,
.shop_table .product-name .product-title a {
	color: #00bcd4;
}

.products .product-info .price ins,
.products .product-info .price .amount {
	color: #00bcd4;
}

.single-product-content .price {
	color: #3c3950;
}

.widget .gem-products-content .gem-products-price ins,
.widget .gem-products-content .gem-products-price .amount {
	color: #3c3950;
}

.woocommerce .shop_table tr td.product-price,
.woocommerce .shop_table tr td.product-subtotal,
.woocommerce .wishlist_table .product-info .product-info-content .product-price,
body .woocommerce .shop_table td.product-price,
body .woocommerce .shop_table td.product-subtotal,
body .woocommerce .wishlist_table .product-info .product-info-content .product-price,
body .woocommerce .woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-price,
body .woocommerce .woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
body .woocommerce .cart_totals table td .amount,
body .woocommerce .cart_totals table tr.order-total td .amount,
body .woocommerce .cart_totals table tr td,
body .woocommerce .woocommerce-checkout .shop_table.woocommerce-checkout-payment-total .cart-subtotal td .amount,
body .woocommerce .woocommerce-checkout #order_review .woocommerce-checkout-payment .payment_methods .payment_box,
body .woocommerce .woocommerce-checkout #order_review .woocommerce-checkout-payment .payment_methods .payment_box:before,
body .woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .checkout-payment .shop_table td.product-total .amount,
body .woocommerce .woocommerce-checkout.woocommerce-checkout-one-page .shop_table.woocommerce-checkout-payment-total .order-total td .amount,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart ul li .quantity span.amount,
body #primary-menu.no-responsive > li.menu-item-cart > .minicart .total span.amount,
body .hamburger-minicart > .minicart ul li .quantity span.amount,
body .hamburger-minicart > .minicart .total span.amount,
body.mobile-cart-position-top #site-header .mobile-cart .total span.amount {
	color: #3c3950;
}


.portfolio.item-separator .portfolio-item:before,
.portfolio.item-separator .portfolio-item:after,
.portfolio.item-separator .portfolio-item .item-separator-box:before,
.portfolio.item-separator .portfolio-item .item-separator-box:after {
	border-color: #000000;
}






















































body {
	background-color: #BDBDBD;
}



.header-background:before {
	background-color: #ffffff;
}
#site-header-wrapper {
	background-color: #ffffff;
}

.header-layout-fullwidth_hamburger #primary-menu.no-responsive,
#thegem-perspective .perspective-navigation:not(.responsive),
#page.vertical-header #site-header-wrapper{
	background-color: #ffffff;
}


.top-area-background:before {
	background-color: #FFFFFFFF;
}

.block-content {
	background-color: #ffffff;
}

#footer-nav {
	background-color: #9F1B96FF;
}

#colophon {
	background-color: #9F1B96FF;
}

            .custom-footer {
            background-color: #9F1B96FF;
        }
    




.preloader:after,
.loading:after {
	display: none;
}
.preloader .preloader-spin,
.loading .preloader-spin {
	display: block;
}

.preloader:after,
.loading:after {
	display: none;
}
.preloader .preloader-spin,
.loading .preloader-spin {
	display: block;
}













	.widget-title:after{
		color: #5E6467;
		display: inline-block;
	}

    body .hover-gradient .portfolio-item .image .overlay:before,
    body .hover-gradient .gallery-item .overlay-wrap .overlay:before,
    body .hover-new-gradient .portfolio-item .image .overlay:before,
    body .portfolio.hover-new-gradient .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
    body .hover-default-gradient .portfolio-item .image .overlay:before,
    body .portfolio.hover-default-gradient .portfolio-item .gem-simple-gallery .gem-gallery-item a:before{
			background: linear-gradient(
			90deg,
			rgba(255,43,88,0.8) 0%,
			rgba(255,216,0,0.8) 100%
		) !important;
	
	}

	body .hover-circular .portfolio-item .image .overlay .overlay-circle,
	body .hover-circular .gallery-item .overlay-wrap .overlay .overlay-circle,
    body .hover-new-circular .portfolio-item .image .overlay .overlay-circle,
    body .portfolio.hover-new-circular .portfolio-item .gem-simple-gallery .gem-gallery-item a:before,
    body .hover-default-circular .portfolio-item .image .overlay .overlay-circle,
    body .portfolio.hover-default-circular .portfolio-item .gem-simple-gallery .gem-gallery-item a:before{
			background: linear-gradient(
		90deg,
		rgba(0, 188, 212,0.75) 0%,
		rgba(53, 64, 147,0.75) 100%
		) !important;
	
		}

	.portfolio.extended-products-grid .portfolio-item .caption .title {
	font-family: 'Montserrat';
	}

	.portfolio.extended-products-grid .portfolio-item .caption .title {
	font-style: normal;
	font-weight: 700;
	}
		
	.portfolio.extended-products-grid .portfolio-item .caption .title {
	font-size: 14px;
	}

	.portfolio.extended-products-grid .portfolio-item .caption .title {
	line-height: 18.2px;
	max-height: calc(18.2px * 2);
	}

	.portfolio.extended-products-grid .portfolio-item .caption .title {
	text-transform: none;
	}

	.portfolio.extended-products-grid .portfolio-item .caption .title {
	letter-spacing: 0px;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .title {
	color: #5F727FFF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item:hover .caption .title {
	color: #3C3950FF;
	}

	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .image .overlay .links .caption .title,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .image .overlay .links .caption .title,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .image .overlay .links .caption .title,
	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple,
	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable {
	color: #212227FF;
	}

	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple,
	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable {
	border-color: #212227FF;
	}

	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .image .caption .categories,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .image .caption .categories,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .image .caption .categories {
	background-color: #212227FF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories,
	.portfolio.extended-products-grid.caption-position-hover:not(.hover-slide):not(.hover-fade) .portfolio-item .caption .categories {
	font-family: 'Montserrat';
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories,
	.portfolio.extended-products-grid.caption-position-hover:not(.hover-slide):not(.hover-fade) .portfolio-item .caption .categories {
	font-style: normal;
	font-weight: 500;
	}
		
	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories,
	.portfolio.extended-products-grid.caption-position-hover:not(.hover-slide):not(.hover-fade) .portfolio-item .caption .categories {
	font-size: 9px;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories,
	.portfolio.extended-products-grid.caption-position-hover:not(.hover-slide):not(.hover-fade) .portfolio-item .caption .categories {
	line-height: 10.8px;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories,
	.portfolio.extended-products-grid.caption-position-hover:not(.hover-slide):not(.hover-fade) .portfolio-item .caption .categories {
	text-transform: uppercase;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories,
	.portfolio.extended-products-grid.caption-position-hover:not(.hover-slide):not(.hover-fade) .portfolio-item .caption .categories {
	letter-spacing: 0.45px;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories {
	color: #99A9B5FF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .categories a:hover {
	color: #00BCD4FF;
	}

	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .image .caption .categories,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .image .caption .categories,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .image .caption .categories {
	color: #FFFFFFFF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption .product-price .price {
	font-family: 'Source Sans Pro';
	}

	.portfolio.extended-products-grid .portfolio-item .caption .product-price .price {
	font-style: normal;
	font-weight: normal;
	}
		
	.portfolio.extended-products-grid .portfolio-item .caption .product-price .price {
	font-size: 16px;
	}

	.portfolio.extended-products-grid .portfolio-item .caption .product-price .price {
	line-height: 25px;
	}


	.portfolio.extended-products-grid .portfolio-item .caption .product-price .price {
	letter-spacing: 0px;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption .product-price .price {
	color: #5F727FFF;
	}

	.portfolio.extended-products-grid.caption-container-preset-light .portfolio-item .image .caption .product-price .price,
	.portfolio.extended-products-grid.caption-container-preset-solid .portfolio-item .image .caption .product-price .price,
	.portfolio.extended-products-grid.caption-container-preset-transparent .portfolio-item .image .caption .product-price .price {
	color: #212227FF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button,
	.thegem-popup-notification .notification-message a.button {
	border-width: 1px;
	border-style: solid;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button,
	.thegem-popup-notification .notification-message a.button {
	border-radius: 30px;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple {
	color: #5F727FFF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple:hover {
	color: #FFFFFFFF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple {
	background-color: #DFE5E8FF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple:hover {
	background-color: #00BCD4FF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple {
	border-color: #DFE5E8FF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_simple:hover {
	border-color: #00BCD4FF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable,
	.thegem-popup-notification .notification-message a.button {
	color: #5F727FFF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable:hover,
	.thegem-popup-notification .notification-message a.button:hover {
	color: #FFFFFFFF;
	}


	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable:hover,
	.thegem-popup-notification .notification-message a.button:hover {
	background-color: #00BCD4FF;
	}

	.portfolio.extended-products-grid.caption-position-page .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable,
	.thegem-popup-notification .notification-message a.button {
	border-color: #5F727FFF;
	}

	.portfolio.extended-products-grid .portfolio-item .caption a.add_to_cart_button.type_button.product_type_variable:hover,
	.thegem-popup-notification .notification-message a.button:hover {
	border-color: #00BCD4FF;
	}



	.portfolio.extended-products-grid .portfolio-item .wrap > .caption .product-bottom a.icon,
	.portfolio.extended-products-grid.caption-position-hover.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-hover.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-image.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-image.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid .portfolio-top-panel .portfolio-top-panel-right .search-filter .search-btn {
	border-radius: 20px;
	}

	.portfolio.extended-products-grid .portfolio-item .wrap > .caption .product-bottom a.icon,
	.portfolio.extended-products-grid.caption-position-hover.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-hover.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid .portfolio-top-panel .portfolio-top-panel-right .search-filter .search-btn {
	color: #5F727FFF;
	}

	.portfolio.extended-products-grid .portfolio-item .wrap > .caption .product-bottom a.icon:hover,
	.portfolio.extended-products-grid.caption-position-hover.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover,
	.portfolio.extended-products-grid.caption-position-hover.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover,
	.portfolio.extended-products-grid .portfolio-top-panel .portfolio-top-panel-right .search-filter .search-btn:hover {
	color: #FFFFFFFF;
	}

	.portfolio.extended-products-grid.caption-position-image .portfolio-item .image .overlay .links .portfolio-icons a.icon {
	color: #212227FF;
	}

	.portfolio.extended-products-grid.caption-position-image .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover {
	color: #FFFFFFFF;
	}

	.portfolio.extended-products-grid .portfolio-item .wrap > .caption .product-bottom a.icon,
	.portfolio.extended-products-grid.caption-position-hover.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-hover.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-image.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid.caption-position-image.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon,
	.portfolio.extended-products-grid .portfolio-top-panel .portfolio-top-panel-right .search-filter .search-btn {
	background-color: #DFE5E8FF;
	}

	.portfolio.extended-products-grid .portfolio-item .wrap > .caption .product-bottom a.icon:hover,
	.portfolio.extended-products-grid.caption-position-hover.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover,
	.portfolio.extended-products-grid.caption-position-hover.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover,
	.portfolio.extended-products-grid.caption-position-image.hover-slide .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover,
	.portfolio.extended-products-grid.caption-position-image.hover-fade .portfolio-item .image .overlay .links .portfolio-icons a.icon:hover,
	.portfolio.extended-products-grid .portfolio-top-panel .portfolio-top-panel-right .search-filter .search-btn:hover {
	background-color: #00BCD4FF;
	}



	.product .onsale,
	.product .new-label,
	.product .out-of-stock-label {
	font-family: 'Montserrat';
	}

	.product .onsale,
	.product .new-label,
	.product .out-of-stock-label {
	font-style: normal;
	font-weight: 700;
	}
		
	.portfolio.extended-products-grid .product .onsale,
	.portfolio.extended-products-grid .product .new-label,
	.portfolio.extended-products-grid .product .out-of-stock-label {
	font-size: 7px;
	}

	.portfolio.extended-products-grid .product .onsale,
	.portfolio.extended-products-grid .product .new-label,
	.portfolio.extended-products-grid .product .out-of-stock-label {
	line-height: 8.4px;
	}

	.product .onsale,
	.product .new-label,
	.product .out-of-stock-label {
	text-transform: uppercase;
	}

	.portfolio.extended-products-grid .product .onsale,
	.portfolio.extended-products-grid .product .new-label,
	.portfolio.extended-products-grid .product .out-of-stock-label {
	letter-spacing: 0.35px;
	}

	.product .onsale,
	.product .new-label,
	.product .out-of-stock-label {
	color: #FFFFFFFF;
	}

	.product .onsale {
	background-color: #00BCD4FF;
	}
	.product .onsale:after {
	border-left-color: #00BCD4FF;
	border-right-color: #00BCD4FF	}
	.product .onsale svg {
	fill: #00BCD4FF;
	}
	.product .onsale svg {
	fill: #00BCD4FF;
	}

	.product .new-label {
	background-color: #393D50FF;
	}
	.product .new-label:after {
	border-left-color: #393D50FF;
	border-right-color: #393D50FF	}
	.product .new-label svg {
	fill: #393D50FF;
	}

	.product .out-of-stock-label {
	background-color: #F44336FF;
	}
	.product .out-of-stock-label:after {
	border-left-color: #F44336FF;
	border-right-color: #F44336FF	}
	.product .out-of-stock-label svg {
	fill: #F44336FF;
	}

	.quick-view-button {
	color: #FFFFFFFF;
	}

	.quick-view-button {
	background-color: #00BCD4A6;
	}

#site-header-wrapper { border-bottom: 1px solid rgba(239, 239, 239, .5) }
#footer-navigation, #footer-navigation li a, footer.custom-footer, footer.custom-footer li a { color: #ffffff !important; }
a.btn.p-outline { background-color: #9f1b96; border-color: #ffffff; }
a.btn.p-outline:hover { background-color: #ffffff; color: #9f1b96; }
#footer-nav { border-top-color: #efefef; }
.top-area, #site-header.fixed.shrink { background-color: #ffffff; }
.header-style-2 #primary-menu.no-responsive li li:hover > a, .header-style-3 #primary-menu.no-responsive li li:hover a, .header-style-2 #primary-menu.no-responsive li li.menu-item-active > a, .header-style-3 #primary-menu.no-responsive li li.menu-item-active a { border-left-color: #9f1b96; }

@media (max-width: 768px){
.postid-6954 #page-title {
    padding-bottom: 90px !important;
    text-align: center;
    }
   .postid-6954  .page-title-alignment-left .page-title-icon {
    float: none;
}
}

.page-id-8406 .layout-boxed .gem-icon-with-text.gem-icon-with-text-float-right .gem-icon-with-text-icon {
    display: block;
    float: left;
    clear: both;
    width: 100%;
    padding-bottom: 30px;
}

.page-id-7952 .portfolio.hover-horizontal-sliding .overlay .links .caption .description {
    display: none !important;
}
@media only screen and (max-width: 2500px) and (min-width: 1680px) {
.page-id-16895 .layout-boxed  .blog.blog-style-masonry.fullwidth-block article {
    width: 24.1%;
}
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
.page-id-16895 .blog.blog-style-masonry.fullwidth-block article {
    width: 33%;
}
}

#colophon .gem-clients  .grayscale, 
#colophon .gem-clients  img {
 filter: none !important;
 background-color: #181828 !important;
 -webkit-filter: grayscale(0%);
 -moz-filter: grayscale(0%);
 filter: grayscale(0%);
 filter:none;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
.postid-7334 .team-person-hover {
    padding-bottom: 20px !important;
} 
.postid-6465  .gem-team-style-6 .team-person-hover,
.postid-5979  .gem-team-style-6 .team-person-hover {
    padding: 34px 34px;
    padding-bottom: 15px;
}

.postid-7334 .team-person-email ,
.postid-6629 .team-person-email ,
.postid-5985 .team-person-email ,
.postid-6465 .team-person-email ,
.postid-5979 .team-person-email {
    display: none;
}

h1,
.title-h1,
.pricing-table-style-8 .pricing-cost {
	letter-spacing: 0.05em;
}
h2,
.title-h2,
h3.comment-reply-title,
.pricing-table-style-6 .pricing-price-title,
.single-product-content .price {
	letter-spacing: 0.05em;
}
h3,
.title-h3,
.cart_totals table .order-total td,
.woocommerce table.shop_table.order-details tr.cart_item td.product-total .amount,
.woocommerce table.shop_table.order-details tr.order_item td.product-total .amount {
	letter-spacing: 0.05em;
}
h4,
.title-h4,
.widget .gem-teams-name,
body .vc_separator h4,
.pricing-table-style-3 .pricing_row_title,
.pricing-table-style-8 .pricing_row_title, 
.pricing-table-style-4 .pricing_row_title,
.single-product-content .price del,
.quickfinder-item .quickfinder-item-title {
	letter-spacing: 0.05em;
}
h5,
.title-h5,
.gem-table thead th,
.gem-teams-phone,
.shop_table td.product-price,
.shop_table td.product-subtotal,
.cart_totals table .cart-subtotal td,
.woocommerce-cart-form.responsive .cart-item .gem-table .shop_table td.product-subtotal,
.woocommerce table.shop_table.order-details thead tr th.product-name,
.woocommerce table.shop_table.order-details thead tr th.product-total,
.widget-gem-portfolio-item.gem-portfolio-dummy:after, 
.resp-tabs-list li,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5,
.blog-style-styled_list1 .post-time,
.gem-teams-phone {
	letter-spacing: 0.05em;
}
h6,
.title-h6,
.project_info-item-style-1 .project_info-item .title,
.gem_accordion_header a,
.widget_rss li,
.wpb_toggle,
.vc_toggle_title h4,
.testimonials-style-1-name.gem-testimonial-name,
.testimonials-style-2-name.gem-testimonial-name,
.diagram-wrapper .digram-line-box .skill-amount,
.diagram-wrapper.style-3 .digram-line-box .skill-title,
.row .vc_progress_bar .vc_label {
	letter-spacing: 0.05em;
}
.letter-spacing {
	letter-spacing: 0.05em;
}
#wp-calendar caption {
	letter-spacing: 0.08em;
}
.pricing-table-style-1 .pricing-price-title {
    letter-spacing: 1px;
}
.gem-quote.gem-quote-style-3 blockquote,
.gem-quote.gem-quote-style-4,
.gem-quote.gem-quote-style-5 {
	letter-spacing: 0.085em;
}
.diagram-wrapper.style-3 .digram-line-box  .skill-title {
	letter-spacing: 0.07em;
}
.gem-basic-grid .post-title h4.entry-title a{
	letter-spacing: 1.2px;
}
.gem-media-grid .vc_gitem-post-data-source-post_title a{
	letter-spacing: 0.05em;
}
.gem-media-grid-2 .vc_gitem-post-data-source-post_title a{
	letter-spacing: 0.05em;
}
.gem-button {
	letter-spacing: 0.05em;
}
.top-area-button .gem-button {
	letter-spacing: 0;
}
@media (max-width: 767px) {
	h1,
	.title-h1,
	.page-title-title h1 {
		font-size: 38px;
		line-height: 53px;
	}
}
@media (max-width: 879px) {
	h2,
	.title-h2,
	.pricing-table-style-5  .pricing-price-title,
	.pricing-table-style-6 .pricing-price-title,
	.pricing-table-style-6 .pricing-price-subtitle,
	h3.comment-reply-title,
	.pricing-table-style-2 .pricing-price-title {
		font-size: 32px;
	}
}
@media (max-width: 879px) {
	h2,
	.title-h2,
	.pricing-table-style-6 .pricing-price-title,
	h3.comment-reply-title {
		line-height: 47px;
	}
}
@media (max-width: 599px) {
	.title-xlarge {
		font-size: 42px;
		line-height: 54px;
	}
}
@media (max-width: 499px) {
	.styled-subtitle,
	.vc_pie_chart,
	form.cart .quantity .qty,
	.shop_table .quantity .qty,
	.woocommerce-before-cart .cart-short-info,
	input[type="text"].coupon-code,
	.cart_totals table th,
	.order-totals table th,
	.cart_totals table .shipping td,
	.woocommerce-message,
	.woocommerce-info,
	.woocommerce ul.woocommerce-error li,
	.woocommerce table.checkout-cart-info-table tr th
	.woocommerce table.checkout-cart-info-table tr.shipping td,
	.widget_calendar caption,
	.blog-style-timeline .post-time,
	.gem-dropcap.gem-dropcap-style-medium,
	.project-info-shortcode-style-default .project-info-shortcode-item .title,
	.project_info-item-style-2 .project_info-item .title,
	.diagram-legend .legend-element .title,
	.widget_shopping_cart .total span.amount{
		font-size: 20px;
	}
}
@media (max-width: 499px) {
	.styled-subtitle,
	.woocommerce-before-cart .cart-short-info,
	.cart_totals table th,
	.order-totals table th,
	.cart_totals table .shipping td,
	.woocommerce-message,
	.woocommerce-info,
	.woocommerce ul.woocommerce-error li,
	.woocommerce table.checkout-cart-info-table tr th,
	.woocommerce table.checkout-cart-info-table tr.shipping td,
	.widget_calendar caption,
	.blog-style-timeline .post-time,
	.project-info-shortcode-style-default .project-info-shortcode-item .title,
	.project_info-item-style-2 .project_info-item .title,
	.diagram-legend .legend-element .title {
		line-height: 30px;
	}
}
@media (max-width: 499px) {
	.gem-testimonial-text,
	.testimonials-style-1-text {
		font-size: 20px;
		line-height: 30px;
	}
}
@media (min-width: 1041px){
	.with-sidebar .products-list{
		min-height: 2250px;
	}
}
@media (min-width: 992px) and (max-width: 1040px){
	.with-sidebar .products-list{
		min-height: 2700px;
	}
}

#colophon #wpcf7-f4-o1 p {
	margin-bottom: 14px;
}
#colophon #wpcf7-f4-o1 .ajax-loader {
	display: none;
}
#colophon input[type="text"],
#colophon input[type="password"],
#colophon input[type="color"],
#colophon input[type="date"],
#colophon input[type="datetime"],
#colophon input[type="datetime-local"],
#colophon input[type="email"], input[type="number"],
#colophon input[type="range"], input[type="search"],
#colophon input[type="tel"],
#colophon input[type="time"],
#colophon input[type="url"],
#colophon input[type="month"],
#colophon input[type="week"],
#colophon textarea {
		color: #99a9b5;
}
#colophon  input::-webkit-input-placeholder,
#colophon  textarea::-webkit-input-placeholder,
#colophon  input:-moz-placeholder,
#colophon  textarea:-moz-placeholder,
#colophon  input::-moz-placeholder,
#colophon  textarea::-moz-placeholder {
	color: #494a59;
}
#colophon #custom_recent_posts-2 {
		margin-bottom: 35px;
}
#colophon #project_slider-13 {
		margin-bottom: 45px;
}
#colophon #text-3 {
		margin-bottom: 56px;
}
#colophon #text-2 {
		margin-bottom: 40px;
}
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-language,
.header-layout-fullwidth_hamburger #primary-menu.no-responsive > li.menu-item-cart,
body #page.vertical-header #primary-menu.no-responsive li.menu-item-language,
body #page.vertical-header #primary-menu.no-responsive li.menu-item-cart {
    display: none;
}
.scrolldown .mouse-2 {
    -webkit-animation: scrollmove 1s infinite ease;
    -moz-animation: scrollmove 1s infinite ease;
    animation: scrollmove 1s infinite ease;
    width: 1.5vw;
    /* background-image: url("../images/mouse_icon.png"); */
    display: block;
    font-size: 0;
    height: 43px;
    background-repeat: no-repeat;
}