@import "src/client_customizations/scss/client_master.scss";

.disclaimer {
    font-size: 0.75rem;
    line-height: 1rem;
}

@media (min-width: 576px) {
    .disclaimer {
        margin-top: 1rem;
    }
}

@media (max-width: 575px) {
    .disclaimer {
        padding-top: 1rem;
    }
}